import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as SmallArrow} from 'assets/img/app/global-imgs/small-arrow.svg';
import {ReactComponent as MonitoringIcon} from 'assets/img/app/sidebar-imgs/monitoring-icon.svg';
import {ReactComponent as EnvironmentalIcon} from 'assets/img/app/sidebar-imgs/environmental-icon.svg';
import {ReactComponent as ReportsIcon} from 'assets/img/app/sidebar-imgs/reports-icon.svg';
import {ReactComponent as DashboardIcon} from 'assets/img/app/sidebar-imgs/dashboard-icon.svg';
import {ReactComponent as TimelineIcon} from 'assets/img/app/sidebar-imgs/timeline-icon.svg';
import {ReactComponent as SettingsIcon} from 'assets/img/app/sidebar-imgs/settings-icon.svg';
import {ReactComponent as SwitchProductIcon} from 'assets/img/app/sidebar-imgs/switch_product.svg';
import SessionStorage from 'common/js/Storage';
import {Urls} from 'common/js/urls';
import {useSelector} from 'react-redux';

const PRODUCTS_URLS = {
    'PYTHIA': Urls.PythiaUrl(),
    'AMS': Urls.AmsUrl()
};

const Sidebar = () => {
    // store vars for JSX
    const userStoreJSX = useSelector(state => state.user);

    const sidebarNavBtns = [
        {name: 'PERFORMANCE', url: '/', iconClass: 'svg-defs-path-g-maskUse', image: MonitoringIcon},
        {name: 'ENVIRONMENTAL', url: '/environmental', iconClass: 'svg-defs-path-g-maskUse', image: EnvironmentalIcon},
        {name: 'REPORTS', url: '/reports', iconClass: 'svg-g-path', id: "reports-sub", image: ReportsIcon, submenu: [
            {name: 'DAILY GROUP REPORT', url: '/reports/dailyGroupReport'},
            {name: 'DAILY VESSEL REPORT', url: '/reports/dailyVesselReport'},
            {name: 'LEG REPORT', url: '/reports/legReport'},
        ]},
        {name: 'DASHBOARD', url: '/dashboards', iconClass: 'svg-path', image: DashboardIcon},
        {name: 'TIMELINE', url: '/timeline', iconClass: 'svg-defs-path-g-maskUse', image: TimelineIcon},
        {name: 'SETTINGS', url: '/settings', iconClass: 'svg-path', image: SettingsIcon}
    ];

    // get all the products of this user except for the current one
    const getUserOtherProducts = userProducts => {
        const currentProduct = SessionStorage.getCookie('product');

        return userProducts?.filter(product => product.name !== currentProduct);
    };

    const changeProduct = productName => {
        SessionStorage.addCookie('product', productName);
        window.location.replace(PRODUCTS_URLS[productName]);
    }

    const closeSidebar = () => {
        for( let el of document.getElementsByClassName('sidebar__subgroup')) {
            if(!!el) {
                if(el.classList.contains('open__submenu')) toggleSubmenu(!undefined, '');
            }
        }
        setTimeout(() => document.querySelectorAll('.sidebar, .ds-navbar__burgers__btn').forEach(el => el.classList.remove('open')));
        
    }

    const openSidebar = () => {
        setTimeout(() => document.querySelectorAll('.sidebar, .ds-navbar__burgers__btn').forEach(el => el.classList.add('open')));
    }
    
    const toggleSubmenu = (submenu, id) => {
        const subGroup = document.getElementsByClassName(`sidebar__subgroup ${id}`);
        const sidebar = document.getElementsByClassName('sidebar')[0];
        const arrowCollection = document.getElementsByClassName('sidebar-menu small-arrow');
        const arrow = document.getElementsByClassName(`sidebar-menu small-arrow ${id}`)[0];

        if ( !submenu || !subGroup ) return;
        if ( !subGroup[0].classList.contains('open__submenu') && sidebar.classList.contains('open') ) {
            for( let el of subGroup ) el.classList.add('open__submenu');
            arrow.classList.add('rotate--180');
        } else {
            for( let el of subGroup ) el.classList.remove('open__submenu');
            arrow.classList.remove('rotate--180');
            if ( id === '' ) {
                for( let el of subGroup ) el.classList.remove('open__submenu');
                for( let el of arrowCollection) el.classList.remove('rotate--180');
            }
        }
    }

    const menuProps = (svb) => {
        let menuProps = { key: svb.name };
        let menuClass = 'sidebar__navBtn-group';
        menuClass += svb.url === '/'+window.location.pathname.split('/')[1] ? ' active' : '';

        if (svb.hasOwnProperty('submenu')) menuProps.onClick = () => {
            toggleSubmenu(svb.submenu, svb.id)
        };

        menuProps.className = menuClass;
        return menuProps;
    };

    return (
        <div className="sidebar ds-box-shadow" onMouseLeave={closeSidebar} onMouseEnter={openSidebar}>
            <div>
                { sidebarNavBtns.map( svb => ( (svb.name === 'ADMINISTRATION' && userStoreJSX.user.occupation !== 3) ? null :
                    <div className="relative" key={svb.name}>
                        <div {...menuProps(svb)}>
                            { !svb.hasOwnProperty('submenu') ?
                                <Link className="sidebar__navBtn-group__navlink" to={svb.url}></Link> : null }
                            <div className="sidebar__navBtn-group__icon flex-centered">
                                <svb.image className={svb.iconClass} />
                            </div>
                            <div className="sidebar__navBtn-group__title headline3">{svb.name}</div>
                            { svb.hasOwnProperty('submenu') ? <div className={`sidebar-menu transform-04 small-arrow ${svb.id}`}>
                                <SmallArrow className="svg-path rotate-180" />
                            </div> : null }
                        </div>

                        { svb.hasOwnProperty('submenu') && <React.Fragment>
                            { svb.submenu.map( svbsm => (
                                <div className={`sidebar__subgroup ${svb.id} flex-centered-start relative`} key={svbsm.name}>
                                    <Link className={`sidebar__subgroup__link ${svb.id}`} to={svbsm.url}></Link>
                                    <div className="sidebar__subgroup__title headline3">{svbsm.name}</div>
                                </div>
                            ))}
                        </React.Fragment> }
                    </div>
                ))}
            </div>
            {getUserOtherProducts(userStoreJSX.user?.products)?.length ? <div className='changeProductBtn'>
                <div className='changeProductBtn__icon'>
                    <SwitchProductIcon className='svg-g-path switchProductIcon'/>
                </div>
                <div className='changeProductBtn__buttons'>
                    {
                        getUserOtherProducts(userStoreJSX.user?.products).map((product, index) => {
                            return(
                                <div 
                                    key={index} 
                                    className="changeProductBtn__buttons__button"
                                    onClick={() => changeProduct(product?.name)}>
                                        SWITCH TO { product?.name }
                                </div>
                            )
                        })
                    }
                </div>
            </div> : null}
        </div>
    );
}

export default Sidebar;
