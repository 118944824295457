import geStorage from 'components/monitoringCategories/localStorages/geStorage';
import syncChartsOptions from "../../components/charts/genericSyncCharts/syncChartsOptions";
import moment from "moment/moment";
import { monitoringStore } from "../../common/store/storeUtils";
import { setReportsPlotLineGuides } from "../../Utilities/highchartsUtils";

let generatorEngineJson = {};

export const turboChargersChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    metrics: [
        {
            metricCategory: "GENERATOR_ENGINE",
            subIds: [],
            metricData: [
                {
                    metricName: "tCInAExhGasTemp"
                },
                {
                    metricName: "tCInBExhGasTemp"
                },
                {
                    metricName: "tCLoInLETPress"
                },
                {
                    metricName: "tCOutExhGasTemp"
                },
                {
                    metricName: "tCRPMPickup"
                }
            ]
        }
    ]
};

export const turboChargersLineChartData = {
  turboChargersChartMetrics: [
      'tCInAExhGasTemp',
      'tCInBExhGasTemp',
      'tCOutExhGasTemp',
      'tCRPMPickup',
      'tCLoInLETPress'
  ],
  chartAxes: {},                    // Dynamically generated in buildNavigationChartAxes method
  turboChargersLineChartTabs: [],   // Dynamically generated in buildNavigationChartTabs method
  navigationMapper: {},             // Dynamically generated in buildNavigationMapper method
  chartSeries: {}   ,                // Dynamically generated in buildNavigationChartSeries method
  currentTab: null,
  handleTabUpdate: null
}

const buildNavigationMapper = noTurboCharger => {
    turboChargersLineChartData.navigationMapper = {};

    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        const tempArr = turboChargersLineChartData.turboChargersChartMetrics.map((metric) => {
            return metric;
        })
        turboChargersLineChartData.navigationMapper[`engine${turboNum}`] = tempArr;
    }
}

const buildNavigationChartTabs = noTurboCharger => {
    turboChargersLineChartData.turboChargersLineChartTabs = [];
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.turboChargersLineChartTabs.push({title: `ENGINE ${turboNum}`, name: `engine${turboNum}`});
    }
}

const buildNavigationChartAxes = noTurboCharger => {
    turboChargersLineChartData.chartAxes = {};

    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartAxes[`engine${turboNum}`] = [
            { id: 'exhTemp-in-A-axis', title: { text: 'Exh. Gas Inlet A Temp (°C)'}, height: '80px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'exhTemp-in-B-axis', title: { text: 'Exh. Gas Inlet B Temp (°C)'}, height: '80px', top: '170px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'tCOutExhGasTemp-axis', title: { text: 'Exh. Gas Outlet Temp (°C)'}, height: '80px', top: '290px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'tCRPMPickup-axis', title: { text: 'RPM Pickup'}, height: '80px', top: '410px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'tCLoInLETPress-axis', title: { text: 'LO Inlet Pressure (bar)'}, height: '80px', top: '530px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ]
    }
}

const buildNavigationChartSeries = noTurboCharger => {
    turboChargersLineChartData.chartSeries = {};
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartSeries[`engine${turboNum}`] = [
            { id: `tCInAExhGasTemp`, name: 'Exh. Gas Inlet A Temp', data: [], tooltipTitle: 'Exh. Gas Inlet A Temp', type: 'line', yAxis: 'exhTemp-in-A-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: `tCInBExhGasTemp`, name: 'Exh. Gas Inlet B Temp', data: [], tooltipTitle: 'Exh. Gas Inlet B Temp', type: 'line', yAxis: 'exhTemp-in-B-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: `tCOutExhGasTemp`, name: 'Exh. Gas Outlet Temp', data: [], tooltipTitle: 'Exh. Gas Outlet Temp', type: 'line', yAxis: 'tCOutExhGasTemp-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: `tCRPMPickup`, name: 'RPM Pickup', data: [], tooltipTitle: 'RPM Pickup', type: 'line', yAxis: 'tCRPMPickup-axis',
              titleSuffix: '(UTC)', tooltip: { valueDecimals: 2 } },
            { id: `tCLoInLETPress`, name: 'LO Inlet Pressure', data: [], tooltipTitle: 'Lube Oil Inlet Press', type: 'line', yAxis: 'tCLoInLETPress-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } }
        ]
    }
}

const setBarTablesDataFormat = (barsTableData) => {
    let tmpObj = {};
    barsTableData.forEach(obj => tmpObj[`${obj.metricName}No${obj.subId}`] = obj.value);
    return tmpObj;
};

const getTitlesFromWidgetIds = (widgetId) => {
    const idsTitlesMapper = {
        loadHistory: 'GENERATOR ENGINES LOAD HISTORY',
        fuelBarChartJson: 'GENERATOR ENGINES FUEL CONSUMPTION',
        fuelLineChartJson: 'GENERATOR ENGINES FUEL CONSUMPTION RATE',
        generatorEngineDetails: 'GENERATOR ENGINE DETAILS',
        turboChargerLineChartJson: 'TURBOCHARGER',
        runningTime: 'RUNNING TIME'
    }

    return idsTitlesMapper[widgetId];
}

// this function is created in all js files of the components that use the SyncedTabWithTabNavigation component
// and recreates the chart json object taking each time the proper data based on the current tab from the JSON js object (response)
// and is mapped with the selected tab
const configureChartData = (currentTab, setSyncCharts, widget) => {
    if (!generatorEngineJson[widget.id] || !currentTab ) return;
    let options = syncChartsOptions();

    options["series"] = turboChargersLineChartData.chartSeries[currentTab];
    options["yAxis"] = turboChargersLineChartData.chartAxes[currentTab];
    options.chart.height = 770;
    options.elementId = `${moment().valueOf()}`;
    options.exporting.filename = getTitlesFromWidgetIds('turboChargerLineChartJson');

    options.exporting.chartOptions.title.text = `${turboChargersLineChartData.turboChargersLineChartTabs?.find((tab) => tab.name === currentTab)?.title} details`;

    // Metrics must be compared with a suffix of no1, no2 etc.. SubID is used to differentiate.
    // This way we are sure that data from correct pagination is displayed each time
    const subIdNumber = (currentTab && parseInt(currentTab.match(/\d+/)[0])),
        _dataObj = JSON.parse(JSON.stringify(generatorEngineJson[widget.id]));

    turboChargersLineChartData.navigationMapper && turboChargersLineChartData.navigationMapper[currentTab] && turboChargersLineChartData.navigationMapper[currentTab].forEach(category => {
        turboChargersLineChartData.chartSeries[currentTab].forEach((serie, index) => {
            if (category === serie.id) options.series[index].data = _dataObj[`${category}No${subIdNumber}`] || [];
        })
    })


    if (monitoringStore().detailedData?.status && setReportsPlotLineGuides) {
        let plotGuides = setReportsPlotLineGuides(generatorEngineJson[widget.id]);
        if (plotGuides?.xAxis) options.xAxis = Object.assign({}, options.xAxis, plotGuides.xAxis);
    } else {
        options.xAxis.plotBands = syncChartsOptions().xAxis.plotBands;
        options.xAxis.plotLines = [];
    }

    turboChargersLineChartData.currentTab = currentTab;
    if(setSyncCharts) {
        setSyncCharts(options);
    } else return options;
}

export const updateTurboChargersChartJson = (id, data, updateState, extraChartConfigs, widget) => {
    // update state with empty object in order to show the NoData in case the response is empty
    if(data.length === 0) {
        updateState(id, {}, widget?.id);
        return;
    }

    const noTurboCharger = data[0]?.values[data[0]?.values.length-1].subId;

    // Navigation Mapper - Build Navigation Mapper object
    buildNavigationMapper(noTurboCharger);

    // Navigation Chart - Build Tabs Object
    buildNavigationChartTabs(noTurboCharger);

    // Navigation Chart - Build Axes Object
    buildNavigationChartAxes(noTurboCharger);

    // Navigation Chart - Build Series Object
    buildNavigationChartSeries(noTurboCharger);

    geStorage.setTurboChargerLineChartData(data);
    generatorEngineJson[widget.id] = (data[0]?.values) ? setBarTablesDataFormat(data[0].values) : [];

    turboChargersLineChartData.currentTab = turboChargersLineChartData.turboChargersLineChartTabs[0]?.name;
    turboChargersLineChartData.handleTabUpdate = configureChartData;

    const currentTabDataConfigured = configureChartData(turboChargersLineChartData.turboChargersLineChartTabs[0]?.name, null, widget);
    updateState(id, currentTabDataConfigured, widget?.id);
};
