import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableSortLabel,
	TableContainer,
	TableHead,
	TableRow,
	Paper
} from "@material-ui/core";
import { headCells } from "./ciiGroupOverviewUtils";
import VesselNameBox from "components/vesselNameBox/vesselNameBox";
import CiiClassBox from "components/ciiClasses/ciiClassBox";
import { ReactComponent as RedUp } from "assets/img/app/overview-imgs/RedUp.svg";
import { ReactComponent as GreenDown } from "assets/img/app/overview-imgs/GreenDown.svg";
import DoubleData from "../../reports/doubleData";
import TableLineChart from "components/charts/lineChart/tableChart";
import { useDispatch, useSelector } from "react-redux";
import { vesselGroupUtils } from "common/store/storeUtils";
import moment from "moment";
import Utils from "Utilities/global";
import { stableSort } from "Utilities/muiTableUtils";
import Tooltip from "components/muiHTMLTooltip/muiTooltip";

const CiiGroupOverview = (props) => {
	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("vesselName");
	const createSortHandler = (property) => (event) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	
	const dispatch = useDispatch();
    const selectedPickerYear = useSelector(state => state.monitoring.ciiSearchingYear.selectedPickerYear);

	return (
		<Paper className="material-ui-paper">
			<TableContainer className="ds-mui-table">
				<Table
					className="material-ui-table"
					aria-labelledby="tableTitle"
					aria-label="enhanced table"
				>
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									align={headCell.align}
									sortDirection={
										orderBy === headCell.id ? order : false
									}
								>
									{headCell.sortable ? (
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={
												orderBy === headCell.id
													? order
													: "asc"
											}
											onClick={createSortHandler(
												headCell.id
											)}
										>
											{headCell.label + (
												(headCell.id === 'requiredCiiTwoYearsLater' || headCell.id ===  'requiredCiiOneYearLater') 
												? (selectedPickerYear + (headCell.id === 'requiredCiiOneYearLater' ? 1 : 2))
												: '' )}
										</TableSortLabel>
									) : (
										headCell.label
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{stableSort(
							props?.data,
							orderBy,
							order,
							"groupOverview"
						)?.map((row, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<TableCell align="center">
										<Tooltip 
											height='auto'
											component={
													<div>
														<VesselNameBox
															data={{
																vesselName: row.vesselDetails.name,
																clickable: true,
																onClick: () => {
																	const groupID = vesselGroupUtils.getVesselGroupIdFromVesselId([row.vesselDetails?.id])
																	vesselGroupUtils.setSelectedAndChange(dispatch, groupID);                 
																	dispatch({ type: 'vessel/setSelectedVessel', payload: row.vesselDetails?.id });
																}
															}}
														/>
													</div>
											}
											title={
												<div className="flex-centered flex-column p-2">
													<div>{row?.vesselDetails?.type}</div>
													<div>
														{row?.vesselDetails.dwt && ` DWT/GT: ${row?.vesselDetails.dwt} mt `}
													</div>
												</div>
											}
										/>
									</TableCell>
									<TableCell align="right">
										<DoubleData
											inheritAlignment={true}
											enableDevider={true}
											firstPart={{
												classNames: "reportsData",
												value: row.fuelConsumption.telegrams
											}}
										/>
									</TableCell>
									<TableCell align="center">
										{row.monthlyFuelConsumption.telegrams.length > 1 
											? <TableLineChart lineChart={row.monthlyFuelConsumption.telegrams} chartName={"FC"}/>
											: (
												<div>
													{`${moment(row.monthlyFuelConsumption.telegrams[0].datetime).format('MMMM YYYY')}: `}
													<span className="reportsData">
														{Utils.renderNumber(row.monthlyFuelConsumption.telegrams[0].y, 0)}
													</span>
												</div>
											)}
									</TableCell>
									<TableCell align="right">
										<DoubleData
											inheritAlignment={true}
											enableDevider={true}
											firstPart={{
												classNames: "reportsData",
												value: row.co2Emissions.telegrams
											}}
											/>
									</TableCell>
									<TableCell align="center">
										{row.monthlyCo2Emissions.telegrams.length > 1 
											? <TableLineChart lineChart={row.monthlyCo2Emissions.telegrams} chartName={"CO2"}/>
											: (
												<div>
													{`${moment(row.monthlyCo2Emissions.telegrams[0].datetime).format('MMMM YYYY')}: `}
													<span className="reportsData">
														{Utils.renderNumber(row.monthlyCo2Emissions.telegrams[0].y, 0)}
													</span>
												</div>
											)}
									</TableCell>
									<TableCell align="center">
										<CiiClassBox
											reportsCiiClass={row?.ciiClassYtd.telegrams}
											reportsValue={row?.ciiYtd.telegrams}
										/>
									</TableCell>
									<TableCell align="right">
										<DoubleData
											inheritAlignment={true}
											enableDevider={true}
											firstPart={{
												classNames: "reportsData",
												value: row?.ciiDelta
													?.telegrams?.replaceAll(/[-+]/g, ''),
												unit: row?.ciiDelta?.telegrams ? "%" : "",
												icon: row?.ciiDelta?.telegrams?.includes(
													"-"
												) ? (
													<GreenDown className="mb-1" />
												) : row?.ciiDelta?.telegrams
													? (
													<RedUp className="mb-1" />
												) : ""
											}}
										/>
									</TableCell>
									<TableCell align="right">
										<DoubleData
											inheritAlignment={true}
											enableDevider={true}
											firstPart={{
												classNames: "primaryData",
												value: row.requiredCii.oneYlater
											}}
										/>
									</TableCell>
									<TableCell align="right">
										<DoubleData
											inheritAlignment={true}
											enableDevider={true}
											firstPart={{
												classNames: "primaryData",
												value: row.requiredCii.twoYlater
											}}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default CiiGroupOverview;
