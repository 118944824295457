import React, { useState } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
import SessionStorage from 'common/js/Storage';
import TermsModal from 'components/modals/termsModal';
import TermsBanner from 'components/terms/termsBanner';

// initiate vessel & vessel group stores
import StoreInitiate from 'common/store/storeInitiate';

// redux store
import { Provider } from 'react-redux';
import store from 'common/store/store';
import {userStore} from 'common/store/storeUtils';
import { useSelector } from 'react-redux';

// analytics
import TagManagerComponent from 'components/analytics/tagManager';

// header
import Navbar from 'components/Navbar/navbar';
import Sidebar from 'components/Sidebar/sidebar';

// loader
import FetchingDataMessage from 'components/infoMessageForUser/fetchingDataMessage';
import StoreDataMessage from 'components/infoMessageForUser/storeDataMessage';

// pages
import VesselMonitoring from 'pages/VesselMonitoring';
import Environmental from 'pages/Environmental';
import DailyGroupReport from 'pages/DailyGroupReport';
import DailyVesselReport from 'pages/DailyVesselReport';
import LegReport from 'pages/LegReport';
import Dashboards from 'pages/Dashboards';
import Timeline from 'pages/Timeline';
import Alerts from 'pages/Alerts';
import Settings from 'pages/Settings';
import SingleDashboard from 'pages/SingleDashboard';
import Notifications from 'components/notifications/notifications';

import "sass/index.scss";
/* eslint no-unused-vars: "off" */
import ChartsThemes from './Utilities/high-charts-theme';
/* eslint no-unused-vars: "off" */
import ChartsUtils from './Utilities/highchartsUtils';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


const PrivateRoute = ({initApp, user, component: Component, ...rest}) => (
    <Route {...rest} render={(props) => {

        let mainProps = {
            ...props,
            user,
        };

        if (props.match.params.dashboardID) {
            mainProps.dashboardID = props.match.params.dashboardID;
        }

        return <>
            {initApp && <Component {...mainProps} />}
        </>
    }}/>
)

const App = () => {
  const [initApp, setInitApp] = useState(false);

  // store vars
  const notificationsStoreJSX = useSelector(state => state.notifications);


    // based on theme that user has selected from settings (dark or light) we manually add the ds-white classname
    // in the body for the whiteThemeColors to get applied otherwise the darkThemeColors are used by default.
    // Also adding the same background color in html to avoid a flash of incorrect background color on re-renders
  if (userStore().user.theme === 'ds-white') {
      document.body.classList.add('ds-white');
      document.documentElement.style.backgroundColor = '#FAFAFB'; // whiteTheme --platform_background
  } else {
      document.body.classList.remove('ds-white');
      document.documentElement.style.backgroundColor = '#081522'; // darkTheme --platform_background
  }

  const appProps = {
    initApp
  };

  return (
    <React.Fragment>
      <FetchingDataMessage message='Fetching data...'/>
      <StoreDataMessage message='Data stored succesfully!'/>
      <TagManagerComponent/>
        {notificationsStoreJSX.showNotification && <Notifications/> }
      <StoreInitiate initApp={initApp} setInitApp={setInitApp} {...appProps} />
      <Router basename='/'>
        <Switch>
        { SessionStorage.isTokenValid(SessionStorage.getCookie('expiredDate')) &&
          <React.Fragment>
            <Navbar {...appProps} />
            <Sidebar/>
            <TermsBanner />
            <TermsModal />
            <div className='main-container main'>
              <PrivateRoute exact path='/' component={VesselMonitoring} {...appProps} />
              <PrivateRoute exact path='/environmental' component={Environmental} {...appProps} />
              <PrivateRoute exact path='/reports/dailyVesselReport' component={DailyVesselReport} {...appProps} />
              <PrivateRoute exact path='/reports/dailyGroupReport' component={DailyGroupReport} {...appProps} />
              <PrivateRoute exact path='/reports/legReport' component={LegReport} {...appProps} />
              <PrivateRoute exact path='/dashboards' component={Dashboards} {...appProps} />
              <PrivateRoute exact path='/dashboard/:dashboardID' component={SingleDashboard} {...appProps} />
              <PrivateRoute exact path='/timeline' component={Timeline} {...appProps} />
              <PrivateRoute exact path='/alerts' component={Alerts} {...appProps} />
              <PrivateRoute exact path='/settings' component={Settings} {...appProps} />
            </div>
          </React.Fragment>}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

ReactDOM.render(
  (
    <Provider store={store}>
      <App/>
    </Provider>
  ),
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
