import React, { useState, useEffect, useRef } from 'react';
import service from 'common/js/service';
import { BoilersUtils } from "Utilities/boilers";
import Overview from 'components/monitoringCategories/overview';
import Hull from 'components/monitoringCategories/hull';
import MainEngine from 'components/monitoringCategories/mainEngine';
import GeneratorEngines from 'components/monitoringCategories/generatorEngine';
import Boilers from 'components/monitoringCategories/boilers';
import NavigationTabs from "components/navigationTabs/navigationTabs";
import ViewAllPopup from "components/monitoringCategories/viewAllPopup";
import { licensing } from "common/store/licensing";
import MapComponent from 'components/map/map';
import { useDispatch, useSelector } from 'react-redux';
import { monitoringStore, vesselStore, vesselGroupStore } from 'common/store/storeUtils';
import { ReactComponent as SmallArrow } from 'assets/img/app/global-imgs/small-arrow.svg';
import OverviewTable from "./overviewComponents/overviewTable";
import { ReactComponent as DownloadActionButton } from 'assets/img/app/monitoring-imgs/downloadActionButton.svg';
import { ClickAwayListener } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { ReactComponent as InformationIcon } from 'assets/img/app/global-imgs/informationIcon.svg';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import NoData from "../noData/noData";

const _TABS = [
    {title: 'OVERALL', name: 'overview', visible: true},
    {title: 'HULL', name: 'hull', visible: true},
    {title: 'MAIN ENGINE', name: 'mainEngine', visible: true},
    {title: 'GENERATOR ENGINE', name: 'generatorEngine', visible: true},
    {title: 'BOILERS', name: 'boilers', visible: true} 
];

const activeTabsInLightView = [
    'overview', 'hull', 'mainEngine', 'generatorEngine', 'boilers'
];

let _CurrentTab = 'overview';

const VesselMonitoring = () => {
    const [tabs, setTabs] = useState(JSON.parse(JSON.stringify(_TABS)));
    const [currentTab, setCurrentTab] = useState('overview');
    const [boilerConfig, setBoilerConfig] = useState(true);
    const [overviewTableData, setOverviewTableData] = useState([]);
    const [loadingOverviewTableData, setLoadingOverviewTableData] = useState(false);
    // Overview table data order state vars to save order local
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("latestFouling");
    const panelBody = useRef();
    const accordionArrow = useRef();
    // store vars
    const monitoringStoreJSX = useSelector(state => state.monitoring);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const dispatch = useDispatch();

    // popOver vars
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenPopOver = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
  
    const popoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    const seTabsAndBoilersConfig = value => {
        let _tabs = [...tabs];
        setBoilerConfig(value);
        _tabs[4].visible = value;
        setTabs(_tabs);

        if(_CurrentTab === 'boilers' && !_tabs[4].visible) {
            setTimeout(() => setCurrentTab('overview'));
            _CurrentTab = 'overview';
        }
    }

    const checkForVesselBoilersConfig = () => {
        service.getBoilerTypes(vesselStore().selectedVessel).then(response => {
            response = response?.filter(item => item.type && item.subId && BoilersUtils.acceptedBoilerTypes.includes(item?.type));
            if(!response.length) seTabsAndBoilersConfig(false);
            else seTabsAndBoilersConfig(true);
        });
    }

    const updateCurrentTab = tab => {
        if (!monitoringStore().fromTo.from && !monitoringStore().fromTo.to) return;
        setCurrentTab(tab);
        _CurrentTab = tab;
    }

    const vesselSelected = vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel;

    useEffect(() => {
        if(!vesselSelected) return;

        // On vessel change we check if there is vessel boiler configuration to show the boilers TAB
        // but on light vessels we always show boiler tab with telegrams data
        if(licensing.lightCondition()) {
            setBoilerConfig(true);
            setTabs(JSON.parse(JSON.stringify(_TABS)));
        } else checkForVesselBoilersConfig();

        // fetch the overview table data to update the table accordingly
        getAndUpdateOverviewTable([vesselStoreJSX.selectedVessel]);

        return () => setBoilerConfig(true);
        // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);

    useEffect(() => {
        // vesselIds are from the vessels of the selected fleet.
        // vesselGroupStore().selectedVesselGroup === -1 is the condition to determine if the ALL FLEETS or specific fleet is selected
        // In case ALL FLEETS is selected we get allVessels from the store otherwise we get fleetVessels from the store accordingly
        const selectedVesselIds = vesselStore()[vesselGroupStore().selectedVesselGroup === -1 ? 'allVessels' : 'fleetVessels'].map(vesselObj => vesselObj.vesselId);
        // update the table with the selected fleet vessels only when specific vessel is not selected
        vesselStore().selectedVessel === -1 && getAndUpdateOverviewTable(selectedVesselIds);
        // in the dependency array we use vesselStoreJSX.fleetVessels instead of vesselGroupStore.selectedVesselGroup because in case of same fleet reselection (from the single vessel page)
        // the vesselGroupStore.selectedVesselGroup doesn't update and the table can't be fetched again
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vesselStoreJSX.fleetVessels]);

    const navTabsProps = {
        tabs: tabs,
        activeTabsInLightView: activeTabsInLightView,
        tabsWithLocker: true,
        currentTab: currentTab,
        updateCurrentTab: updateCurrentTab
    }

    const getAndUpdateOverviewTable = (vesselIds, sortBy, direction) => {
        // If no sorting option has been selected, default to sorting by 'latestFouling' in descending order.
        setLoadingOverviewTableData(true);
        service.getOverviewTableData(vesselIds, sortBy || 'latestFouling', direction || 'desc')
            .then(data => {
                setOverviewTableData(data);
                setLoadingOverviewTableData(false);

                // on fleet change (reselection or change) we initialize the sorting
                if(!(sortBy || direction)) {
                    setOrder("desc");
                    setOrderBy("latestFouling");
                }
            })
    }

    const getMapTitle = () => {
        if(vesselSelected) return "VESSEL'S ROUTE";
        if(vesselGroupStore().allVesselGroupsObj.id === vesselGroupStore().selectedVesselGroup) return "COMPANY'S VESSELS LATEST POSITION";
        return "GROUP’S VESSELS LATEST POSITION";
    }

    const handleAccordion = () => {
        if(!panelBody.current || !vesselSelected) return;
        panelBody.current.classList.toggle('accordion');
        accordionArrow.current.classList.toggle('rotate-180');
    }

    const downloadFormatter = async (format) => {
        // When vesselStore().selectedVessel === -1 it means we are on fleet view so we get the vesselIds of the selected fleet or allFleets
        // otherwise we get the vesselId of the selected vessel
        let vesselIds;
        if(vesselStore().selectedVessel === -1) {
            vesselIds = vesselStore()[vesselGroupStore().selectedVesselGroup === -1 ? 'allVessels' : 'fleetVessels'].map(vesselObj => vesselObj.vesselId)
        } else {
            vesselIds = [vesselStore().selectedVessel];
        }
        popoverClose();
        await service.getExportedFormat(vesselIds, format);
    }

    const sortTable = async (sortBy, direction) => {
        let selectedVesselIds;
        if(vesselStore().selectedVessel === -1) {
            selectedVesselIds = vesselStore()[vesselGroupStore().selectedVesselGroup === -1 ? 'allVessels' : 'fleetVessels'].map(vesselObj => vesselObj.vesselId);
        } else {
            selectedVesselIds = [vesselStore().selectedVessel];
        }
        getAndUpdateOverviewTable(selectedVesselIds, sortBy, direction)
    }

    return (
        <div className='main-content-padding pad-t-0'>

            {currentTab !== 'overview' && <ViewAllPopup/>}

            <div className='widget-panel marg-b-15'>
                <div className='widget-panel__header overview-monitoring-header'>
                    <div className="flex-centered">
                        OVERVIEW
                        <Tooltip
                            placement="top"
                            height="auto"
                            component={<InformationIcon className="svg-path marg-l-10 pointer" />}
                            title={
                                <span className="overview-table__title-tooltip">
                                    This widget always shows the vessel's current performance indicators
                                </span>
                            }
                        />
                    </div>
                    <DownloadActionButton className='export-icon' onClick={handleOpenPopOver} />
                    {open &&
                        <ClickAwayListener onClickAway={popoverClose}>
                            <Popper id={open ? 'popover' : undefined} open={open} anchorEl={anchorEl} placement="bottom-end">
                                <div className='export-options'>
                                    <div className='export-options__title' >Download as:</div>
                                    <div className='export-options__separator'></div>
                                    <div onClick={() => downloadFormatter('csv')} className='export-options__option pointer' >CSV</div>
                                    <div onClick={() => downloadFormatter('xlsx')} className=' export-options__option pointer'>XLSX</div>
                                </div>
                            </Popper>
                        </ClickAwayListener>
                    }
                </div>
                <div className="widget-panel__body">
                    {
                        !loadingOverviewTableData && !overviewTableData ? (
                            <NoData
                                height={vesselSelected ? '125px' : 'calc(100vh - 280px)'}
                                text="No data to display at the moment"
                            />
                        ) : (
                            <OverviewTable
                                data={overviewTableData}
                                sortTable={sortTable}
                                order={order}
                                setOrder={setOrder}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                loadingData={loadingOverviewTableData}
                            />
                        )
                    }
                </div>
            </div>

            {vesselSelected ?
                <div className='widget-panel'>
                    <div className='widget-panel__header pointer' onClick={handleAccordion}>
                        {getMapTitle()}
                        {vesselSelected ?
                            <SmallArrow className='svg-path transform-04 marg-l-15 rotate-180'
                                        ref={accordionArrow}/> : null}
                    </div>
                    <div
                        className={`widget-panel__body ${vesselSelected ? 'overflowHidden map-accordion accordion' : ''}`}
                        ref={panelBody}>
                        <MapComponent
                            fromTo={monitoringStoreJSX.fromTo}
                            resetLegView={monitoringStoreJSX.resetLegView}
                            fromToChanged={monitoringStoreJSX.fromToChanged}
                            setVesselDataHistory={(value) => dispatch({
                                type: 'monitoring/setVesselDataHistory',
                                payload: value
                            })}
                            setDetailedData={(value) => dispatch({
                                type: 'monitoring/setDetailedData',
                                payload: value
                            })}
                        />
                    </div>
                </div> : null
            }

            {vesselSelected &&
                <NavigationTabs
                    {...navTabsProps}
                    classes={{
                        mainClass: 'navigation-tabs',
                        extraClasses: 'flex-space-around'
                    }}
                />
            }

            {vesselSelected && currentTab === 'overview' && <Overview/>}

            {vesselSelected && currentTab === 'hull' && <Hull/>}

            {vesselSelected && currentTab === 'mainEngine' && <MainEngine/>}

            {vesselSelected && currentTab === 'generatorEngine' && <GeneratorEngines/>}

            {vesselSelected && currentTab === 'boilers' && boilerConfig && <Boilers/>}
        </div>
    );
}

export default VesselMonitoring;
