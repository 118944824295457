import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import moment from 'moment';
import chartOptions from "../components/charts/lineChart/react-lineChart";
import { monitoringStore } from "../common/store/storeUtils";
var XLSX = require("xlsx");
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);


Highstock.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
    let path = [
        // Arrow stem
        'M', x + w * 0.5, y,
        'L', x + w * 0.5, y + h * 0.7,
        // Arrow head
        'M', x + w * 0.3, y + h * 0.5,
        'L', x + w * 0.5, y + h * 0.7,
        'L', x + w * 0.7, y + h * 0.5,
        // Box
        'M', x, y + h * 0.9,
        'L', x, y + h,
        'L', x + w, y + h,
        'L', x + w, y + h * 0.9
    ];
    return path;
};

Highcharts.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
    let path = [
        // Arrow stem
        'M', x + w * 0.5, y,
        'L', x + w * 0.5, y + h * 0.7,
        // Arrow head
        'M', x + w * 0.3, y + h * 0.5,
        'L', x + w * 0.5, y + h * 0.7,
        'L', x + w * 0.7, y + h * 0.5,
        // Box
        'M', x, y + h * 0.9,
        'L', x, y + h,
        'L', x + w, y + h,
        'L', x + w, y + h * 0.9
    ];
    return path;
};

const customRefreshTooltipFunction = function (proceed, point) {
    const p = proceed.call(this, point);

    const pointToCheck = point?.[0] ? point[0] : point;

    const customTooltipBorderColor = pointToCheck?.series?.options?.customTooltipBorderColor !== 'defaultSeriesColor' ? pointToCheck?.series?.options?.customTooltipBorderColor : pointToCheck?.color;

    if(customTooltipBorderColor && this.label) 
        this.label.attr({stroke: customTooltipBorderColor});

    return p;
}

const removeNanValuesInCsvXls = function(proceed, multiLevelHeaders) {
    let rows = proceed.call(this, multiLevelHeaders);  

    for(let i = rows.length -1; i >= 1 ; i--) {
        // remove the whole row if it has only NaN values
        let row = rows[i];
        if(row.filter((value, index) => index !== 0).every(rowItem => isNaN(rowItem))) rows.splice(i, 1);
    }

    return rows;
};

export const downloadChartInXLSXform = function(props) {
    let div = document.createElement('div'), name, sheetsName, xlsxRows = [], rows;
    div.style.display = 'none';
    document.body.appendChild(div);
    
    // if no props default export to xlsx else export xlsx base on props
    if (!props?.xlsxRows){
        rows = this.getDataRows(true);
        if(JSON.stringify(rows[0]) === JSON.stringify(rows[1])) rows.splice(0, 1); // if there is a duplicated category row, delete it, so it does not appear in the exported xlsx file

        // logic to discard all the columns that have junk values (== for example if column name is [object, object])
        const maxCol = rows[0]?.findIndex(col => {
            return (typeof col !== 'string');
        });
                
        xlsxRows = rows.map(function (row) {
            return row.map(function (column, colIndex) {
                if(maxCol >= 0 && colIndex >= maxCol) return {}; // for the junk column, don't export it's values, if it has any
                return {
                    t: typeof column === 'number' ? 'number' : 'string',
                    v: column
                };
            });
        });

        if (this.options.exporting.filename) {
            name = this.options.exporting.filename;
        } else if (this.title && this.title.textStr) {
            name = this.title.textStr.replace(/ /g, '-').toLowerCase();
        } else {
            name = 'chart';
        }
        // the sheet's name will be the same as the filename, 
        // but if the filename exceeds 31 chars, then the sheet's name should be the first 31 chars of the filename (31 is the excel's limit for a sheet name) 
        sheetsName = this.options.exporting.filename ? this.options.exporting.filename.substr(0, 31) : 'Sheet 1';
    }  else {
        name = props?.name
        sheetsName = props?.sheetsName ? props?.sheetsName.substr(0, 31) : 'Sheet 1';
        xlsxRows = props?.xlsxRows
    }

   
    var workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.aoa_to_sheet(xlsxRows);

    workbook.Sheets[sheetsName] = worksheet;
    workbook.SheetNames[0] = sheetsName;

    XLSX.writeFileXLSX(workbook, `${name}.xlsx`);
}

export const downloadChartInCSVform = function(props) {

    const blob = new Blob([props.csvContent], { type: 'text/csv;charset=utf-8;' });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = props.name;
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

};
export const csvXlsxExportForCurvesAndScatterWithDatetime = (obj, metricUnitIndex, metricUnitValues) => {

    //add (kW) or (mt/day) to the columns headers that needs to change
    let name = obj.options.exporting.filename
    name = name.replace(/\./g, "");
    //always get the headers from obj.getDataRows(true) callback to get the right column headers
    //remove the metric units in order to mach them correctly in the loop and put the unit after    
    let headers = obj.getDataRows(true)[0].map((row) => row.replace(/\(.*?\)/, "").trim())
    // adding a column with the name Datetime
    headers.unshift('Datetime')
    
    const dataMap = new Map();
    
    obj.options.series.forEach(serie => { 
        serie.data.forEach(({ datetime, x, y }) => { 
            const key = `${datetime}-${x}`; 
            if (!dataMap.has(key)) {                
                // Initialize the row with empty values 
                dataMap.set(key, [datetime ? moment(datetime).utc().format('DD-MM-YYYY HH:mm:ss') : "", x, "", "", "", ""]); 
            } 
            // console.log("datetime: ",moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss'), x, y);
                
            // Insert the y value into the correct column based on the series name
            const row = dataMap.get(key);
            
            const columnIndex = headers.indexOf(serie.name);
            row[columnIndex] = y; 
        });
    });
    
    // Add the unit to the headers after the loop in order not to break the list mapping
    headers = headers.map((val, index) => {
        if(val.toUpperCase() === "RPM") return val;
        if(index === 1) return `${val} (${metricUnitIndex})`;
        if(val === "Datetime") return val;
        return `${val} (${metricUnitValues})`
    });


    // Step 3: Convert map values to an array and sort by speed (x) and datetime
    let rows = [headers, ...Array.from(dataMap.values()).sort((a, b) => a[1] - b[1] || a[0] - b[0])];            
                
    const sheetsName = name ? name.substr(0, 200) : 'Sheet 1';

    return {rows, sheetsName, name};
}

// This method builds the export context button configuration based on the selected tab. This is for the widgets that have tabs and support export
// TODO currently this method is made only for mainEngine performance page, needs refactor to support all widgets that have tabs and support export
export const buildExportContextButton = (tabName, tabs) => {
    return {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    let name = this.options.exporting.filename;
                    let sheetsName = name

                    if (sheetsName.length > 30) {
                        sheetsName = `${tabName.toUpperCase()}`
                    }

                    const rows = this.getDataRows(true);
                    const newRows = rows.map(row => row.map((value, index) =>
                        index === 0 ? moment.utc(value).format('DD-MM-YYYY HH:mm:ss') : value))


                    let updatedows = [
                        tabs[tabName.toUpperCase()],
                        ...newRows.slice(1, newRows.length)
                    ];
                    // remove special characters before the download function
                    // eslint-disable-next-line no-useless-escape
                    name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                    name = name.replace(/\./g, "");
                    // eslint-disable-next-line no-useless-escape
                    sheetsName = sheetsName.replace(/[:\/\\\?\*\[\]]/g, "");
                    downloadChartInXLSXform({
                        xlsxRows: updatedows,
                        sheetsName: sheetsName,
                        name: name
                    });
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    let name = this.options.exporting.filename;
                    // eslint-disable-next-line no-useless-escape
                    name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                    name = name.replace(/\./g, "");

                    const rows = this.getDataRows(true);
                    const newRows = rows.map(row => row.map((value, index) =>
                        index === 0 ? moment.utc(value).format('DD-MM-YYYY HH:mm:ss') : value))

                    let updatedows = [
                        tabs[tabName.toUpperCase()],
                        ...newRows.slice(1, newRows.length)
                    ];

                    const csvContent = updatedows
                        .map((row) => row?.join(","))?.join("\n");

                    downloadChartInCSVform({ csvContent, name: name });
                }
            }
        ]
    }
}

export const setReportsPlotLineGuides = (data) => {
    let plotObject = { xAxis: {} };
    plotObject.xAxis = Object.assign({}, { plotBands: chartOptions().xAxis.plotBands }, { plotLines: chartOptions().xAxis.plotLines });

    const line = data[0]?.values.find((obj) => obj.value.length > 0) ? data[0].values.find((obj) => obj.value.length > 0).value : null;

    if (line && !line.length > 0) return;
    plotObject.xAxis.plotBands.from = plotObject.xAxis.plotLines[0].value = monitoringStore().detailedData.status
        ? monitoringStore().detailedData.guidesStart
        : null
    plotObject.xAxis.plotBands.to = plotObject.xAxis.plotLines[1].value = monitoringStore().detailedData.status
        ? monitoringStore().detailedData.guidesEnd
        : null;

    return plotObject;
}

Highstock.wrap(Highstock.Chart.prototype, "getDataRows", removeNanValuesInCsvXls);
Highcharts.wrap(Highstock.Chart.prototype, "getDataRows", removeNanValuesInCsvXls);
Highcharts.wrap(Highcharts.Tooltip.prototype, "refresh", customRefreshTooltipFunction);
Highstock.wrap(Highstock.Tooltip.prototype, "refresh", customRefreshTooltipFunction);

if(Highstock.getOptions().exporting) Highstock.Chart.prototype.downloadXLSX = downloadChartInXLSXform; 
if(Highcharts.getOptions().exporting) Highcharts.Chart.prototype.downloadXLSX = downloadChartInXLSXform;