import Highcharts from 'highcharts';
import Utilities from 'Utilities/global';
import {licensing} from "common/store/licensing";
import moment from 'moment';
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";
import { userStore, vesselGroupUtils, vesselStore } from 'common/store/storeUtils';
import { downloadChartInCSVform, downloadChartInXLSXform } from 'Utilities/highchartsUtils';

const userTheme = userStore().user?.theme !== 'ds-white' ? 'darkTheme' : 'whiteTheme';

const fcrChartPayload = {
    xAxis: "VESSEL",
    aggregation: "SUM",
    withMetadata: true,
    metrics: [
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumptionAbsolute"
                }
            ]
        },
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "commonDGFOMassRateAbsolute"
                },
                {
                    metricName: "boilerCommonFoMassRateAbsolute"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc"
                },
                {
                    metricName: "ge_1_foConsumption_calc"
                },
                {
                    metricName: "bo_1_foConsumption_calc"
                }
            ]
        }
    ]
};

const setToMT = (value, category) => {
    if(category === 'MAIN_ENGINE' || category === 'VESSEL') return value/1000;
    return value;
}

const changeVesselIdToVesselName = (array) => {
    let response = [];
    if(array.value) array.value.forEach( subArray => {
        response.push({
            period: `${moment(subArray?.dailyDateRange?.timestampFrom).utc().format('DD/MM HH:mm')} - ${moment(subArray?.dailyDateRange?.timestampTo).utc().format('DD/MM HH:mm')}`,
            name: vesselStore().allVessels.find(vessel => vessel.vesselId === subArray.x)?.name,
            y: setToMT(parseFloat(subArray.y), array.metricCategory) || null,
            missingValues: subArray.missingValues && subArray.missingValues > Utilities.missingValuesLimit
            ? subArray.missingValues.toFixed(1) : null,
            backgroundColor: array.metricName === "me_1_foConsumption_calc" || array.metricName === "ge_1_foConsumption_calc" || array.metricName === "bo_1_foConsumption_calc"
            ? ThemeColorsForCharts()['reports_color']
            : subArray.missingValues && subArray.missingValues > Utilities.missingValuesLimit 
            ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor 
            : ThemeColorsForCharts()['sensor_color']

        });
    });
    return response;
};


const fcr = (id, response, updateState, extraChartConfigs, widget) => {
    if(response && response[0] && response[0]?.values?.length === 0) return updateState(id, {}, widget?.id);
    let datas = [1,2,3,4,5,6];

    const fleetName = vesselGroupUtils.getVesselGroupNameFromVesselId([vesselStore().fleetVessels[0].vesselId])
    const isFleetLight = licensing.fleetAllVesselsAreLight()
    
    if(response[0] && response[0].values) response[0].values.forEach(obj => {
        if(obj.metricName === 'commonDGFOMassRateAbsolute') {
            datas[0] = {...obj};
            datas[0].value.forEach(serie => {
                if(licensing.lightCondition(null, vesselStore().fleetVessels.filter(v => v.vesselId === serie.x)[0])) serie.y = null;
            });
        }
        if(obj.metricName === 'foVolConsumptionAbsolute') {
            datas[1] = {...obj};
            datas[1].value.forEach(serie => {
                if(licensing.lightCondition(null, vesselStore().fleetVessels.filter(v => v.vesselId === serie.x)[0])) serie.y = null;
            });
        }
        if(obj.metricName === 'boilerCommonFoMassRateAbsolute') {
            datas[2] = {...obj};
            datas[2].value.forEach(serie => {
                if(licensing.lightCondition(null, vesselStore().fleetVessels.filter(v => v.vesselId === serie.x)[0])) serie.y = null;
            });
        }
        if(obj.metricName === 'ge_1_foConsumption_calc') datas[3] = {...obj};
        if(obj.metricName === 'me_1_foConsumption_calc') datas[4] = {...obj};
        if(obj.metricName === 'bo_1_foConsumption_calc') datas[5] = {...obj};
    });

    updateState(id, { chart: {id: "fcrFleet"},
        yAxis: {
            title: { text: 'Fuel Consumption (mt)' }
        },
        customTooltip: true,
        tooltip: {
            formatter: function() {
                return (`
                <div class="flex-centered-col">
                    <div class="highcharts-fonts tooltip-title"><b>${this.point.period} (UTC)</b></div>

                    <div class="flex-centered-col tooltip-values" style="width: 100%;">
                        <div class="flex-space-between highcharts-fonts" style="width: 100%;" id="${this.point.stackTotal}">
                            <div class="flex-centered">
                                <div class="tooltip-circle" style="background-color:var(--primary_text);"></div>
                                <div>Total: &nbsp;</div>
                            </div>
                            <div><b>${this.point.stackTotal.toFixed(2)} mt</b></div>
                        </div>

                        <div class="flex-space-between highcharts-fonts" style="width: 100%;" id="${this.series.name}">
                            <div class="flex-space-between">
                                <div class="tooltip-circle" style="background-color:${this.point.backgroundColor};"></div>
                                <div style="margin-right: 24px;">${this.series.userOptions.tooltipTitle}: &nbsp;</div>
                            </div>
                            <div><b>${this.point.y.toFixed(2)} mt</b></div>
                        </div>

                        <div class="highcharts-fonts" id="${this.series.name}" style="width: 100%; text-align:left; ${!this.point.missingValues ? 'display:none' : 'display:block'}">
                            <div style="margin-left: 12px;">${this.point.missingValues > 0 ? this.point.missingValues + '% missing data' : ''}</div>
                        </div>
                        <div class="arrow-down" style="border-top-color:${this.point.backgroundColor}"></div>
                    </div>
                </div>
                `)
            },
            useHTML: true,
        },
        series: [
            {
                name: 'SensorBO',
                tooltipTitle: 'Sensor BO FC',
                data: changeVesselIdToVesselName(datas[2]),
                color: ThemeColorsForCharts()['third_stack_sensor'],
                stack: 'sensors',
                centerInCategory: true,
                groupPadding: 0.3,
                showInLegend: (changeVesselIdToVesselName(datas[2]).length > 0),
                tooltip: {valueDecimals: 2, valueSuffix: ` mt`} 
            },
            {
                name: 'SensorGE',
                tooltipTitle: 'Sensor GE FC',
                data: changeVesselIdToVesselName(datas[0]),
                color: ThemeColorsForCharts()['second_stack_sensor'],
                stack: 'sensors',
                centerInCategory: true,
                groupPadding: 0.3,
                showInLegend: (changeVesselIdToVesselName(datas[0]).length > 0),
                tooltip: {valueDecimals: 2, valueSuffix: ` mt`}    //${datas[0].unit}
            },
            {
                name: 'SensorME',
                tooltipTitle: 'Sensor ME FC',
                data: changeVesselIdToVesselName(datas[1]),
                color: ThemeColorsForCharts()['sensor_color'],
                stack: 'sensors',
                centerInCategory: true,
                groupPadding: 0.3,
                showInLegend: (changeVesselIdToVesselName(datas[1]).length > 0),
                tooltip: {valueDecimals: 2, valueSuffix: ` mt`}    //${datas[1].unit}
            }, 
            {
                name: 'TelegramBo',
                tooltipTitle: 'Reports BO FC',
                data: changeVesselIdToVesselName(datas[5]),
                color: ThemeColorsForCharts()['third_stack_reports'],
                stack: 'telegrams',
                centerInCategory: true,
                groupPadding: 0.3,
                showInLegend: (changeVesselIdToVesselName(datas[5]).length > 0),
                tooltip: {valueDecimals: 2, valueSuffix: ` ${datas[5].unit}`}
            },
            {
                name: 'TelegramGE',
                tooltipTitle: 'Reports GE FC',
                data: changeVesselIdToVesselName(datas[3]),
                color: ThemeColorsForCharts()['second_stack_reports'],
                stack: 'telegrams',
                centerInCategory: true,
                groupPadding: 0.3,
                showInLegend: (changeVesselIdToVesselName(datas[3]).length > 0),
                tooltip: {valueDecimals: 2, valueSuffix: ` ${datas[3].unit}`}
            },
            {
                name: 'TelegramME',
                tooltipTitle: 'Reports ME FC',
                data: changeVesselIdToVesselName(datas[4]),
                color: ThemeColorsForCharts()['reports_color'],
                stack: 'telegrams',
                centerInCategory: true,
                groupPadding: 0.3,
                showInLegend: (changeVesselIdToVesselName(datas[4]).length > 0),
                tooltip: {valueDecimals: 2, valueSuffix: ` ${datas[4].unit}`}
            },
        ],
        exporting: {
            enabled: true,
            buttons: {contextButton: {
                menuItems: [
                    "download",
                    "customSeparator",
                    {
                        text: "XLSX",
                        onclick: function () {
                            let name = `${fleetName}-FUEL CONSUMPTION`;
                            
                            let sheetsName = name
                            const rows = this.getDataRows(true);
                            let headers = this.getDataRows(true)[0]
        
                            if (sheetsName.length > 30) {
                                sheetsName = "FUEL CONSUMPTION"
                            }
                            
                            headers = headers.map((val) => {
                                if (typeof val !== 'string') return val; // Ensure val is a string     
                                return val.toUpperCase().includes("CATEGORY") ? 'Vessel name'
                                    : val.toUpperCase().includes('SENSORBO') ? 'BO Sensor consumption (mt)'
                                    : val.toUpperCase().includes('SENSORGE') ? 'GE Sensor consumption (mt)'
                                    : val.toUpperCase().includes('SENSORME') ? 'ME Sensor consumption (mt)'
                                    : val.toUpperCase().includes('TELEGRAMBO') ? 'BO Report consumption (mt)'
                                    : val.toUpperCase().includes('TELEGRAMGE') ? 'GE Report consumption (mt)'
                                    : val.toUpperCase().includes('TELEGRAMME') ? 'ME Report consumption (mt)'
                                    : val;
                            });
                            let updatedows = []

                            if (isFleetLight) {
                                headers = [headers[0], headers[4], headers[5], headers[6]]
                                const rowsForLightFleet = rows.map((row) => {
                                    return [
                                        row[0],
                                        row[4],  
                                        row[5],  
                                        row[6] 
                                    ];
                                });
                                updatedows = [headers, ...rowsForLightFleet.slice(2, rows.length)];            
                            } else {
                                updatedows = [headers, ...rows.slice(2, rows.length)];
                            }

                            // remove special characters before the download function
                            // eslint-disable-next-line no-useless-escape
                            name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                            name = name.replace(/\./g, "");
        
                            // eslint-disable-next-line no-useless-escape
                            sheetsName = sheetsName.replace(/[:\/\\\?\*\[\]]/g, "");
                            downloadChartInXLSXform({
                                xlsxRows: updatedows,
                                sheetsName: sheetsName,
                                name: name
                            });
                        }
                    },
                    {
                        text: "CSV",
                        onclick: function () {
                            let name = `${fleetName}-FUEL CONSUMPTION`;
                            // eslint-disable-next-line no-useless-escape
                            name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                            name = name.replace(/\./g, "");
        
                            const rows = this.getDataRows(true);
                            let headers = this.getDataRows(true)[0]
                            
                            headers = headers.map((val) => {
                                if (typeof val !== 'string') return val; // Ensure val is a string     
                                return val.toUpperCase().includes("CATEGORY") ? 'Vessel name'
                                    : val.toUpperCase().includes('SENSORBO') ? 'BO Sensor consumption (mt)'
                                    : val.toUpperCase().includes('SENSORGE') ? 'GE Sensor consumption (mt)'
                                    : val.toUpperCase().includes('SENSORME') ? 'ME Sensor consumption (mt)'
                                    : val.toUpperCase().includes('TELEGRAMBO') ? 'BO Report consumption (mt)'
                                    : val.toUpperCase().includes('TELEGRAMGE') ? 'GE Report consumption (mt)'
                                    : val.toUpperCase().includes('TELEGRAMME') ? 'ME Report consumption (mt)'
                                    : val;
                            });
                            let updatedows = []

                            if (isFleetLight) {
                                headers = [headers[0], headers[4], headers[5], headers[6]]
                                const rowsForLightFleet = rows.map((row) => {
                                    return [
                                        row[0],
                                        row[4],  
                                        row[5],  
                                        row[6] 
                                    ];
                                });
                                updatedows = [headers, ...rowsForLightFleet.slice(2, rows.length)];            
                            } else {
                                updatedows = [headers, ...rows.slice(2, rows.length)];
                            }
                            
                            const csvContent = updatedows
                                .map((row) => row?.join(","))?.join("\n");
        
                            downloadChartInCSVform({ csvContent, name: name });
                        }
                    }
                ]                
            }},
            chartOptions: {
                chart: {backgroundColor: ThemeColorsForCharts()['platform_background']}
            },
        }
}, widget?.id);
};

export {fcrChartPayload, fcr};
