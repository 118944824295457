import React, { useState, useEffect } from 'react';
import Layout from 'components/core/Layout';
import ReportsService from "common/js/reports";
import CiiVesselOverview from 'widgets/vesselData/ciiVesselOverview';
import { ciiVesselOverviewUpdate } from 'widgets/vesselData/ciiVesselOverviewUtils';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import {monitoringStore, vesselStore, monitoringUtils} from 'common/store/storeUtils';
import GlobalUtilities from 'Utilities/global';
import CiiLegsEfficiency from 'widgets/vesselData/ciiLegsEfficiency';
import CiiVesselEfficiency from 'widgets/vesselData/ciiEfficiencyChart';
import { ciiLegsEfficiencyUpdate } from 'widgets/vesselData/ciiLegsEfficiencyUtils';
import service from 'common/js/service';
import { vesselEfficiencyDataUpdate } from 'widgets/vesselData/ciiEfficiencyUtils';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


let isMounted = false,
    thisYear = moment(GlobalUtilities.nowOrDemo()).year(),
    page = 0;
const defaultEfficiencyGranularity = 'WEEK';

const CiiVesselMonitoring = ({ overallReportsFromToChanged, overallReportsPeriod }) => {
    const reportsService = new ReportsService();
    const [ciiVesselOverview, setCiiVesselOverview] = useState({});
    const [ciiLegsEfficiency, setCiiLegsEfficiency] = useState();
    const [vesselEfficiencyData, setVesselEfficiencyData] = useState([]);
    const [hasNotTelegramConfiguration, setHasNotTelegramConfiguration] = useState();
    const [onDataChange, setOnDataChange] = useState(null);

    const [requestOnGoing, setRequestOnGoing] = useState({
        vesselRating: false,
        vesselEfficiencyData: false,
        ciiLegsEfficiency:false
    });
    
    const dispatch = useDispatch()
    const vesselStoreJSX = useSelector(state => state.vessel)
    const selectedPickerYear = useSelector(state => state.monitoring.ciiSearchingYear.selectedPickerYear);

    const getTelegramConfiguration = async () => {
        if (vesselStore().selectedVessel && vesselStore().selectedVessel !== -1){
            const telegramConfig = await service.getTelegramsConfig({vesselIds: vesselStore().selectedVessel }).then(response => {
                    return response?.data
                })
            if(telegramConfig) setHasNotTelegramConfiguration(!Object.values(telegramConfig)[0].hasTelegramConfiguration)
        }
    }

    const reportsComponents = [
        {
            widgetId: 'vesselEfficiencyData',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true, request: () => {
                        return { method: service.getVesselEfficiency, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),
                                vesselID :vesselStore().selectedVessel,
                                granularity : defaultEfficiencyGranularity 
                            }
                        }
                    }
                },
            },
            payload: '',
            type: ''
        },
        {
            widgetId: 'ciiLegsEfficiency',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {  
                value: true, externalURequest: {
                    value: true, request: () => {
                        return { method: service.getEfficiencyLegs, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(), 
                                vesselID: vesselStore().selectedVessel,
                                page,
                            } 
                        }
                    }
                }
            },
            payload: '',
            type: ''
        },

        {
            widgetId: 'ciiVesselOverview',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true,
                    request: () => {
                        return { 
                            method: service.getCiiVesselOverview, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(), 
                                vesselID: vesselStore().selectedVessel
                            } 
                        }
                    }
                },
                overwriteHeader: 'get-vessel-class'
            },
            payload: '',
            type: 'text'
        },
    ];

    const getTitlesFromWidgetIds = (widgetId) => {
        const idsTitlesMapper = {
            ciiVesselOverview: 'CII OVERVIEW',
            vesselEfficiencyData: 'CII OVER TIME',
            ciiLegsEfficiency: "CII LEGS' EFFICIENCY",
        }
        
        return idsTitlesMapper[widgetId];
    }

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7',
            layout: [{
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
                    components: [
                        { id: 'ciiVesselOverview', title: getTitlesFromWidgetIds('ciiVesselOverview'), component: CiiVesselOverview, data: {}, style: { height: '463px' }, props: { removeFromLayout: ciiVesselOverview === "" } }
                    ]
                }, {
                    grid: { xs: 12, sm: 12, md: 12, lg: 8, xl: 8 },
                    components: [
                        { id: 'vesselEfficiencyData', title: getTitlesFromWidgetIds('vesselEfficiencyData'), component: CiiVesselEfficiency, data: {}, style: { height: '463px' }, props: { removeFromLayout: vesselEfficiencyData === "" } }
                    ]
                },
                 {
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                    components: [
                        { id: 'ciiLegsEfficiency', title: getTitlesFromWidgetIds('ciiLegsEfficiency'), component: CiiLegsEfficiency, data: {}, style: { height: '600px' }, props: { removeFromLayout: ciiLegsEfficiency === "" } }
                    ]
                }
                ]
            }]
        };
    };


    const createLayoutProps = (data, props = getWidgetsPreferences()) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    if (('id' in newComponent) && !('element' in newComponent)) {
                        if (newComponent.id in data) newComponent.props = data[newComponent.id];
                    }
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    };

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currenRequestOnGoing) => { return {...currenRequestOnGoing, [key]: value}});
    }

    const reportsResponseMapper = (data, widgetId) => {
        const mapper = {
            ciiVesselOverview: ciiVesselOverviewUpdate,
            vesselEfficiencyData: vesselEfficiencyDataUpdate,
            ciiLegsEfficiency: ciiLegsEfficiencyUpdate,
        }
        const stateMapper ={
            ciiVesselOverview,
            vesselEfficiencyData,
            ciiLegsEfficiency,
        }
        return mapper[widgetId](
            widgetId,
            data,
            updateStateMapper,
            null,
            reportsComponents.find(report => report.widgetId === widgetId),
            dispatch,
            widgetId === 'vesselEfficiencyData' ? defaultEfficiencyGranularity : null,
            page,
            stateMapper[widgetId]
        );
    }

    const updateStateMapper = (key, value) => {
        const updateMapper = {
            ciiVesselOverview: setCiiVesselOverview,
            vesselEfficiencyData: setVesselEfficiencyData,
            ciiLegsEfficiency: setCiiLegsEfficiency,
        }
        return updateMapper[key](value);
    }

    const fetchMore = () => {
        if(ciiLegsEfficiency?.totalPages && ciiLegsEfficiency.totalPages === (page+1)) return;
        page += 1;
        reportsService.registerReports(
            [vesselStore().selectedVessel],
            reportsComponents.filter(report => report.widgetId === 'ciiLegsEfficiency'),
            reportsResponseMapper,
            null,
            true,
        );
    }

    const initCiiOverall = () => {
        // trigger with onDataChange variable the segments buttons widget
        // in order to return the segment selection to default
        setOnDataChange(new Date());

        registerWidgetsStore.setFromTo(monitoringStore().fromTo);
        // if overview storage has saved data, then use them and don't make new requests to get the data
        reportsService.registerReports(
            [vesselStore().selectedVessel],
            reportsComponents,
            reportsResponseMapper,
            updateRequestOnGoing,
            true
        );
    }

    useEffect(() => {
        if (!isMounted) return;
        page = 0;
        getTelegramConfiguration();
        initCiiOverall();
        // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);
    
    useEffect(() => {
        if (!isMounted) return;
        if (!overallReportsFromToChanged) return;
        if (!overallReportsPeriod?.from || !overallReportsPeriod?.to) return;
        
        page = 0;
        return initCiiOverall();
        
        // eslint-disable-next-line
    }, [overallReportsFromToChanged]);
    
    useEffect(() => {
        isMounted = true;
        const controller = new AbortController();
        GlobalUtilities.signal = controller.signal;
        getTelegramConfiguration();
        
        if (!overallReportsPeriod?.from || !overallReportsPeriod?.to) return;
        
        initCiiOverall();
        
        return () => {
            controller.abort();
            isMounted = false;
            GlobalUtilities.signal = null;
            page = 0;
        }
        // eslint-disable-next-line
    }, []);
    
    const data = {
        ciiVesselOverview: {
            data: ciiVesselOverview,
            allowTelegrams: monitoringUtils.allowTelegrams(),
            loading: requestOnGoing.ciiVesselOverview,
            hasNotTelegramConfiguration
        },
        vesselEfficiencyData: {
            options: vesselEfficiencyData,
            noStack: true,
            loading: requestOnGoing.vesselEfficiencyData,
            selectedPickerYear,
            thisYear,
            hasNotTelegramConfiguration,
            defaultInputProps: {
                onDataChange,
                options: [
                    { label: "Weekly", value: "WEEK" },
                    { label: "Monthly", value: "MONTH" }
                ],
                defaultActive: 'WEEK'
            },
            innerRequest: async selectedSegment => {
                return service.getVesselEfficiency({
                    granularity: selectedSegment,
                    vesselID : vesselStoreJSX.selectedVessel,
                    from: moment(`01/01/${selectedPickerYear}`,"DD/MM/YYYY").utc(true).valueOf(),
                    to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),

                })
            },
            innerUpdate: (response, selectedSegment, setChartOptions, chartOptions) => vesselEfficiencyDataUpdate(
                'vesselEfficiencyData',
                response, null,
                setChartOptions,
                chartOptions,
                null,
                selectedSegment
            ),
            footerInfo: {
                title: 'LEGEND:',
                subtitle: 'Required CII'
            }
        },
        ciiLegsEfficiency:{
            data: ciiLegsEfficiency?.content || [],
            extra:  {
                ...ciiLegsEfficiency,
                fetchMore,
                page,
            },
            loading: requestOnGoing.ciiLegsEfficiency,
            hasNotTelegramConfiguration
        }
    }

    return (
        <div className="cii-vessel-monitoring main-content-padding pad-t-0">
            <div className="cii-vessel-monitoring__widgets">
                <Layout {...createLayoutProps(data)} />
            </div>
        </div>
    );
}

export default CiiVesselMonitoring;
