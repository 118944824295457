import React, { useState, useEffect } from 'react'
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';
import {userStore} from 'common/store/storeUtils';
import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';


const chartRef = React.createRef();

const SyncCharts = (props) => {
    const [allInOne, setAllInOne] = useState(syncChartsOptions());
    
    useEffect(() => {
        if(!props?.options?.series) return;
        if(props?.options?.series.length === 0) return;
        if(isEqual(allInOne?.series, props?.options?.series)) return;

        if(props?.options?.series && props?.options?.series.length > 0 && props?.options.defaultZoom) {
            let dataArray = props?.options?.series.filter(serie => serie?.data?.length > 0)[0];
            //if chart has data and ref
            if(dataArray?.data && chartRef?.current?.chart) {
                dataArray = dataArray.data;
                // const granularity = moment( dataArray[dataArray.length - 1].x ).diff(moment(dataArray[dataArray.length - 2].x), 'minutes');
                //if chart points are more than 60 points then set the chart zoom according the default zoom prop and granularity
                if(dataArray?.length > 60) {
                    chartRef.current.chart.xAxis[0].setExtremes(
                        moment( dataArray[dataArray?.length - 1].x ).subtract(props?.options?.defaultZoom, 'hours').valueOf(), 
                        dataArray[dataArray?.length - 1].x
                    );
                }
                //if chart points are less than 60 points then the default zoom set to all chart points
                if(dataArray?.length <= 60 && dataArray?.length > 0) {
                    chartRef.current.chart.xAxis[0].setExtremes(
                        dataArray[0].x, 
                        dataArray[dataArray.length - 1].x
                    ); 
                }
            }
        }

        if(props.setNavigatorExtremes) {
            const data = props?.options?.series?.filter(serie => serie?.data?.length > 0)?.[0]?.data;
            props.setNavigatorExtremes(data, chartRef, props);
        }

        // if(props.series && props.series.length > 0 && props.resetZoomAfterUpdate && chartRef.current) {
        //     chartRef.current.chart.xAxis[0].setExtremes(null,null);
        // }

        if(props?.options?.tooltip) {
            props.options.tooltip.backgroundColor = (userStore().user?.theme !== 'ds-white') ? '#142536' : 'white';
            props.options.tooltip.style = {"color": (userStore().user?.theme !== 'ds-white') ? "white" : "#3b464f", "fontSize": "13px", "whiteSpace": "nowrap"};
        }

        // cloneDeep is used because when series array updates React don't rerender causing outdated data in the chart
        setAllInOne(cloneDeep(props?.options));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options]);

    return (
        <HighchartsReact 
            id={`sync-charts-container-${props?.options?.elementId || moment().valueOf()}`}
            ref={chartRef}
            constructorType={"stockChart"}
            options={allInOne}
            highcharts={Highcharts}
        />
    )
}

export default SyncCharts;