import React from 'react';

export const fleetHeadCells = [
    {
        id: "vesselName",
        numeric: false,
        sortable: true,
        label: "Vessel Name",
        align: 'left'
    },
    {
        id: "latestFouling",
        numeric: true,
        sortable: true,
        label: <span>Latest Fouling <span className="font-weight-normal">(%)</span></span>,
        align: 'right'
    },
    {
        id: "modelConfidence",
        numeric: false,
        sortable: true,
        label: "Model Confidence",
        align: 'left'
    },
    {
        id: "hullDegradationSinceLastDrydock",
        numeric: true,
        sortable: true,
        label: <span className="text-right">
            Hull Degradation since<br/> DD
            <span className="font-weight-normal">(%)</span>
        </span>,
        align: 'right'
    },
    {
        id: "hullDegradationSinceLastCleaningEvent",
        numeric: true,
        sortable: true,
        label: <span className="text-right">
            Hull Degradation since<br/> UW
            <span className="font-weight-normal">(%)</span>
        </span>,
        align: 'right'
    },
    {
        id: "annualHullDegradationRate",
        numeric: true,
        sortable: true,
        label: <span className="text-right">
            Annual Hull Degradation<br/> Rate
            <span className="font-weight-normal">(%)</span>
        </span>,
        info: "Projection of the potential hull degradation after one year, based on the current fouling rate.",
        align: 'right'
    },
    {
        id: "latestDrydock",
        numeric: false,
        sortable: true,
        label: "Latest DD",
        align: 'left'
    },
    {
        id: "latestCleaningEvent",
        numeric: false,
        sortable: true,
        label: "Latest UW",
        info: <div className="overview-table__cleaning-event-tooltip">
            <span className="description">UW cleaning event abbreviations</span>
            <div className="event"><span>PP:</span> <span>Propeller Polishing</span></div>
            <div className="event"><span>BTP:</span> <span>Bow Thruster Polishing</span></div>
            <div className="event"><span>SCC:</span> <span>Sea Chest Cleaning</span></div>
            <div className="event"><span>HFC:</span> <span>Hull Full Cleaning</span></div>
            <div className="event"><span>HSC:</span> <span>Hull Side Cleaning</span></div>
            <div className="event"><span>HBC:</span> <span>Hull Bottom Cleaning</span></div>
        </div>,
        align: 'left'
    },
];
