import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import Utils from 'Utilities/global';
import { darkChartVariables, whiteChartVariables } from 'Utilities/themeColorsForCharts';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const chartLinesColors = (ThemeColorsForCharts) => [
    ThemeColorsForCharts['malibu_blue'], ThemeColorsForCharts['golden_corn'], ThemeColorsForCharts['violet'], ThemeColorsForCharts['ruby_color'],
    ThemeColorsForCharts['amaranth'], ThemeColorsForCharts['carrot_orange'], ThemeColorsForCharts['cerulean'], ThemeColorsForCharts['brandy_rose'],
    ThemeColorsForCharts['main_blue'], ThemeColorsForCharts['golden_corn'], ThemeColorsForCharts['violet'], ThemeColorsForCharts['green_bay']
];

Highcharts.darkTheme = {
    colors: chartLinesColors(darkChartVariables),

    chart: {
        backgroundColor: 'transparent',
        style: {
            fontFamily: "'Open Sans', sans-serif",
        },
        resetZoomButton: {
            theme: {
                fill: darkChartVariables['main_blue'],
                stroke: darkChartVariables['main_blue'],
                style: {color: darkChartVariables['primary_text']},
                r: 3,
                states: {
                    hover: {
                        color: darkChartVariables['primary_text'],
                        fill: darkChartVariables['main_blue'],
                        style: { color: darkChartVariables['primary_text']}
                    }
                }
            }
        }
    },

    yAxis: {
        title: {
            text: '',
            align: 'high', 
            textAlign: 'left',
            rotation: 0, 
            reserveSpace: false,
            x: 35, 
            y: -15,
            style: {
                fontSize: '11px',
                color: darkChartVariables['secondary_text'],
                whiteSpace: 'nowrap'
            }
        },
        gridLineColor: darkChartVariables['lines_on_charts'],
        labels: {style:{"color": darkChartVariables['primary_text']}, y: 3, x: -16},
        showLastLabel: true
    },

    xAxis: { 
        title: {style: {color: darkChartVariables['secondary_text'], fontSize: '11px', margin: 12}},
        offset: 16,
        lineWidth: 0,
        labels: {y: 15, style:{"color": darkChartVariables['primary_text']}, autoRotation: 0},
    },

    title: {
        style: {
            color: darkChartVariables['primary_text'],
            font: 'bold 16px, sans-serif'
        }
    },
    subtitle: {
        style: {
            color: darkChartVariables['primary_text'],
            font: 'bold 12px, sans-serif'
        }
    },

    tooltip: {
        padding: 8,
        borderRadius: 3,
        distance: 20,
        borderWidth: 2,
        backgroundColor: darkChartVariables['second_level_card'],
        style: {"color": darkChartVariables['primary_text'], "fontSize": "13px", "whiteSpace": "nowrap", "minWidth": "150px"},
        missingValuesBackgroundColor: darkChartVariables['missing_first_stack_sensor']
    },

    plotOptions: {
        series: {
            borderRadius: 4,
            // shadow: {
            //     color: 'rgba(255,255,255,0.08)',
            //     width: 10,
            //     offsetX: 0,
            //     offsetY: 0
            // }
        },
        errorbar: {
            color: darkChartVariables['disabled_surface'],
            stemWidth: 1,
            whiskerLength: 5
        },
        column: {
            dataLabels: {
                shadow: false,
                style: {
                    color: darkChartVariables['primary_text'],
                    font: '11px, sans-serif',
                    fontWeight: 300
                },
                formatter: function() {
                    return parseFloat(Utils.roundToDigits(this.y, 1));
                }
            }
        }
    },

    legend: {
        itemStyle: {
            color: darkChartVariables['primary_text'],
            fontSize: '11px'
        },
        itemHoverStyle:{
            color: darkChartVariables['main_blue']
        }   
    },
    lang: {
        contextButtonTitle: 'Download'
    },
    exporting: {
        enabled: true,
        /**cas 02.35.01 we remove pdf, png, jpeg, svg exports from all the platform and we keep only xlsx and csv  */
        menuItemDefinitions: {
            // downloadPDF: { 
            //     text: "PDF document"
            // },
            // downloadPNG: {
            //     text: "PNG image"
            // },
            // downloadJPEG: {
            //     text: "JPG image"
            // },
            // downloadSVG: {
            //     text: "SVG image",
            // },
            download: {
                text: "<div style='font-weight: 600;'>Download as:</div>"
            },
            downloadXLSX: {
                text: "XLSX",
                onclick: function() {
                    this.downloadXLSX();
                }
            },
            csv: {
                text: "CSV",
                onclick: function() {
                    this.downloadCSV();
                }
            },
            customSeparator: {
                text: "<div style='background-color: #1E2E3E; width: 169px; height: 1px; margin-left: -18px;'><div>"
            }
        },
        buttons: {
            contextButton: {
                /**cas 02.35.01 we remove pdf, png, jpeg, svg exports from all the platform and we keep only xlsx and csv  */
                menuItems: ["download", "customSeparator", "downloadXLSX", "csv"],
                // menuItems: ["download", "customSeparator", "downloadXLSX", "csv", "customSeparator", "downloadPDF", "downloadPNG", "downloadJPEG", "downloadSVG"],
                symbol: 'download',
                symbolFill: darkChartVariables['primary_text'],
                symbolStroke: darkChartVariables['primary_text'],
                theme:{
                    fill: 'transparent',
                    stroke: 'transparent',
                },
                className: 'customExportingButton',
                menuClassName: 'customExportingMenu',
                width: 20,
                height: 20,
                x: -15,
            },
        }
    },

    navigation: {
        menuStyle: {
            "background": darkChartVariables['second_level_card'],
            "padding": "4px",
            "border": "none",
            "height": "122px",
            "width": "169px",
            "box-shadow": darkChartVariables['widgets_shadow'],
        },
        menuItemStyle: {
            "color": darkChartVariables['primary_text'],
            "fontSize": "14px",
        }
    },

    navigator: {
        height: 50,
        maskFill: 'rgba(10, 28, 45, 0.67)',
        maskInside: false,
        outlineWidth: 0,
        series: {
            type: 'areaspline',
            color: darkChartVariables['widget_header_divider'],
            lineColor: darkChartVariables['main_blue'],
            fillOpacity: 1,
            lineWidth: 2,
            dataGrouping: {smoothed: true},
            marker: {
                enabled: false
            }
        },
        xAxis: {
            tickWidth: 0,
            lineWidth: 0,
            gridLineWidth: 0,
            labels: {
                align: 'left',
                style: {color: darkChartVariables['secondary_text']}
            },
        },
        handles: {
            backgroundColor: darkChartVariables['hover_button'],
            borderColor: darkChartVariables['hover_button'],
            width: 6,
            height: 74,
        }
    },

    scrollbar: {
        enabled: true,
        trackBorderWidth: 0,
        buttonBorderWidth: 0,
        buttonBackgroundColor: darkChartVariables['platform_background'],
        buttonArrowColor: darkChartVariables['platform_background'],
        barBorderWidth: 0,
        barBackgroundColor: darkChartVariables['lines_on_charts'],
        height: 12,
        trackBackgroundColor: darkChartVariables['platform_background'],
        rifleColor: darkChartVariables['secondary_text']
    },

    noData: {
        style: {
            fontWeight: 'normal',
            fontSize: '14px',
            color: darkChartVariables['primary_text']
        }
    }

}

Highcharts.whiteTheme = {
    colors: chartLinesColors(whiteChartVariables),

    chart: {
        backgroundColor: 'transparent',
        style: {
            fontFamily: "'Open Sans', sans-serif",
        },
        resetZoomButton: {
            theme: {
                fill: whiteChartVariables['main_blue'],
                stroke: whiteChartVariables['main_blue'],
                style: {color: whiteChartVariables['standaredWhite']},
                r: 3,
                states: {
                    hover: {
                        color: whiteChartVariables['standaredWhite'],
                        fill: whiteChartVariables['main_blue'],
                        style: { color: whiteChartVariables['standaredWhite']}
                    }
                }
            }
        }
    },

    yAxis: {
        title: {
            text: '',
            align: 'high', 
            textAlign: 'left',
            rotation: 0, 
            reserveSpace: false,
            x: 35, 
            y: -15,
            style: {
                fontSize: '11px',
                color: whiteChartVariables['secondary_text'],
                whiteSpace: 'nowrap'
            }
        },
        gridLineColor: whiteChartVariables['lines_on_charts'],
        labels: {style:{"color": whiteChartVariables['primary_text']}, y: 3, x: -16},
        showLastLabel: true
    },

    xAxis: { 
        title: {style: {color: whiteChartVariables['secondary_text'], fontSize: '11px', margin: 12}},
        offset: 16,
        lineWidth: 0,
        labels: {y: 15, style:{"color": whiteChartVariables['primary_text']}, autoRotation: 0},
    },

    title: {
        style: {
            color: whiteChartVariables['primary_text'],
            font: 'bold 16px, sans-serif'
        }
    },
    subtitle: {
        style: {
            color: whiteChartVariables['primary_text'],
            font: 'bold 12px, sans-serif'
        }
    },

    tooltip: {
        padding: 12,
        distance: 20,
        borderRadius: 3,
        borderWidth: 2,
        backgroundColor: whiteChartVariables['standaredWhite'],
        style: {"color": whiteChartVariables['standaredWhite'], "fontSize": "13px", "whiteSpace": "nowrap", "minWidth": "150px"},
        missingValuesBackgroundColor: whiteChartVariables['missing_first_stack_sensor']
    },

    plotOptions: {
        series: {
            borderRadius: 4,
            // shadow: {
            //     color: 'rgba(0,0,0,0.08)',
            //     width: 10,
            //     offsetX: 0,
            //     offsetY: 0
            // }
        },
        errorbar: {
            color: whiteChartVariables['disabled_surface'],
            stemWidth: 2,
            whiskerLength: 7
        },
        column: {
            dataLabels: {
                shadow: false,
                style: {
                    color: whiteChartVariables['primary_text'],
                    font: '11px, sans-serif',
                    stroke: 'none'
                },
                formatter: function() {
                    return parseFloat(Utils.roundToDigits(this.y, 1));
                }
            }
        }
    },

    legend: {
        itemStyle: {
            color: whiteChartVariables['primary_text'],
            fontSize: '11px'
        },
        itemHoverStyle:{
            color: whiteChartVariables['main_blue'],
        }   
    },
    lang: {
        contextButtonTitle: 'Download'
    },
    exporting: {
        enabled: true,
        /**cas 02.35.01 we remove pdf, png, jpeg, svg exports from all the platform and we keep only xlsx and csv  */
        menuItemDefinitions: {
            // downloadPDF: { 
            //     text: "PDF document"
            // },
            // downloadPNG: {
            //     text: "PNG image"
            // },
            // downloadJPEG: {
            //     text: "JPG image"
            // },
            // downloadSVG: {
            //     text: "SVG image",
            // },
            download: {
                text: "<div style='font-weight: 600;'>Download as:</div>"
            },
            downloadXLSX: {
                text: "XLSX",
                onclick: function() {
                    this.downloadXLSX();
                }
            },
            csv: {
                text: "CSV",
                onclick: function() {
                    this.downloadCSV();
                }
            },
            customSeparator: {
                text: "<div style='background-color: #ebedef; width: 169px; height: 1px; margin-left: -18px;'><div>"
            }
        },
        buttons: {
            contextButton: {
                /**cas 02.35.01 we remove pdf, png, jpeg, svg exports from all the platform and we keep only xlsx and csv  */
                menuItems: ["download", "customSeparator", "downloadXLSX", "csv"],
                // menuItems: ["download", "customSeparator", "downloadXLSX", "csv", "customSeparator", "downloadPDF", "downloadPNG", "downloadJPEG", "downloadSVG"],
                symbol: 'download',
                symbolFill: whiteChartVariables['primary_text'],
                symbolStroke: whiteChartVariables['primary_text'],
                theme:{
                    fill: 'transparent',
                    stroke: 'transparent',
                },
                className: 'customExportingButton',
                menuClassName: 'customExportingMenu',
                width: 20,
                height: 20,
                x: -15
            },
        }
    },

    navigation: {
        menuStyle: {
            "background": whiteChartVariables['standaredWhite'],
            "padding": "4px",
            "border": "none",
            "height": "122px",
            "width": "169px",
            "box-shadow": whiteChartVariables['widgets_shadow'],
        },
        menuItemStyle: {
            "color": whiteChartVariables['primary_text'],
            "fontSize": "14px",
        }
    },

    navigator: {
        height: 50,
        maskFill: 'rgba(255, 255, 255, 0.67)',
        maskInside: false,
        outlineWidth: 0,
        series: {
            type: 'areaspline',
            color: whiteChartVariables['widget_header_divider'],
            lineColor: whiteChartVariables['main_blue'],
            fillOpacity: 1,
            lineWidth: 2,
            dataGrouping: {smoothed: true},
            marker: {
                enabled: false
            }
        },
        xAxis: {
            tickWidth: 0,
            lineWidth: 0,
            gridLineWidth: 0,
            labels: {
                align: 'left',
                style: {color: whiteChartVariables['secondary_text']}
            },
        },
        handles: {
            backgroundColor: whiteChartVariables['hover_button'],
            borderColor: whiteChartVariables['hover_button'],
            width: 6,
            height: 74
        }
    },

    scrollbar: {
        enabled: true,
        trackBorderWidth: 0,
        buttonBorderWidth: 0,
        buttonBackgroundColor: whiteChartVariables['platform_background'],
        buttonArrowColor: whiteChartVariables['platform_background'],
        barBorderWidth: 0,
        barBackgroundColor: whiteChartVariables['lines_on_charts'],
        height: 12,
        trackBackgroundColor: whiteChartVariables['platform_background'],
        rifleColor: whiteChartVariables['secondary_text']
    },

    noData: {
        style: {
            fontWeight: 'normal',
            fontSize: '14px',
            color: whiteChartVariables['primary_text']
        }
    }

}

Highstock.whiteTheme = {
    ...Highcharts.whiteTheme,
}

Highstock.darkTheme = {
    ...Highcharts.darkTheme,
}
