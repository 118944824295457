import React, { useState, useEffect } from 'react';
import OverallPeriodHeader from 'components/monitoringCategories/overallPeriodHeader';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import service from 'common/js/service';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import CiiMonitoring from 'components/monitoringCategories/ciiMonitoring';
import CiiVesselMonitoring from 'components/monitoringCategories/ciiVesselMonitoring';


const Environmental = () => {
    const [overallReportsFromToChanged, setOverallReportsFromToChanged] = useState(null);
    
    const selectedYearFromPicker = useSelector(state => state.monitoring.ciiSearchingYear.selectedPickerYear);
    const dispatch = useDispatch()

    const [selectedPickerYear, setSelectedPickerYear] = useState(selectedYearFromPicker);

    // store vars
    const vesselStoreJSX = useSelector(state => state.vessel);
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    

    const selectedPeriod = {
        from: moment(`01/01/${selectedYearFromPicker}`,'DD/MM/YYYY').utc(true).valueOf(),
        to: selectedYearFromPicker === moment().year() 
            ? moment.utc().valueOf() 
            : moment(`31/12/${selectedYearFromPicker}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf()
    }
    
    // On year change from picker set the selected year to state
    const goToDates = () => {
        dispatch({ type: 'monitoring/setCiiSearchingDatesFromTo', payload: { selectedPickerYear: selectedPickerYear }})
        setOverallReportsFromToChanged(new Date());
    };

    //this is the request to set the dates in the OverallPeriodHeader Component through redux store
    const getResponseDates = async () => {
        const data = (vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) 
            ? await service.getCompanyPeriod({from: selectedPeriod.from, to: selectedPeriod.to})
            : (vesselGroupStoreJSX.selectedVesselGroup !== vesselGroupStoreJSX.allVesselGroupsObj.id)
                && ( vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId ) 
                    ? await  service.getFleetPeriod({from: selectedPeriod.from, to: selectedPeriod.to, id: vesselGroupStoreJSX.selectedVesselGroup})
                    : await service.getVesselPeriod({from: selectedPeriod.from, to: selectedPeriod.to, id: vesselStoreJSX.selectedVessel})

        dispatch({ type: 'monitoring/setCiiDatesFromTo', payload: { from: data?.dateFrom, to: data?.dateTo }})
        registerWidgetsStore.setFromTo({from: data?.dateFrom, to: data?.dateTo});
    }


    // On mount, change fleet or vessel
    useEffect(() => {
        getResponseDates();
        // eslint-disable-next-line
    }, [vesselGroupStoreJSX.selectedVesselGroup, vesselStoreJSX.selectedVessel, overallReportsFromToChanged]);



    return (
        <div className='environmental'>
            <div className="vessel-monitoring-container">

                {/* On this div "main-content-padding" we have the fixed elements (titles, tabs, period header) */}
                <div className="main-content-padding">
                    {/* Page titles */}
                    <div className='section-title cii-page-title'>
                        CII MONITORING
                    </div>

                    <div className='vessel-monitoring-fixed-header'>
                        <OverallPeriodHeader 
                            reportsOverallPeriod={selectedPeriod}
                            selectedPickerYear={selectedPickerYear}
                            setSelectedPickerYear={setSelectedPickerYear}
                            goToDates={goToDates}
                        />
                    </div>
                </div>


                {/* if selected group and not selected vessel then render Group Page */}
                {
                    (vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id)
                    || ( vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId )
                        ? <div className="monitoring-pages">
                                <CiiMonitoring
                                    overallReportsPeriod={selectedPeriod}
                                    overallReportsFromToChanged={overallReportsFromToChanged}
                                />
                            </div>
                        : null
                }

                {/*if selected vessel then render tabs + ( Overall page or Performance page ) */}
                {
                    (vesselGroupStoreJSX.selectedVesselGroup !== vesselGroupStoreJSX.allVesselGroupsObj.id)
                    && ( vesselStoreJSX.selectedVessel !== vesselStoreJSX.allVesselsObj.vesselId )
                        ? <div className="monitoring-pages">
                            <CiiVesselMonitoring
                                overallReportsPeriod={selectedPeriod}
                                overallReportsFromToChanged={overallReportsFromToChanged}
                            />
                        </div>
                    : null
                }
            </div>
        </div>
    );
}
 
export default Environmental;