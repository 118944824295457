import meStorage from "../../components/monitoringCategories/localStorages/meStorage";
import syncChartsOptions from "../../components/charts/genericSyncCharts/syncChartsOptions";
import moment from "moment/moment";
import { monitoringStore, vesselStore, vesselUtils } from "../../common/store/storeUtils";
import { buildExportContextButton } from "../../Utilities/highchartsUtils";
import { mainEngineHardCodedHeaders } from "./generalDetails";
import { setReportsPlotLineGuides } from "../../Utilities/highchartsUtils";

let pistonDetailsJson = {};

const setBarTablesDataFormat = (barsTableData) => {
  let tmpObj = {};
  barsTableData.forEach(obj => {
    if (obj.value.length === 0) return;
    tmpObj[obj.metricName] = obj.value
  });
  return tmpObj;
};

export const pistonsChartPayload = {
  xAxis: "TIME",
  withNavigationStatus: true,
  aggregation: "AVG",
  metrics: [{
    metricCategory: "MAIN_ENGINE",
    subIds: [1],
    metricData: []
  }]
}

export const pistonLineChartData = {
  pistonsChartMetrics: [
    'cylExhGasOutTemp', 'cylJCFWOutTemp', 'cylPistonCOOutTemp', 'scavAirFireDetTemp'
  ],
  chartAxes: {},              // Dynamically generated in buildNavigationChartAxes method
  pistonsLineChartTabs: [],   // Dynamically generated in buildNavigationChartTabs method
  navigationMapper: {},       // Dynamically generated in buildNavigationMapper method
  chartSeries: {},             // Dynamically generated in buildNavigationChartSeries method
  currentTab: null,
  handleTabUpdate: null
}

export const buildPistonsChartPayload = (result) => {
  pistonsChartPayload.metrics[0].metricData = [];

  if (!result?.noCyllinders) result.noCyllinders = 12;
  for (let i = 1; i <= result.noCyllinders; i++) {
    pistonLineChartData.pistonsChartMetrics.forEach((metric, index) => {
      pistonsChartPayload.metrics[0].metricData.push({'metricName': `${metric}No${i}`});
    })
  }

  // Navigation Mapper - Build Navigation Mapper object
  buildNavigationMapper(result.noCyllinders);

  // Navigation Chart - Build Tabs Object
  buildNavigationChartTabs(result.noCyllinders);

  // Navigation Chart - Build Axes Object
  buildNavigationChartAxes(result.noCyllinders);

  // Navigation Chart - Build Series Object
  buildNavigationChartSeries(result.noCyllinders);
}

const buildNavigationMapper = noCyllinders => {
  pistonLineChartData.navigationMapper = {};

  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    const tempArr = pistonLineChartData.pistonsChartMetrics.map((metric) => {
      return `${metric}No${cylNum}`
    })

    pistonLineChartData.navigationMapper[`p${cylNum}`] = tempArr;
  }

}

const buildNavigationChartTabs = noCyllinders => {
  pistonLineChartData.pistonsLineChartTabs = [];
  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    pistonLineChartData.pistonsLineChartTabs.push({title: `P${cylNum}`, name: `p${cylNum}`});
  }

}

const buildNavigationChartAxes = noCyllinders => {
  pistonLineChartData.chartAxes = {};

  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    pistonLineChartData.chartAxes[`p${cylNum}`] = [
      { id: 'exhTemp-axis', title: { text: 'Exh. Gas Out. Temp (°C)', offset: 60}, height: '100px', top: '60px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      { id: 'jackCool-axis', title: { text: 'Jacket Cooling Fresh Water Out. Temp (°C)', offset: 60 }, height: '100px', top: '210px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      { id: 'pistCool-axis', title: { text: 'Piston Cooling Oil Out. Temp (°C)', offset: 60 }, height: '100px', top: '360px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      { id: 'scavFire-axis', title: { text: 'Scavenge Air Fire Detection Temp (°C)', offset: 60 }, height: '100px', top: '510px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
    ]
  }
}

const buildNavigationChartSeries = noCyllinders => {
  pistonLineChartData.chartSeries = {};
  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    pistonLineChartData.chartSeries[`p${cylNum}`] = [
      { id: `cylExhGasOutTempNo${cylNum}`, name: 'Exh. Gas Out. Temp', data: [], tooltipTitle: 'Exh. Gas Out. Temp', type: 'line', yAxis: 'exhTemp-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
      { id: `cylJCFWOutTempNo${cylNum}`, name: 'Jacket Cooling Fresh Water Out. Temp', data: [], tooltipTitle: 'Jacket Cooling Fresh Water Out. Temp', type: 'line', yAxis: 'jackCool-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
      { id: `cylPistonCOOutTempNo${cylNum}`, name: 'Piston Cooling Oil Out. Temp', data: [], tooltipTitle: 'Piston Cooling Oil Out. Temp', type: 'line', yAxis: 'pistCool-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
      { id: `scavAirFireDetTempNo${cylNum}`, name: 'Scavenge Air Fire Detection Temp', data: [], tooltipTitle: 'Scavenge Air Fire Detection Temp', type: 'line', yAxis: 'scavFire-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
    ]
  }
}

// this function is created in all js files of the components that use the SyncedTabWithTabNavigation component
// and recreates the chart json object taking each time the proper data based on the current tab from the JSON js object (response)
// and is mapped with the selected tab
const configureChartData = (currentTab, setSyncCharts, widget) => {
  if (!pistonDetailsJson[widget.id] || !currentTab) return;
  let options = syncChartsOptions();
  const vesselId = widget?.vesselIds?.[0] ? widget?.vesselIds[0] : vesselStore().selectedVessel;
  const tabName = currentTab === 'lOil' ? 'LUBE OIL' : currentTab === 'fOil' ? 'FUEL OIL' : currentTab;

  options["series"] = pistonLineChartData.chartSeries[currentTab];
  options["yAxis"] = pistonLineChartData.chartAxes[currentTab];
  options.chart.height = 770;
  options.title = '';
  options.elementId = 'pistons-details' || `${moment().valueOf()}`;
  options.exporting.buttons.contextButton.y = 15;
  if(options?.exporting?.chartOptions) {
    options.exporting.chartOptions.series = pistonLineChartData.chartSeries[currentTab];
    options.exporting.chartOptions.yAxis = pistonLineChartData.chartAxes[currentTab];
  }
  options.exporting.filename = `${vesselUtils.getVesselName(vesselId)}-${tabName.toUpperCase()}`
  options.exporting.buttons.contextButton = buildExportContextButton(tabName, mainEngineHardCodedHeaders['pistons-details']);
  options.exporting.chartOptions.title.text = `${pistonLineChartData.pistonsLineChartTabs?.find((tab) => tab.name === currentTab)?.title} details`;

  // Metrics already are counted with no1, no2 etc.. No conversion needs to be made to match with axis
  pistonLineChartData.navigationMapper && pistonLineChartData.navigationMapper[currentTab] && pistonLineChartData.navigationMapper[currentTab].forEach(category => {
    pistonLineChartData.chartSeries[currentTab].forEach((serie, index) => {
      if (category === serie.id) options.series[index].data = pistonDetailsJson[widget.id][category] || [];
    })
  })

  if (monitoringStore().detailedData?.status && setReportsPlotLineGuides) {
    let plotGuides = setReportsPlotLineGuides(pistonDetailsJson[widget.id]);
    if (plotGuides?.xAxis) options.xAxis = Object.assign({}, options.xAxis, plotGuides.xAxis);
  } else {
    options.xAxis.plotBands = syncChartsOptions().xAxis.plotBands;
    options.xAxis.plotLines = [];
  }

  pistonLineChartData.currentTab = currentTab;
  if(setSyncCharts) {
    setSyncCharts(options);
  } else return options;
}


export const updatePistonsChartJson = (id, data, updateState, extraChartConfigs, widget) => {
  // update state with empty object in order to show the NoData in case the response is empty
  if(data.length === 0) {
    updateState(id, {}, widget?.id);
    return;
  }

  meStorage.setPistonsData(data);
  pistonDetailsJson[widget.id] = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values) : [];
  pistonLineChartData.currentTab = pistonLineChartData.pistonsLineChartTabs[0]?.name;
  pistonLineChartData.handleTabUpdate = configureChartData;

  const currentTabDataConfigured = configureChartData(pistonLineChartData.pistonsLineChartTabs[0]?.name, null, widget);

  updateState(id, currentTabDataConfigured, widget?.id);
}
