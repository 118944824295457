import service from 'common/js/service';
import { monitoringStore, vesselStore } from 'common/store/storeUtils';
import { updateFoulingPenalty, cleaningEventsUpdate } from 'widgets/vesselData/foulingPenalty';
import hullStorage from "components/monitoringCategories/localStorages/hullStorage";
import syncChartsOptions from "components/charts/lineChart/react-lineChartStock.js";
import ThemeColors from "sass/_themeColorsForJs.scss";
import { vesselUtils } from 'common/store/storeUtils';
import Highcharts from "highcharts/highstock";
import Utilities from 'Utilities/global';
import moment from 'moment';
import { downloadChartInCSVform, downloadChartInXLSXform } from 'Utilities/highchartsUtils';

export const getFoulingPenaltyOvertimeTitle = () => 'FOULING PENALTY OVER TIME';

export const CONFIDENCE_MAPPER = {
    'Missing key input': {
        color: ThemeColors['error_text'],
        classes: 'missingKeyInput',
        footerText: 'Missing Key Input'
    },
    'Limited Input': {
        color: ThemeColors['disabled_text'],
        classes: 'limitedInput',
        footerText: 'Limited Input'
    },
    'Bad data quality': {
        color: ThemeColors['medium_severity'],
        classes: 'badDataQuality',
        footerText: 'Bad Data Quality'
    },
    'Data processing by A.I.': {
        color: ThemeColors['value_text'],
        classes: 'dataProcessingbyAI',
        footerText: 'Data Processing by AI'
    },
    'Low': {
        color: ThemeColors['low_severity'],
        classes: 'lowConfidence',
        footerText: 'Low Confidence'
    },
    'High': {
        color: ThemeColors['fine_status'],
        classes: 'highConfidence',
        footerText: 'High Confidence'
    }
};

export const yAxis = [
    { 
        id: 'confidence-level-axis',
        labels: { enabled: false },
        title: { text: 'Confidence level', x: 3, y: -9 },
        height: '4px', 
        top: '35px',
        min: 0,
        opposite: false,
        lineColor: 'transparent'
    },
    { 
        id: 'fouling-axis',
        title: { text: 'Fouling (%)', x: 33, y: -9 },
        labels: { y: 4 },
        top: '75px',
        height: '185px', 
        min: 0,
        maxPadding: 0.3, // give a little space on the top of the chart so that the event label points that are high can fit
        opposite: false,
        lineColor: 'transparent',
        minRange : 0.1 // use that for showing the yaxis zero line at the bottom of the chart even when we have only label events and not fouling series data
    }
];

const getCustomLabel = (foulingValue, xValue, confidenceColor) => {
    return {
        y: -15,
        x: -65,
        align: 'left',
        useHTML: true,
        formatter: function() {
            return(
                `
                <div class="wrapper-plot">
                    <div class="plot-label">
                        Fouling: ${foulingValue} ${foulingValue !== '-' ? '%' : ''} - ${moment(xValue).format('DD/MM/YY')}
                    </div>
                    <div class="plot-source" style="background-color: ${confidenceColor};"></div>
                </div>
                `
            )
        },
        textAlign: 'center',
        rotation: 0
    }
}

let series = [ 
    { name: 'Hull Fouling (%)', id: 'foulingPenaltyOvertime', data: [], tooltipTitle: '', type: 'line', yMin: 0, yAxis: 'fouling-axis'},
];

const getFlagsCommonSeries = (seriesId, isOnSeries, seriesData) => ({
    type: 'flags',
    id: seriesId,
    yAxis: 'fouling-axis',
    allowOverlapX: true, 
    data: seriesData,
    onSeries: isOnSeries ? 'foulingPenaltyOvertime' : undefined,
    shape: 'circlepin',
    lineColor: ThemeColors['malibu_blue'],
    tooltip: {
        enabled: true,
        outside: true,
        shared: true,
        headerFormat: '',
        pointFormatter: function() {
            const beforeCleaningValue = Utilities.renderNumber(Utilities.roundToDigits(this?.before, 2));
            const afterCleaningValue = Utilities.renderNumber(Utilities.roundToDigits(this?.after, 2));

            return `
                <div class="fouling-over-time-tooltip__eventLabel flex-centered-col">
                    <div class="full-width flex-centered small-label marg-b-5">
                        ${this?.eventType}
                    </div>

                    <div class="full-width flex-centered form-description marg-b-5">
                        ${moment(this?.x).utc().format('DD/MM/YY')}
                    </div>

                    <div class="flex-centered-col cleaning-event-dates form-description marg-b-5">
                        <div class="flex-space-between full-width">
                            <div>Before cleaning: </div>
                            <div class="small-label">${beforeCleaningValue} ${beforeCleaningValue !== '-' ? '%' : ''}</div>
                        </div>
                        <div class="flex-space-between full-width">
                            <div>After cleaning: </div>
                            <div class="small-label">${afterCleaningValue} ${afterCleaningValue !== '-' ? '%' : ''}</div>
                        </div>
                    </div>

                    <div class="flex-centered-col cleaning-event-maintenance-info form-description">
                        ${
                            this?.cleaningEvents?.length
                                ? this.cleaningEvents.map(operation => {
                                    return `
                                    <div class="flex-space-between full-width">
                                        <div>${operation?.part}:</div>
                                        <div class="small-label">${operation?.action}</div>
                                    </div>
                                    `
                                }).join('')
                                : ''
                        }
                    </div>

                </div>
            `;
        }
    }
});

export const updateFoulingPenaltyOvertime = async (id, data, updateState, extraChartConfigs, widget) => {    
    // in case data is null, then call the old fouling penalty request and it's update function 
    if(!data) {
        const cleaningEventsRes = await service.getCleaningEvents({ vesselId: vesselStore().selectedVessel, datetime: monitoringStore().fromTo.to });
        cleaningEventsUpdate(cleaningEventsRes);

        const res = await service.getPredictedPowerPenalty({ vessel: vesselStore().selectedVessel, date: monitoringStore().fromTo.to });
        updateFoulingPenalty(id, res, updateState, extraChartConfigs, widget);
    }
    else {
        hullStorage.setFoulingPenalty(data);

        // re-initialize the series, so that the confidence level series that were added before are removed
        series = [ 
            { name: 'Hull Fouling (%)', id: 'foulingPenaltyOvertime', data: [], tooltipTitle: '', type: 'line', yMin: 0, yAxis: 'fouling-axis'},
        ];

        let foulingPenaltyOvertimeJSON = { ...syncChartsOptions() };

        foulingPenaltyOvertimeJSON.chart = {
            height: 418,
            panning: true,
            zoomType: 'x',
            className: 'foulingPenaltyOverTimeChart'
        };
        foulingPenaltyOvertimeJSON.legend.enabled = false;
        foulingPenaltyOvertimeJSON.yAxis = yAxis;

        // create label for last point on top of the chart and at the same x value as the last point
        if(data?.fouling) {
            const lastPoint = data.fouling.find(point => point.lastPoint);

            if(lastPoint) {
                const foulingValue = Utilities.renderNumber(Utilities.roundToDigits(lastPoint.y, 2));

                foulingPenaltyOvertimeJSON.xAxis.plotLines = [{
                    color: 'transparent',
                    value: lastPoint.x,
                    label: getCustomLabel(foulingValue, lastPoint.x, CONFIDENCE_MAPPER[lastPoint.confidence]?.color)
                }]
            }
        }

        // the max time range of the handles to be 2 years
        foulingPenaltyOvertimeJSON.xAxis.maxRange = 63072000000;

        foulingPenaltyOvertimeJSON.xAxis.labels.formatter = function() {
            return Highcharts.dateFormat('%d/%m/%y', this.value);
        };

        foulingPenaltyOvertimeJSON.xAxis.labels.y = -5
        foulingPenaltyOvertimeJSON.xAxis.title.offset = 15;
        foulingPenaltyOvertimeJSON.series = series;

        series[0].data = data?.fouling;
        series[0].color = ThemeColors['malibu_blue'];

        foulingPenaltyOvertimeJSON.tooltip = {
            enabled: true,
            useHTML: true,
            split: true,
            shared: true,
            outside: true,
            headerFormat: '<div class="flex-centered-col" style="background-color: transparent">'+
                '<div class="highcharts-fonts"><b>'+
                    '{series.userOptions.tootltipXTitle}{point.x:%d/%m/%y}&nbsp;{series.userOptions.titleSuffix}</b></div>'+
                '<div class="flex-centered-col tooltip-values">'
        };

        series[0].tooltip = {
            enabled: true,
            outside: true,
            shared: true,
            pointFormatter: function() {
                if(!this?.label) {
                    const confidenceColor = CONFIDENCE_MAPPER[this?.confidence]?.color || ThemeColors['disabled_text'];
                    const foulingValue = Utilities.renderNumber(Utilities.roundToDigits(this?.y, 2));

                    return `
                        <div class="fouling-over-time-tooltip">
                            <div class="full-width flex-centered marg-b-5 small-label">
                                ${moment(this?.x).utc().format('DD/MM/YY')} (UTC)
                            </div>
                            <div class="full-width flex-centered-start">
                                <div class="tooltip-circle marg-r-5 marg-l-5" style="background-color: ${this?.color};"></div>
                                <div class="form-text form-description">Fouling: ${foulingValue} ${foulingValue !== '-' ? '%' : ''}</div>
                            </div>
                            <div class="form-text marg-l-15 form-description">
                                <span class="marg-r-5">Confidence level:</span>
                                <span style="color:${confidenceColor}">${this?.confidence}</span>
                            </div>
                        </div>
                    `;
                } else return '';
            },
        };
        const csvXlsxData = (obj) => {
            //making the axisData from the this.series obj
            const xData = obj.series[0].xData;
            const yData = obj.series[0].yData;
            //for each x we are adding arrays for serie x,y(x)
            let rows = xData.map((x, index) => [moment.utc(x).format("YYYY-MM-DD HH:mm:ss"), yData[index]]);
            //filtering each row for empty value but we want to keep the 0 values
            rows = rows.filter((row) => row[1] || row[1] === 0)
            //sort the arrays by date
            rows.sort((a, b) => moment.utc(b[0]).valueOf() - moment.utc(a[0]).valueOf());
            //putting the column names at the top of the array
            rows.unshift(["Date", "Hull Fouling (%)"]);
            
            const name = `${vesselUtils?.getVesselName(widget?.vesselIds[0])}-HULL FOULING`.substring(0, 31);           
            const sheetsName = `${vesselUtils?.getVesselName(widget?.vesselIds[0])}-HULL FOULING`.substring(0, 31);
            
            return {rows, name, sheetsName}
        }

        foulingPenaltyOvertimeJSON.exporting.buttons.contextButton= {
            menuItems: [
                "download",
                "customSeparator",
                {
                    text: "XLSX",
                    onclick: function () {
                        const dto = csvXlsxData(this)
                        
                        downloadChartInXLSXform({xlsxRows: dto.rows, sheetsName: dto.sheetsName, name: dto.name});
                    }
                },
                {
                    text: "CSV",
                    onclick: function () {
                        const dto = csvXlsxData(this)
                        //convert array to string with delimeter , for the csv
                        const csvContent = dto.rows.map(row => row.slice(0, 2).join(',')).join('\n');
                    
                        downloadChartInCSVform({csvContent, name: dto.name})
                    }
                },
              ]
        }
        
        if(data?.cleaningEvents?.length) {
            // put flags only for the label events
            const allFlagsData = data?.cleaningEvents?.map(dataItem => ({...dataItem, title: dataItem.label}));

            const flagsDataWithValues = allFlagsData.filter(dataItem => dataItem?.y !== null && dataItem?.y !== 0);
            const flagsDataWithNullValues = allFlagsData.filter(dataItem => dataItem?.y === null || dataItem?.y === 0).map(dataItem => ({...dataItem, y: 0}));

            if(flagsDataWithValues?.length) series.push(getFlagsCommonSeries('foulingOvertimeWithValues', true, flagsDataWithValues));
            if(flagsDataWithNullValues?.length) series.push(getFlagsCommonSeries('foulingOvertimeWithNullValues', false, flagsDataWithNullValues));   
        };

        // show only the 'xlsx' and 'csv' exporting options
        foulingPenaltyOvertimeJSON.navigation.menuStyle.height = 150;

        // create a series for each confidence level line
        if(data.confidenceLevel) {
            data.confidenceLevel.forEach((obj, index) => {
                // add as the first point of each line, the last point of the previous line
                if(index > 0) {
                    const previousConfidenceLevelData = data.confidenceLevel?.[index - 1]?.data;
                    obj.data.unshift(previousConfidenceLevelData?.[previousConfidenceLevelData?.length - 1]);
                }

                series.push({
                    id: `confidence-level-line${index}`,
                    type: 'line',
                    yAxis: 'confidence-level-axis',
                    data: obj.data,
                    color: CONFIDENCE_MAPPER[obj.confidence]?.color,
                    tooltip: { enabled: false },
                    name: `Confidence level ${index+1}`,
                })
            });
        }

        const chartHasData = foulingPenaltyOvertimeJSON.series.find(seriesObj => seriesObj?.data?.length > 0);
        if(!chartHasData) foulingPenaltyOvertimeJSON = {};

        updateState(id, { chartData: foulingPenaltyOvertimeJSON, confidenceLevelCategories: data?.confidenceLevelCategories }, widget?.id);
    }
}

