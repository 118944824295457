import React from 'react'
import {averageWind as updateAverageWind, averageWindPayload} from "reports/payloads/averageWind";
import {averageRpm as updateAverageRpm, averageRpmPayload} from "../../reports/payloads/averageRPM";
import service from "../../common/js/service";
import {vesselLog as updateVesselLog} from "../events/VesselLog";
import EventTypeList from "../../components/eventTypeList/eventTypeList";
import {hullInfoNamesMapper, updateHullInfoTableJson} from "../vesselData/hullInfoTable";
import moment from "moment";
import {LoadLineChartPayload, updateLoadLineChartJson} from "../mainEngine/loadLineChart.js";
import {mainEngineInfoNamesMapper, updateMeInfoJson} from "../mainEngine/meInfoTable";
import {FuelBarChartPayload, FuelBarChartPayload_light, updateFuelBarChart} from "../mainEngine/fuelBarChart.js";
import {FuelLineChartPayload, FuelLineChartPayload_light, FuelLineChartPredictedPayload, setPData, updateFuelLineChartJson} from "../mainEngine/fuelLineChart.js";
import {loadHistory as updateLoadHistoryLineChartJSON, loadHistoryPayload, loadHistoryPayload_light, setGesNum as setGesNumLoadHistory} from "../generatorEngine/loadHistoryLineChart.js";
import {sog as updateSOG, sogPayload} from "../../reports/payloads/sog";
import {generalBarsTablePayload, mainEngineHardCodedHeaders, mainEngineHardCodedKeys, mainEngineSyncedChartsConfig, updateGeneralBarsTable} from "../mainEngine/generalDetails";
import {buildPistonsChartPayload, pistonLineChartData, pistonsChartPayload, updatePistonsChartJson} from "../mainEngine/pistonsLineChart";
import {updateDeviationChartJson} from "../mainEngine/deviationBarChart.js";
import {averageCurrents as updateAverageCurrents, averageCurrentsPayload} from "../../reports/payloads/averageCurrents";
import {navigationStatusMilesPayload, navStatus, setMiles} from "../vesselData/navigationStatus";
import {navigationStatusChartUtils} from "../vesselData/navigationChart";
import {vesselMonitoringChartPayload, vesselMonitoringChartPayloadLight, vesselMonitoringChartUpdate} from "../vesselData/vesselMonitoringChartUtils";
import {fcr as updateFcr, fcrChartLightPayload, fcrChartPayload} from "../mainEngine/fcrVesselChart";
import {fcr as updateGroupFcr, fcrChartPayload as fcrGroupChartPayload} from "../mainEngine/fcrFleetChart";
import {columnDefs, fleetLog as updateFleetLog} from "../events/fleetLog";
import TableComp from "../../components/table/table";
import EventTypeGroup from "../../components/eventTypeGroup/eventTypeGroup";
import VesselNameBox from "../../components/vesselNameBox/vesselNameBox";
import {vesselRatingPayload, vesselRatingUpdate} from "../vesselData/vesselRatingJs";
import {fleetDailyInfo as updateFleetDailyInfo, fleetDailyInfoColumnDefs, fleetDailyInfoPayload} from "../vesselData/fleetDailyInfo";
import DoubleData from "../../components/table/doubleData/doubleData";
import ReportPeriod from "../../components/table/reportPeriod/reportPeriod";
import {licensing} from "../../common/store/licensing";
import ReportsService from 'common/js/reports';
import {buildTurboChargersChartPayload, turboChargersChartPayload, turboChargersLineChartData, updateTurboChargersChartJson} from "../mainEngine/turboChargersLineChart";
import {dashboardMapUpdate} from "../../components/map/dashboardMap.js";
import {legFuelConsumptionPayload, updateLegFuelConsumption} from 'widgets/mainEngine/legFuelConsumption';
import {fuelBarChartPayload, fuelBarChartPayload_light, updateFuelBarChart as updateFuelBarChartGE} from "../generatorEngine/fuelBarChart.js";
import {fuelLineChartPayload, fuelLineChartPayload_light, updateFuelLineChart} from "../generatorEngine/fuelLineChart.js";
import {turboChargersChartPayload as turboChargersChartPayloadGE, turboChargersLineChartData as turboChargersLineChartDataGE, updateTurboChargersChartJson as updateTurboChargersChartJsonGE} from "../../widgets/generatorEngine/turboChargersLineChart";
import {updateRunningTime} from "../generatorEngine/runningTime.js";
import {updateGeDetails, geDetailsPayload, geDetailsConfig, geDetailsHardCodedKeys} from "../generatorEngine/geDetails.js";
import {setBoilersRunningTimeTypes, updateBoilersRunningTime} from "../boilers/runningTime.js";
import {setBoilersFuelBarTypes, updateFuelBarChart as updateFuelBarChartBO, boilersFuelBarUpdatePayload, fuelBarChartPayload as fuelBarChartBOPayload, fuelBarChartPayload_light as fuelBarChartBOPayload_light} from "../boilers/fuelBarChart.js";
import {setBoilersFuelLineTypes, updateFuelLineChart as updateFuelLineChartBO, boilersFuelLineUpdatePayload, fuelLineChartPayload as fuelLineChartBOPayload, fuelLineChartPayload_light as fuelLineChartBOPayload_light} from "../boilers/fuelLineChart.js";
import {setBoilersDetailsTypes, updateBoilersDetailsChart, boilersDetailsUpdatePayload, boilersDetailsChartPayload, boilersDetailsLineChartData} from "../boilers/boilersDetailsChart.js";
import {powerMonitoringPayload, powerMonitoringPredictedPayload, setPowerPredictedData, updatePowerMonitoringJSON} from "../vesselData/powerMonitoring";
import {foulingWastePayload, setWasteTones, updateFoulingWaste} from "../vesselData/foulingWaste.js";
import {cleaningEventsUpdate, updateFoulingPenalty, getFoulingPenaltyTitle, getLegFoulingPenaltyTitle} from 'widgets/vesselData/foulingPenalty.js';
import HullFoulingPenaltyFooter from 'components/footers/dynamicFooters/hullFoulingPenaltyFooter.jsx'
import ArrivalDepartureFooter from 'components/footers/staticFooters/arrivalDepartureFooter.jsx';
import {totalFuelConsumptionAllMetricsPayload, updateTotalFuelConsumption} from 'reports/payloads/totalFuelConsumption.js';
import {generalKPISPayload, updateGeneralKPIS, updateVesselIsTelegramConfigured, generalKPISColumnDefs} from 'reports/payloads/generalKPIS';
import {legInfoPayload, legInfo as updateLegInfo} from 'widgets/vesselData/legInfo';
import {loadConditionPayload, loadConditionLightPayload, loadConditionDataPayload, loadCondition as updateLoadCondition, loadDataUpdate} from 'widgets/vesselData/loadCondition';
import {updateVesselTripsPorts} from 'reports/payloads/vesselTripsPorts.js';
import {
    vesselMonitoringChartPayload as vesselMonitoringChartPayloadLeg, 
    vesselMonitoringChartPayloadLight as vesselMonitoringChartPayloadLightLeg, 
    vesselMonitoringChartUpdate as vesselMonitoringChartUpdateLeg
} from "reports/payloads/vesselMonitoringChart";
import Utils from 'Utilities/global';
import dashboardRequestsUtilities from 'Utilities/dashboardRequestsUtils';
import { userStore, reportsUtils, vesselUtils, vesselGroupUtils, dashboardUtils } from 'common/store/storeUtils';

const reportsPayload = {
    averageWind: widget => {
        return {
            widgetId: 'averageWind',
            hasTimePeriod: true,
            singleRequest: {value: true, externalURequest: false, overwriteHeader: false},
            payload: averageWindPayload,
            type: 'text',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['averageWind']),
            updateHandler: updateAverageWind,
            style: {
                height: '177px'
            }
        }

    },
    averageCurrents: widget => {
        return {
            widgetId: 'averageCurrents',
            hasTimePeriod: true,
            singleRequest: {value: true, externalURequest: false, overwriteHeader: false},
            payload: averageCurrentsPayload,
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['averageCurrents']),
            type: 'text',
            updateHandler: updateAverageCurrents,
            style: {
                height: '177px'
            }
        }

    },
    averageRPM: widget => {
        return {
            widgetId: 'averageRPM',
            hasTimePeriod: true,
            singleRequest: {value: true, externalURequest: false, overwriteHeader: false},
            payload: averageRpmPayload,
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['averageRPM']),
            type: 'text',
            updateHandler: updateAverageRpm,
            style: {
                height: '177px'
            }
        }
    },
    sog: widget => {
        return {
            widgetId: 'sog',
            hasTimePeriod: true,
            singleRequest: {value: true, externalURequest: false, overwriteHeader: false},
            payload: sogPayload,
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['sog']),
            type: 'text',
            updateHandler: updateSOG,
            style: {
                height: '177px'
            }
        }
    },
    vesselLog: widget => {
        return {
            widgetId: 'vesselLog', singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getVesselEvents, params: {
                                value: widget.vesselIds,
                                startingDate: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['vesselLog'])?.from,
                                endingDate: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['vesselLog'])?.to
                            }
                        }
                    }
                }
            },
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['vesselLog']),
            type: 'table',
            updateHandler: updateVesselLog,
            style: {
                height: '400px'
            },
            extraProps: {
                component: EventTypeList,
                className: 'eventTypeList'
            }
        }
    },
    hullInfo: widget => {
        return {
            widgetId: 'hullInfo', singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getHullData, params:
                                {vesselId: widget.vesselIds}
                        }
                    }
                }
            }, payload: {}, type: 'text',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['hullInfo']),
            updateHandler: updateHullInfoTableJson,
            extraProps: {
                tableInfoNamesMapper: hullInfoNamesMapper
            }
        }

    },
    pvCurves: (widget) => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

        return { 
            widgetId: 'pvCurves',
            extraProps: {
                widget: widget,
                inDashboard: true,
                widgetTitle: 'POWER-SPEED',
                isLight: isLight,
                mode: 'chart',
                requestInfo: {
                    vesselId: widget?.vesselIds,
                    from: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['pvCurves'])?.from).valueOf(),
                    to: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['pvCurves'])?.to).valueOf()
                }
            }
        }
    },
    fvCurves: (widget) => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

        return { 
            widgetId: 'fvCurves',
            extraProps: {
                widget: widget,
                inDashboard: true,
                widgetTitle: 'FUEL-SPEED',
                isLight: isLight,
                mode: 'chart',
                requestInfo: {
                    vesselId: widget?.vesselIds,
                    from: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['fvCurves'])?.from).valueOf(),
                    to: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['fvCurves'])?.to).valueOf()
                }
            }
        }
    },
    hullPowerMonitoring: widget => {
        return {
            widgetId: 'hullPowerMonitoring',
            prerequisite: {
                isReport: true, report: {
                    widget: { ...widget, inDashboard: true },
                    inDashboard: true,
                    innerPrerequisite: true,
                    widgetId: 'powerPredictedData',
                    singleRequest: {value: true},
                    payload: powerMonitoringPredictedPayload,
                    specificDates: false,
                    type: 'basicChart'
                }, callback: setPowerPredictedData
            },
            specificDates: false,
            singleRequest: {value: true}, payload: powerMonitoringPayload, type: 'basicChart',
            updateHandler: updatePowerMonitoringJSON

        }
    },
    hullFoulingWaste: widget => {
        return {
            widgetId: 'hullFoulingWaste',
            prerequisite: {
                value: true, request: () => {
                    return {
                        method: service.getFuelWasteAmount, params: {
                            vesselId: widget.vesselIds,
                            from: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['hullFoulingWaste'])?.from.valueOf(),
                            to: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['hullFoulingWaste'])?.to.valueOf()
                        }
                    }
                }, callback: setWasteTones
            },
            singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getFuelWasteChart, params: {
                                payload: foulingWastePayload,
                                vesselId: widget.vesselIds[0],
                                fromDate: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['hullFoulingWaste'])?.from.valueOf(),
                                toDate: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['hullFoulingWaste'])?.to.valueOf()
                            }
                        }
                    }
                }
            },
            payload: {},
            type: 'text',
            updateHandler: updateFoulingWaste
        }
    },
    hullFoulingWaste_leg: widget => {
        return {
            widgetId: 'hullFoulingWaste_leg',
            prerequisite: {
                value: true, request: () => {
                    return {
                        method: service.getFuelWasteAmount, params: {
                            vesselId: widget.vesselIds,
                            from: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampFrom.valueOf(),
                            to: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampTo.valueOf()
                        }
                    }
                }, callback: setWasteTones
            },
            singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getFuelWasteChart, params: {
                                payload: foulingWastePayload,
                                vesselId: widget.vesselIds[0],
                                fromDate: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampFrom.valueOf(),
                                toDate: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampTo.valueOf()
                            }
                        }
                    }
                }
            },
            payload: {},
            type: 'text',
            updateHandler: updateFoulingWaste
        }
    },
    hullFoulingPenalty: widget => {
        return {
            widgetId: 'hullFoulingPenalty',
            singleRequest: {value: true, externalURequest: {
                    value: true,
                    request: () => {
                        return {
                            method: service.getPredictedPowerPenalty,
                            params:{ vessel: widget?.vesselIds[0], date: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['hullFoulingPenalty'])?.to.valueOf()}
                        }
                    }}},
            payload: {},
            type: 'text',
            prerequisite: {
                value: true,
                request: () => {
                    return {method: service.getCleaningEvents, params:{ vesselId: widget?.vesselIds[0], datetime: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['hullFoulingPenalty'])?.to }}
                },
                callback: cleaningEventsUpdate
            },
            extraProps: {
                customTitleFunc: getFoulingPenaltyTitle,
                footer: (data, widget) => {return <HullFoulingPenaltyFooter foulingPenalty={data} widget={widget}/>}
            },
            updateHandler: updateFoulingPenalty
        }
    },
    hullFoulingPenalty_leg: widget => {
        return {
            widgetId: 'hullFoulingPenalty_leg',
            singleRequest: {value: true, externalURequest: {
                    value: true,
                    request: () => {
                        return {
                            method: service.getPredictedPowerPenalty,
                            params:{ vessel: widget?.vesselIds[0], date: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampTo.valueOf()}
                        }
                    }}},
            payload: {},
            type: 'text',
            prerequisite: {
                value: true,
                request: () => {
                    return {method: service.getCleaningEvents, params:{ vesselId: widget?.vesselIds[0], datetime: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampTo.valueOf() }}
                },
                callback: cleaningEventsUpdate
            },
            extraProps: {
                customTitleFunc: getLegFoulingPenaltyTitle,
                footer: (data, widget) => {return <HullFoulingPenaltyFooter foulingPenalty={data} widget={widget}/>}
            },
            updateHandler: updateFoulingPenalty
        }
    },
    meLoad_hourly: widget => {
        return {
            widgetId: 'meLoad_hourly',
            specificDates: {
                from: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['meLoad_hourly'])?.from).valueOf(),
                to: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['meLoad_hourly'])?.to).valueOf()
            },
            singleRequest: {value: true},
            payload: LoadLineChartPayload,
            type: 'basicChart',
            updateHandler: updateLoadLineChartJson
        }
    },
    meInfo: widget => {
        return {
            widgetId: 'meInfo', singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getMeInfo, params: {vesselId: widget.vesselIds}
                        }
                    }
                }
            }, payload: {}, type: 'text',
            updateHandler: updateMeInfoJson,
            extraProps: {
                tableInfoNamesMapper: mainEngineInfoNamesMapper
            }
        }
    },
    meFC_daily: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        return {
            widgetId: 'meFC_daily',
            singleRequest: {value: true},
            payload: isLight ? FuelBarChartPayload_light : FuelBarChartPayload,
            type: 'basicChart',
            specificDates: {
                from: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['meFC_daily'])?.from)
                    .utc().set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0
                    }).valueOf(),
                to: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['meFC_daily'])?.to).valueOf()
            },
            updateHandler: updateFuelBarChart
        }
    },
    geFC_daily: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        return {
            widgetId: 'geFC_daily', singleRequest: {value: true},
            payload: isLight ? fuelBarChartPayload_light : fuelBarChartPayload,
            type: 'basicChart',
            specificDates: {
                from: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['geFC_daily'])?.from)
                    .utc().set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0
                    }).valueOf(),
                to: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['geFC_daily'])?.to).valueOf()
            },
            updateHandler: updateFuelBarChartGE
        }
    },
    meFC_hourly: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        return {
            widgetId: 'meFC_hourly',
            prerequisite: {
                isReport: true,
                report: {
                    widget: { ...widget, inDashboard: true },
                    inDashboard: true,
                    innerPrerequisite: true,
                    widgetId: 'fuelPdata',
                    singleRequest: {value: true},
                    payload: FuelLineChartPredictedPayload,
                    type: 'basicChart',
                    specificDates: false
                }, callback: setPData
            },
            singleRequest: {value: true, externalURequest: false},
            payload: isLight ? FuelLineChartPayload_light : FuelLineChartPayload,
            type: 'basicChart',
            updateHandler: updateFuelLineChartJson,
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['meFC_hourly'])
        }
    },
    geFC_hourly: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        return {
            widgetId: 'geFC_hourly',
            singleRequest: {value: true},
            payload: isLight ? fuelLineChartPayload_light : fuelLineChartPayload,
            type: 'basicChart',
            updateHandler: updateFuelLineChart,
            extraProps: {
                footer: () => <ArrivalDepartureFooter/>
            },
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['geFC_hourly'])
        }
    },
    meDetails_hourly: widget => {
        return {
            widgetId: 'meDetails_hourly',
            singleRequest: {value: true},
            payload: generalBarsTablePayload,
            type: 'basicChart',
            updateHandler: updateGeneralBarsTable,
            extraProps: {
                chartSeries: mainEngineSyncedChartsConfig.chartSeries,
                chartAxes: mainEngineSyncedChartsConfig.chartAxes,
                navigationMapper: mainEngineHardCodedKeys.navigationMapper,
                tabs: mainEngineHardCodedKeys.names,
                chartHeight: 930,
                className: "meDetails",
                exportingChartTitle: 'MAIN ENGINE DETAILS',
                elementId: 'main-engine-general',
                headers: mainEngineHardCodedHeaders['main-engine-general'],
                currentTab: mainEngineSyncedChartsConfig.currentTab,
                updateCurrentTab: mainEngineSyncedChartsConfig.handleTabUpdate,
            },
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['meDetails_hourly'])
        }
    },
    meTC_hourly: widget => {    
        return {
            widgetId: 'meTC_hourly', singleRequest: {value: true}, prerequisite: {
                value: true, request: () => {
                    return {
                        method: service.getMeInfo, params: {vesselId: widget.vesselIds}
                    }
                }, callback: buildTurboChargersChartPayload
            }, payload: turboChargersChartPayload, type: 'basicChart',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['meTC_hourly']),
            updateHandler: updateTurboChargersChartJson,
            extraProps: {
                chartSeries: turboChargersLineChartData.chartSeries,
                navigationMapper: turboChargersLineChartData.navigationMapper,
                chartAxes: turboChargersLineChartData.chartAxes,
                elementId: "turbo-chargers-details",
                headers: mainEngineHardCodedHeaders['turbo-chargers-details'],            
                chartHeight: 930,
                className: "turboChargersDetails",
                tabs: turboChargersLineChartData.turboChargersLineChartTabs,
                exportingChartTitle: 'TURBOCHARGER',
                currentTab: turboChargersLineChartData.currentTab,
                updateCurrentTab: turboChargersLineChartData.handleTabUpdate,
            }
        }
    },
    geTC_hourly: widget => {
        return {
            widgetId: 'geTC_hourly',
            singleRequest: {value: true},
            payload: turboChargersChartPayloadGE,
            type: 'basicChart',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['geTC_hourly']),
            updateHandler: updateTurboChargersChartJsonGE,
            extraProps: {
                chartSeries: turboChargersLineChartDataGE.chartSeries,
                navigationMapper: turboChargersLineChartDataGE.navigationMapper,
                chartAxes: turboChargersLineChartDataGE.chartAxes,
                chartHeight: 930,
                divideBySubId: true,
                className: "turboChargersDetails",
                tabs: turboChargersLineChartDataGE.turboChargersLineChartTabs,
                exportingChartTitle: 'TURBOCHARGER',
                currentTab: turboChargersLineChartDataGE.currentTab,
                updateCurrentTab: turboChargersLineChartDataGE.handleTabUpdate,
            }
        }
    },
    destinationCard: widget => {
        const reportsService = new ReportsService();

        return {
            widgetId: 'destinationCard', 
            singleRequest: {
                value: true, 
                externalURequest: {
                    value: true, 
                    request: () => {
                        return {
                            method: reportsService.registerLegs, 
                            params: {
                                id: widget?.vesselIds[0],
                                from: moment(Utils.nowOrDemo()).subtract(1, 'month').utc().valueOf(),
                                to: moment(Utils.nowOrDemo()).utc().valueOf()
                            }
                        }
                    }, 
                }
            },
            updateHandler: updateVesselTripsPorts
        }
    },
    stoppageInfo: widget => {
        return {
            widgetId: 'stoppageInfo',
            prerequisite: {
                isReport: true, report: {
                    widget: { ...widget, inDashboard: true },
                    inDashboard: true,
                    widgetId: 'setMiles', innerPrerequisite: true, singleRequest: {value: true, externalURequest: false},
                    payload: navigationStatusMilesPayload, type: 'text',
                    specificDates: false
                }, callback: setMiles
            },
            singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-navigation-status'},
            payload: '',
            type: 'text',
            hasTimePeriod: true,
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['stoppageInfo']),
            updateHandler: navStatus,
            style: {height: '200px'}
        }
    },
    travellingInfo: widget => {
        return {
            widgetId: 'travellingInfo',
            prerequisite: {
                isReport: true, report: {
                    inDashboard: true,
                    widget: { ...widget, inDashboard: true },
                    widgetId: 'setMiles', innerPrerequisite: true, singleRequest: {value: true, externalURequest: false},
                    payload: navigationStatusMilesPayload, type: 'text',
                    specificDates: false
                }, callback: setMiles
            },
            singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-navigation-status'},
            payload: '',
            type: 'text',
            hasTimePeriod: true,
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['travellingInfo']),
            updateHandler: navStatus,
            style: {height: '200px'}
        }
    },
    travellingInfo_leg: widget => {
        return {
            widgetId: 'travellingInfo_leg',
            singleRequest: {value: true},
            payload: legInfoPayload,
            specificDates: false,
            type: 'text',
            hasTimePeriod: true,
            updateHandler: updateLegInfo
        }
    },
    mePistons_hourly: widget => {
        return {
            widgetId: 'mePistons_hourly',
            singleRequest: {value: true},
            prerequisite: {
                value: true, request: () => {
                    return {
                        method: service.getMeInfo, params: {vesselId: widget.vesselIds}
                    }
                }, callback: buildPistonsChartPayload
            },
            payload: pistonsChartPayload,
            type: 'basicChart',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['mePistons_hourly']),
            updateHandler: updatePistonsChartJson,
            extraProps: {
                chartSeries: pistonLineChartData.chartSeries,
                navigationMapper: pistonLineChartData.navigationMapper,
                chartAxes: pistonLineChartData.chartAxes,
                chartHeight: 930,
                downloadIconY: 15,
                className: "pistonsDetails",
                tabs: pistonLineChartData.pistonsLineChartTabs,
                exportingChartTitle: 'PISTON DETAILS',
                elementId: 'pistons-details',
                headers: mainEngineHardCodedHeaders['pistons-details'],
                currentTab: pistonLineChartData.currentTab,
                updateCurrentTab: pistonLineChartData.handleTabUpdate,
            }
        }
    },
    mePistonsdeviation_hourly: widget => {
        return {
            widgetId: 'mePistonsdeviation_hourly',
            singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-piston-deviation'},
            payload: '',
            type: 'basicChart',
            specificDates: {
                from: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['mePistonsdeviation_hourly'])?.from).valueOf(),
                to: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['mePistonsdeviation_hourly'])?.to).valueOf()
            },
            updateHandler: updateDeviationChartJson,
            extraProps: {
                exporting: {
                    filename: 'PISTONS DEVIATION'
                }
            },
            style: {
                height: '795px'
            }
        }
    },
    geLoad_hourly: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

        return {
            widgetId: 'geLoad_hourly',
            singleRequest: isLight ? {value: true, externalURequest: false} : {value: true},
            payload: isLight ? loadHistoryPayload_light: loadHistoryPayload,
            type: 'basicChart',
            prerequisite: isLight ? { value: true, request: () => {return {method: service.getGeInfo, params: userStore().user?.company?.id }}, callback: setGesNumLoadHistory } : null,
            updateHandler: updateLoadHistoryLineChartJSON
        }
    },
    geLoad_hourly_leg: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

        return {
            widgetId: 'geLoad_hourly_leg',
            singleRequest: isLight ? {value: true, externalURequest: false} : {value: true},
            payload: isLight ? loadHistoryPayload_light: loadHistoryPayload,
            type: 'basicChart',
            prerequisite: isLight ? { value: true, request: () => {return {method: service.getGeInfo, params: userStore().user?.company?.id }}, callback: setGesNumLoadHistory } : null,
            updateHandler: updateLoadHistoryLineChartJSON
        }
    },
    navigationStatusChart: widget => {
        return {
            widgetId: 'navigationStatusChart',
            hasTimePeriod: true,
            singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-navigation-status'},
            payload: '',
            type: 'text',
            updateHandler: navStatus,
            extraProps: {
                genericChartOptions: navigationStatusChartUtils,
                progressChartOptions: navigationStatusChartUtils.progressChartOptions(widget),
            }
        }

    },
    vesselMonitoring: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

        return {
            widgetId: 'vesselMonitoring',
            singleRequest: {value: true, externalURequest: false},
            payload: isLight ? vesselMonitoringChartPayloadLight : vesselMonitoringChartPayload,
            type: 'basicChart',
            specificDates: false,
            updateHandler: vesselMonitoringChartUpdate,
            extraProps: {
                exportingChartTitle: "VESSEL'S MONITORING",

            }
        }
    },
    vesselMonitoring_leg: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

        return {
            widgetId: 'vesselMonitoring_leg',
            singleRequest: { value: true, externalURequest: false},
            payload: isLight ? vesselMonitoringChartPayloadLightLeg : vesselMonitoringChartPayloadLeg,
            type: 'basicChart',
            updateHandler: vesselMonitoringChartUpdateLeg,
            extraProps: {
                exportingChartTitle: "VESSEL'S MONITORING LEG",
            }
        }
    },
    splittedFC: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

        return {
            widgetId: 'splittedFC',
            singleRequest: {value: true},
            payload: isLight ? fcrChartLightPayload : fcrChartPayload,
            type: 'basicChart',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['splittedFC']),
            updateHandler: updateFcr,
            style: {
                height: '361px'
            },
            extraProps: {
                noStack: true,
                exportingChartTitle: 'FUEL CONSUMPTION RATE',
            }
        }
    },
    splittedGroupFC: widget => {
        return {
            widgetId: 'splittedGroupFC',
            singleRequest: {value: true},
            payload: fcrGroupChartPayload,
            type: 'basicChart',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['splittedGroupFC']),
            updateHandler: updateGroupFcr,
            style: {
                height: '340px'
            },
            extraProps: {
                noStack: false,
                exportingChartTitle: 'FUEL CONSUMPTION RATE',
            }
        }
    },
    groupLog: widget => {
        return {
            widgetId: 'groupLog',
            singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getGroupDailyTimelineEvents, params:
                                {
                                    value: vesselGroupUtils.getVesselGroupIdFromVesselId(widget?.vesselIds),
                                    datetime: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['groupLog'])?.from
                                }
                        }
                    }
                }
            }, payload: {}, type: 'table',
            updateHandler: updateFleetLog,
            extraProps: {
                columns: columnDefs,
                component: TableComp,
                frameworkComponents: {eventTypeGroup: EventTypeGroup, vesselNameBox: VesselNameBox},
                iconName: 'eventsBigIcon',
                componentGrid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 9},
                iconGrid: {xs: 'hidden', sm: 'hidden', md: 'hidden', lg: 'hidden', xl: 3},
            }

        }
    },
    vesselRating: widget => {
        return {
            widgetId: 'vesselRating',
            singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-vessel-class'},
            payload: vesselRatingPayload,
            type: 'text',
            hasTimePeriod: true,
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['vesselRating']),
            updateHandler: vesselRatingUpdate,
            extraProps: {
                allowTelegrams: true
            },
            style: {
                height: '430px'
            }
        }
    },
    groupInfo: widget => {
        return {
            widgetId: 'groupInfo',
            singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-daily-generic-report'},
            payload: fleetDailyInfoPayload,
            type: 'table',
            specificDates: {
                from: moment().subtract(3, 'days').valueOf(),
                to: moment().valueOf()
            },
            updateHandler: updateFleetDailyInfo,
            extraProps: {
                columns: fleetDailyInfoColumnDefs,
                frameworkComponents: {vesselNameBox: VesselNameBox, doubleData: DoubleData, reportPeriod: ReportPeriod},
                allowSorting: false,
            }
        }
    },
    mapVessel: widget => {
        return {
            widgetId: 'mapVessel',
            updateHandler: dashboardMapUpdate,
            bypassReports: true,
            extraProps: {
                state: {
                    fromTo: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['mapVessel']),
                    fromToChanged: true
                }
            }
        }
    },
    legMapVessel: widget => {
        return {
            widgetId: 'legMapVessel',
            updateHandler: dashboardMapUpdate,
            bypassReports: true,
            extraProps: {
                state: {
                    fromTo: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['legMapVessel']),
                    fromToChanged: true
                }
            }
        }
    },
    mapGroup: widget => {
        return {
            widgetId: 'mapGroup',
            updateHandler: dashboardMapUpdate,
            bypassReports: true,
            extraProps: {
                state: {
                    fromTo: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['mapGroup']),
                    fromToChanged: true
                }
            }
        }
    },
    mapCompany: widget => {
        return {
            widgetId: 'mapCompany',
            updateHandler: dashboardMapUpdate,
            bypassReports: true,
            extraProps: {
                state: {
                    fromTo: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['mapCompany']),
                    fromToChanged: true
                }
            }
        }
    },
    splittedFC_leg: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        return {
            widgetId: 'splittedFC_leg',
            singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-radial-report'},
            payload: isLight ? legFuelConsumptionPayload : legFuelConsumptionPayload,
            type: 'text',
            specificDates: false,
            updateHandler: updateLegFuelConsumption,
            style: {
                height: '367px'
            }
        }
    },
    geRunningTime_hourly: widget => {
        return {
            widgetId: 'geRunningTime_hourly', 
            singleRequest: {
                value: true, 
                externalURequest: {
                    value: true, 
                    request: () => { return {method: service.getRunningTimeSensoredAndTelegram, 
                        params: {
                            vesselId: widget.vesselIds[0], 
                            from: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['geRunningTime_hourly'])?.from, 
                            to: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['geRunningTime_hourly'])?.to
                        }}}
                }
            }, 
            payload: '',
            type: 'text',
            hasTimePeriod: true,
            updateHandler: updateRunningTime
        }
    },
    geRunningTime_hourly_leg: widget => {
        return {
            widgetId: 'geRunningTime_hourly_leg', 
            singleRequest: {
                value: true, 
                externalURequest: {
                    value: true, 
                    request: () => { return {method: service.getRunningTimeSensoredAndTelegram, 
                        params: {
                            vesselId: widget.vesselIds[0], 
                            from: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampFrom.valueOf(), 
                            to: dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampTo.valueOf()
                        }}}
                }
            }, 
            payload: '',
            type: 'text',
            hasTimePeriod: true,
            extraProps: {isReportsWidget: true},
            updateHandler: updateRunningTime,
        }
    },
    geDetails_hourly: widget => {
        return {
            widgetId: 'geDetails_hourly', singleRequest: { value: true }, payload: geDetailsPayload, type: 'basicChart',
            specificDates: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['geDetails_hourly']),
            updateHandler: updateGeDetails,
            extraProps: {
                chartSeries: geDetailsConfig.chartSeries,
                chartAxes: geDetailsConfig.chartAxes,
                navigationMapper: geDetailsHardCodedKeys.navigationMapper,
                tabs: geDetailsHardCodedKeys.names,
                engineTabs: geDetailsHardCodedKeys.enginesMapper,
                chartHeight: 930,
                className: "geDetailsLineChartJSON",
                exportingChartTitle: 'GENERATOR ENGINE DETAILS',
                divideBySubId: true,
                currentTab: geDetailsConfig.currentTab,
                updateCurrentTab: geDetailsConfig.handleTabUpdate,
                currentEngineTab: geDetailsConfig.currentEngineTab,
                updateCurrentEngineTab: geDetailsConfig.handleEngineTabUpdate,
            }
        }
    },
    boRunningTime_hourly: widget => {
        return {
            widgetId: 'boRunningTime_hourly', prerequisite: {
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: widget.vesselIds}
                }, callback: setBoilersRunningTimeTypes
            }, singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getBoilersRunningTime, params:
                                {
                                    vesselId: widget.vesselIds,
                                    from: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['boRunningTime_hourly'])?.from,
                                    to: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['boRunningTime_hourly'])?.to
                                }
                        }
                    }
                }
            }, payload: '',
            type: 'text',
            extraProps: {
                noBoilersConfigSvg: 'boilers_running_hours_Icon'
            },
            hasTimePeriod: true,
            updateHandler: updateBoilersRunningTime
        }
    },
    boFC_daily: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        return {
            widgetId: 'boFC_daily',
            singleRequest: {value: true},
            payload: isLight ? fuelBarChartBOPayload_light : fuelBarChartBOPayload,
            type: 'basicChart',
            specificDates: {
                from: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['boFC_daily'])?.from)
                    .utc().set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0
                    }).valueOf(),
                to: moment(dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject['boFC_daily'])?.to).valueOf()
            },
            prerequisite: {
                prerequisiteBuildsPayload: true,
                updatePayloadCallback: () => boilersFuelBarUpdatePayload(widget),
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: widget?.vesselIds[0]}
                }, callback: setBoilersFuelBarTypes
            },
            extraProps: {
                noBoilersConfigSvg: 'boilers_fcr_bar_Icon'
            },
            updateHandler: updateFuelBarChartBO
        }
    },
    boFC_hourly: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        return {
            widgetId: 'boFC_hourly',
            singleRequest: {value: true},
            payload: isLight ? fuelLineChartBOPayload_light : fuelLineChartBOPayload,
            type: 'basicChart',
            prerequisite: {
                prerequisiteBuildsPayload: true,
                updatePayloadCallback: () => boilersFuelLineUpdatePayload(widget),
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: widget?.vesselIds[0]}
                }, callback: setBoilersFuelLineTypes
            },
            updateHandler: updateFuelLineChartBO,
            extraProps: {
                footer: () => <ArrivalDepartureFooter/>,
                noBoilersConfigSvg: 'boilers_fcr_line_Icon',
            }
        }
    },
    boDetails_hourly: widget => {
        return {
            widgetId: 'boDetails_hourly',
            singleRequest: {value: true},
            payload: boilersDetailsChartPayload,
            type: 'basicChart',
            prerequisite: {
                prerequisiteBuildsPayload: true,
                updatePayloadCallback: boilersDetailsUpdatePayload,
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: widget?.vesselIds[0]}
                }, callback: setBoilersDetailsTypes
            },
            updateHandler: updateBoilersDetailsChart,
            extraProps: {
                chartSeries: boilersDetailsLineChartData.chartSeries,
                chartAxes: boilersDetailsLineChartData.chartAxes,
                navigationMapper: boilersDetailsLineChartData.navigationMapper,
                tabs: boilersDetailsLineChartData.boilersDetailsLineChartTabs,
                plotLines: boilersDetailsLineChartData.plotLines,
                chartHeight: 930,
                exportingChartTitle: "BOILER'S DETAILS",
                divideBySubId: true,
                tabsWithoutNumbers: true,
                noBoilersConfigSvg: 'boilers_details_Icon',
                currentTab: boilersDetailsLineChartData.currentTab,
                updateCurrentTab: boilersDetailsLineChartData.handleTabUpdate,
                // title: {
                //     text: 'Boiler Status',
                //     align: 'center',
                //     y: 5,
                //     style: {
                //       color: '#687685',
                //       fontSize: '11px'
                //     }
                // }
            }
        }
    },
    totalFuelConsumption_leg: widget => {
        return { 
            widgetId: 'totalFuelConsumption_leg', 
            singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-generic-report' }, 
            payload: totalFuelConsumptionAllMetricsPayload, 
            type: 'text',
            updateHandler: updateTotalFuelConsumption,
            hasTimePeriod: true,
            extraProps: {
                isLegReportWidget: true
            }
        }
    },
    generalKPIS_leg: widget => {
        return {
            widgetId: 'generalKPIS_leg', 
            prerequisite: { 
                value: true, 
                request: () => {return {method: reportsUtils.getTelegramsConfig, 
                params: { vesselIds: [widget.vesselIds[0]] }}}, 
                callback: updateVesselIsTelegramConfigured 
            },
            singleRequest: {value: true}, 
            payload: generalKPISPayload, 
            type: 'table',
            hasTimePeriod: true,
            updateHandler: updateGeneralKPIS,
            extraProps: {
                columns: generalKPISColumnDefs,
                frameworkComponents: { vesselNameBox: VesselNameBox, doubleData: DoubleData},
                allowSorting: false,
                isLegReportsComponent: true,
                wrapperStyle: {width: '100%', height: '100%', marginTop: 25},
                wrapperClass: 'generalKPISWigdet'
            }
        }
    },
    loadCondition_leg: widget => {
        const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
        
        return {
            widgetId: 'loadCondition_leg', 
            singleRequest: {value: true}, 
            payload: isLight ? loadConditionLightPayload : loadConditionPayload, 
            type: 'text',
            prerequisite: {
                isReport: true,
                report: { 
                    innerPrerequisite: false,
                    widgetId: 'loadConditionData',
                    inDashboard: true,
                    specificDates: {
                        from: dashboardUtils.dashboardProperties?.timespansObject['loadCondition_leg'+widget?.id]?.timestampFrom,
                        to: dashboardUtils.dashboardProperties?.timespansObject['loadCondition_leg'+widget?.id]?.timestampFrom,
                    },
                    singleRequest: { value: true },
                    payload: loadConditionDataPayload,
                    type: 'text'
                },
                callback: loadDataUpdate
            },
            hasTimePeriod: true,
            updateHandler: updateLoadCondition
        }
    },
    meSfocPower_monthly: (widget, templateId) => dashboardRequestsUtilities.meSfocPower(widget, {widgetId: 'meSfocPower_monthly', templateId}),
    meSfocPower_weekly: (widget, templateId) => dashboardRequestsUtilities.meSfocPower(widget, {widgetId: 'meSfocPower_weekly', templateId}),
    meSfocPower_leg: (widget, templateId) => dashboardRequestsUtilities.meSfocPower(widget, {widgetId: 'meSfocPower_leg', isLeg: true, templateId}),

    meFCPower_monthly: (widget, templateId) => dashboardRequestsUtilities.meFCPower(widget, {widgetId: 'meFCPower_monthly', templateId}),
    meFCPower_weekly: (widget, templateId) => dashboardRequestsUtilities.meFCPower(widget, {widgetId: 'meFCPower_weekly', templateId}),
    meFCPower_leg: (widget, templateId) => dashboardRequestsUtilities.meFCPower(widget, {widgetId: 'meFCPower_leg', isLeg: true, templateId}),

    mePowerRpm_monthly: (widget, templateId) => dashboardRequestsUtilities.mePowerRpm(widget, {widgetId: 'mePowerRpm_monthly', templateId}),
    mePowerRpm_weekly: (widget, templateId) => dashboardRequestsUtilities.mePowerRpm(widget, {widgetId: 'mePowerRpm_weekly', templateId}),
    mePowerRpm_leg: (widget, templateId) => dashboardRequestsUtilities.mePowerRpm(widget, {widgetId: 'mePowerRpm_leg', isLeg: true, templateId}),

    mePscavPower_monthly: (widget, templateId) => dashboardRequestsUtilities.mePscavPower(widget, {widgetId: 'mePscavPower_monthly', templateId}),
    mePscavPower_weekly: (widget, templateId) => dashboardRequestsUtilities.mePscavPower(widget, {widgetId: 'mePscavPower_weekly', templateId}),
    mePscavPower_leg: (widget, templateId) => dashboardRequestsUtilities.mePscavPower(widget, {widgetId: 'mePscavPower_leg', isLeg: true, templateId}),

    turbochargerRpmPower_monthly: (widget, templateId) => dashboardRequestsUtilities.turbochargerRpmPower(widget, {widgetId: 'turbochargerRpmPower_monthly', templateId}),
    turbochargerRpmPower_weekly: (widget, templateId) => dashboardRequestsUtilities.turbochargerRpmPower(widget, {widgetId: 'turbochargerRpmPower_weekly', templateId}),
    turbochargerRpmPower_leg: (widget, templateId) => dashboardRequestsUtilities.turbochargerRpmPower(widget, {widgetId: 'turbochargerRpmPower_leg', isLeg: true, templateId}),

    turbochargerPscavRpm_monthly: (widget, templateId) => dashboardRequestsUtilities.turbochargerPscavRpm(widget, {widgetId: 'turbochargerPscavRpm_monthly', templateId}),
    turbochargerPscavRpm_weekly: (widget, templateId) => dashboardRequestsUtilities.turbochargerPscavRpm(widget, {widgetId: 'turbochargerPscavRpm_weekly', templateId}),
    turbochargerPscavRpm_leg: (widget, templateId) => dashboardRequestsUtilities.turbochargerPscavRpm(widget, {widgetId: 'turbochargerPscavRpm_leg', isLeg: true, templateId})
}

export default reportsPayload;
