import geStorage from "components/monitoringCategories/localStorages/geStorage";
import syncChartsOptions from "../../components/charts/genericSyncCharts/syncChartsOptions";
import Utilities from "../../Utilities/global";
import {licensing} from "../../common/store/licensing";
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";
import { vesselUtils, monitoringStore } from 'common/store/storeUtils';

export const fuelLineChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    withTelegramNavigationStatus: true,
    withNavigationStatus: true,
    timeGroup: 'HOUR',   
    metrics:[
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "commonDGFOMassRate"
                }
            ]
        },
    ]
};
export const fuelLineChartPayload_light = {
    xAxis: "TIME",
    aggregation: "AVG",
    withTelegramNavigationStatus: true,
    withNavigationStatus: true,
    timeGroup: 'HOUR',
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "ge_1_foConsumption_calc"
                }
            ]
        },
    ]
};

const yAxisData = [
    {id: 'geFo-axis', title: {text: 'Fuel Consumption (kg/min)'}, min: 0, tickPixelInterval: 150, opposite: false}
];
const yAxisData_light = [
    {id: 'geFo-axis', title: {text: 'Fuel Consumption (mt)'}, min: 0, tickPixelInterval: 150, opposite: false}
];

const seriesData = [
    {name: 'FUEL CONSUMPTION', data: [], tooltipTitle: 'FCR', yAxis: 'geFo-axis',
        titleSuffix:'(UTC)', yMin: 0, tooltip: {valueSuffix: ' kg/min', valueDecimals: 2}}
];
const seriesData_light = [
    {name: 'FUEL CONSUMPTION', data: [], tooltipTitle: 'Report FCR', yAxis: 'geFo-axis', color: ThemeColorsForCharts()['reports_color'],
        titleSuffix:'(UTC)', yMin: 0, tooltip: {valueSuffix: ' mt', valueDecimals: 2}}
];
const supportedNavigationStatuses = ["DEPARTURE", "ARRIVAL"];

export const updateFuelLineChart = (id, data, updateState, extraChartConfigs, widget) => {
    geStorage.setLineChartData(data);

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if (data[0]?.values[0]?.value?.length === 0) return updateState(id, [], widget?.id);

    const supportedStatusPlotLines = data[0]?.navigationStatus[0]?.statuses?.filter(status => supportedNavigationStatuses.includes(status.status));
    const plotLinesArray = [];

    let fuelLineChartJSON = { ...syncChartsOptions() };
    fuelLineChartJSON.yAxis = isLight ? [...yAxisData_light] : [...yAxisData];
    fuelLineChartJSON.series = isLight ? JSON.parse(JSON.stringify(seriesData_light)) : JSON.parse(JSON.stringify(seriesData));
    fuelLineChartJSON.xAxis.title = { text: "Time (UTC)" };
    fuelLineChartJSON.xAxis.title.margin = 10;
    fuelLineChartJSON.chart.height = 505;
    fuelLineChartJSON.chart.marginTop = 50;

    if(data && data[0]) {
        if (isLight) data[0].values[0].value.forEach(val => {val.y = parseFloat(val.y)})

        fuelLineChartJSON.series[0].data = data[0] ? [...data[0].values[0].value] : [];

        supportedStatusPlotLines?.length > 0 && supportedStatusPlotLines.forEach(status => {
            plotLinesArray.push({
                value: status.datetime,
                dashStyle: 'LongDash',
                width: 2,
                color: Utilities.getNavigationStatus(status.status).color,
                label: {
                    style: {
                        display: 'none'
                    }
                }
            })
        })

        fuelLineChartJSON.plotLines = plotLinesArray;

        // if detailed data exists with map bullet clicked or alert clicked, add the plotLines and plotBands (guides) to line charts
        if (extraChartConfigs && extraChartConfigs.plotLines) {
            let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(data);
            if (plotGuides?.xAxis) {
                fuelLineChartJSON.xAxis = Object.assign({}, fuelLineChartJSON.xAxis, plotGuides.xAxis);
            }
        }

        // if detailed data don't exist we need to reset the plotLines and plotBands configuration 
        // in case there were data before and the exit detailes view was clicked
        if (monitoringStore().detailedData.status === false) {
            fuelLineChartJSON.xAxis.plotBands = syncChartsOptions().xAxis.plotBands;
            fuelLineChartJSON.xAxis.plotLines = [];
        }
    }

    updateState(id, fuelLineChartJSON, widget?.id);
};
