import "isomorphic-fetch";
import {BadRequest, BadResponse, NotFound, ServerError, Unauthorized, UnknownError} from 'common/js/exceptions';
import Storage from "common/js/Storage";
import Utilities from 'Utilities/global';
import {Urls} from 'common/js/urls';


const getSettings = (extraSettings) => {
    return {
        enableLoader: true,
        autoHandleError: true,
        ...extraSettings
    };
};

const defaultHeaders = Object.freeze({
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept': 'application/json'
});

const setHeaders = (customHeaders = {}) => {
    let headersData = {...defaultHeaders, ...customHeaders};
    Storage.getCookie('token') && (headersData['Authorization'] = `Bearer ${Storage.getCookie('token')}`);
    Storage.getCookie('userId') && (headersData['deepsea-user-id'] = Storage.getCookie('userId'));
    //headersData['Language'] = pageStore.activeLanguage;
    Storage.getCookie('user') && (headersData['X-Customer-Id'] = Storage.getCookie('user').company.name);
    //app.csrfToken !== '' && (headersData['X-CSRF-Token'] = app.csrfToken);
    if(headersData['Cookie']) delete headersData['Cookie'];
    return new Headers(headersData);
};

const setRequestData = (method, payLoad, headers) => {
    let data = {
        //redirect: 'error',
        method,
        headers
        //credentials: 'include'
    };
    Object.keys(payLoad).length > 0 && (data.body = JSON.stringify(payLoad));
    return data;
};

// For more complex objects where pointers exist inside, and to avoid trying to stringify a cyclic object
const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            seen.add(value);
        }
        return value;
    };
};

const setPayloadObj = payLoad => {
    (payLoad.length > 0) && payLoad.forEach((el) => {
        if (el.component) delete el.component;
        if (el.loading) delete el.loading;
    })
    return payLoad;
}

const setRequestDataIncludingEmptyBody = (method, payLoad, headers) => {
    let data = {method, headers};
    data.body = JSON.stringify(setPayloadObj(payLoad), getCircularReplacer());
    return data;
};

//@TODO REMOVE ALL THESE FUNCTIONS OF CLASSES
const createExceptionByStatus = (params) => {
    switch (params.status) {
        case 200:
        case 201:
        case 202:
        case 203:
        case 204:
            return null;
        case 400:
            return new BadRequest(params);
        case 401:
            return new Unauthorized(params);
        case 404:
            return new NotFound(params);
        case 504:
        case 500:
            return new ServerError(params);
        default:
            return new UnknownError(params);
    }
};

const catchError = (settings, error, reject, path) => {
    if (settings.autoHandleError) {
        //return reject(dialogStore.serverError(error));
        console.log(`Error: ${error.message} on request ${path}`);
        return reject({error: true});
    }
    return reject(error);
};

const visibleElement = el => {
    if(!el) return;
    el.style.display = 'block';
    el.style.opacity = 1;
};

const request = async (path, method, payLoad, extraSettings) => {
    const settings = getSettings(extraSettings);
    const headers = setHeaders(settings.headers);
    const fetchData = setRequestData(method, payLoad, headers);

    let el = null;

    el = document.querySelector('.info-message-for-user.fetch');

    visibleElement(el);

    return new Promise((resolve, reject) => {
        const signal = Utilities.signal
            ? { signal: Utilities.signal }
            : {};

        fetch(path, { ...fetchData, ...signal }).then(resp => {
            Utilities.fadeOut(el, 300);
            resp.text().then(text => {
                let error = createExceptionByStatus(resp);
                if (error === null) {
                    if (text === '') return resolve(text);
                    try {
                        const json = JSON.parse(text);
                        if (json.error) {
                            error = new BadResponse({
                                status: resp.status,
                                statusText: resp.statusText,
                                json
                            });
                            return catchError(settings, error, reject, path);
                        } /*else if (json.data) {
                            return resolve(json.data);
                        }*/
                        return resolve(json);
                    } catch (e) {
                        error = new BadResponse({
                            status: resp.status,
                            statusText: resp.statusText,
                            message: text
                        });
                        return catchError(settings, error, reject, path);
                    }
                } else if (resp.status === 401) {
                    Storage.clearSession();
                    window.location.replace(Urls.ServicesUrl().redirectToAuthURL);
                } 
                else if (resp.status === 400 || resp.status === 410 || resp.status === 408) {
                    const json = JSON.parse(text);
                    return resolve(json);
                } 
                else {
                    try {
                        error.json = JSON.parse(text);
                        return catchError(settings, error, reject, path);
                    }
                    catch (e) {
                        error.message = error instanceof NotFound ? `${resp.statusText} ${resp.url}` : text;
                        return catchError(settings, error, reject, path);
                    }
                }
            }).finally(() => {
                //userUtils.refreshSessionTimer();
            });
        }).catch(e => {
            const error = createExceptionByStatus({statusText: e.name});
            error.message = e.message;
            console.log(`Error: ${e.message} on request ${path}`);
            return {error: true};
        });
    });
};

const requestFile = async (path, method, payLoad, extraSettings) => {
    const settings = getSettings(extraSettings);
    const headers = setHeaders(settings.headers);
    const fetchData = setRequestData(method, payLoad, headers);

    let el = null;

    el = document.querySelector('.info-message-for-user.fetch');
    visibleElement(el);

    return new Promise((resolve, reject) => {
        const signal = Utilities.signal
            ? { signal: Utilities.signal }
            : {};

        fetch(path, { ...fetchData, ...signal }).then(resp => {
            Utilities.fadeOut(el, 300);

            if (resp.ok) {
                // Check if the response is of type CSV (Content-Type: text/csv) or spreadsheetml.sheet
                const contentType = resp.headers.get('Content-Type');
                const isCsv = contentType?.includes('text/csv');
                const isExcel = contentType?.includes('spreadsheetml.sheet');
                if (contentType && (isCsv || isExcel)) {
                    resp.blob().then(blob => {
                        // Get the filename from the Content-Disposition header
                        const contentDisposition = resp.headers.get('Content-Disposition');
                        const filename = contentDisposition
                            ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                            : isCsv ? 'download.csv' : 'download.xlsx';

                        // Create a link to trigger the download
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;

                        // Programmatically click the link to download the file
                        link.click();

                        resolve('CSV file downloaded successfully');
                    }).catch(error => {
                        reject(`Error in downloading file: ${error}`);
                    });
                } else {
                    reject('The response is not in CSV or Excel format');
                }
            } else {
                const error = createExceptionByStatus(resp);
                return catchError(settings, error, reject, path);
            }
        }).catch(e => {
            const error = createExceptionByStatus({statusText: e.name});
            error.message = e.message;
            console.log(`Error: ${e.message} on request ${path}`);
            reject({error: true});
        });
    });
};

const requestFullResponse = async (url, method, payload) => {
    const headers = setHeaders();
    const fetchData = setRequestDataIncludingEmptyBody(method, payload, headers);
    return await fetch(url, fetchData);
};  

const requestsObj =  {
    post: async (path, payLoad, extraSettings = {}) => request(path, 'POST', payLoad, extraSettings).catch(e => e),
    patch: async (path, payLoad, extraSettings = {}) => request(path, 'PATCH', payLoad, extraSettings).catch(e => e),
    get: async (path, extraSettings = {}) => request(path, 'GET', {}, extraSettings).catch(e => e),
    getFile: async (path, extraSettings = {}) => requestFile(path, 'GET', {}, extraSettings).catch(e => e),
    put: async (path, payLoad, extraSettings = {}) => request(path, 'PUT', payLoad, extraSettings).catch(e => e),
    delete: async (path, payLoad, extraSettings = {}) => request(path, 'DELETE', payLoad, extraSettings).catch(e => e),
    requestFullResponse: async (path, method, payload) => requestFullResponse(path, method, payload)
};

export default requestsObj;
