import ThemeColors from 'sass/_themeColorsForJs.scss';
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';
import { vesselGroupUtils } from 'common/store/storeUtils';
import { omit } from 'lodash';
import store from 'common/store/store';
import { keys } from 'lodash';

const getTooltipFormat = () => {
	return {
        headerFormat: `<div class="ciiBarChartTooltip-header flex-centered-col" />
                        <div class="highcharts-fonts marg-b-15 {point.point.className}"> {point.point.tooltipHeader} </div>`,
        // do not show classes with 0 vessels in the tooltip
        pointFormatter: function() {
            return(
                this.y !== 0 ? `<div class="d-flex flex-column">
                    <div class="ciiBarChartTooltip-point highcharts-fonts tooltip-title flex-space-between">
                        <div class="flex-centered">
                            <div class="tooltip-circle" style="background-color:${this.color};"></div>
                            <div>Class ${this.class}:</div>
                        </div>
                        <div class="reportsData">&nbsp; ${this.y} Vessels </div>
                    </div>
                </div>` : ''
            )
        },
        footerFormat: '</div></div>',
        shared: true,
        split: false,
        useHTML: true,
        padding: 0
	}
};

const chartYAxis = [{
    id: 'ciiGroupEfficiencyYAxis',
    title: { text: 'Number of Vessels'},
    height: '180px',
    top: '10%',
    allowDecimals: false,
    lineWidth: 0,
    offset: 2,
    opposite: false,
    showFirstLabel: false
}];

const getDataObj = (widgetName, seriesObj, obj, extraInfo) => {
    const tooltipMonth = extraInfo?.granularity === 'QUARTER' ? obj.x : new Date(`${obj.x} 1, 2000`).toLocaleString('en-US', { month: 'long' })
    const mapper = {
        efficiencyWidget: {
            name: obj.x,
            tooltipHeader: `${tooltipMonth} ${extraInfo?.selectedPickerYear ? extraInfo.selectedPickerYear : ''}`,
            class: seriesObj.name,
            className: 'ciiBarChartTooltip-header-text',
            ...omit(obj, ['x']),
            y: obj.y
        },
        groupsOverviewWidget: {
            name: obj.x,
            tooltipHeader: obj.x,
            class: seriesObj.name,
            className: '',
            ...omit(obj, ['x']),
            y: obj.y
        }
    }

    return mapper[widgetName];
};

const setSeries = (response, widgetName, extraInfo) => {
    const series = response?.telegrams?.series ? response.telegrams.series.map(seriesObj => {
        seriesObj.color = ThemeColors[`Class${seriesObj.name}`];;
        seriesObj.centerInCategory = true;
        seriesObj.customTooltipBorderColor = ThemeColors['reports_color'];

        seriesObj.data = seriesObj?.data?.map(obj => getDataObj(widgetName, seriesObj, obj, extraInfo));
        seriesObj.showInLegend = seriesObj.data.some(dataItem => dataItem.y); // if at least some y values are different than 0 or null, then show the legend for this series

        return seriesObj;
    }) : [];

    return series;
};

// return an array of months depending on the timestamp of each series data
const getXAxisCategories = series => {
    let categories = [];

    series.forEach(seriesObj => {
        const categoriesToAdd = seriesObj?.data?.map(dataObj => dataObj.x);
        categories = categories.concat(categoriesToAdd);
    });

    return [...new Set(categories)];
};

const getLegend = () => ({
    labelFormatter : function () {return `<span>Class ${this.name}`;}
});

const setCommonChartOptions = defaultChartOptions => {
    defaultChartOptions.chart.height = 370;
    defaultChartOptions.chart.marginLeft = 70;
    defaultChartOptions.chart.type = 'column';

    defaultChartOptions.yAxis = [...chartYAxis];
    defaultChartOptions.yAxis[0].minorTickInterval = 0.1;
    defaultChartOptions.yAxis[0].accessibility = { rangeDescription: 'Range: 0.1 to 1000' };
    defaultChartOptions.yAxis[0].minorGridLineColor = 'transparent';

    defaultChartOptions.xAxis.type = 'category';
    defaultChartOptions.xAxis.title.offset = 40;
    defaultChartOptions.plotOptions.column.pointWidth = 8;

    defaultChartOptions.tooltip = { ...getTooltipFormat() };
    defaultChartOptions.exporting = { enabled: false };

    defaultChartOptions.legend = {
        enabled: true,
        ...getLegend(),
        itemMarginBottom: 10,
        verticalAlign: 'top',
        align: 'center',
    };

    // disable navigator and scrollbar
    defaultChartOptions.navigator.enabled = false;
    defaultChartOptions.scrollbar.enabled = false;
};

export const ciiEfficiencyUpdate = (id, response, updateState, updateInnerState, innerUpdate, extraInfo ) => {
    const defaultChartOptions = !response.data ? {} : {...syncChartsOptions(true)};
    
    if (response.data) {
        setCommonChartOptions(defaultChartOptions);

        defaultChartOptions.xAxis.labels.formatter = null;
        defaultChartOptions.xAxis.categories = [...getXAxisCategories(response.data.telegrams.series)];
        defaultChartOptions.xAxis.labels.y = 10;
        defaultChartOptions.xAxis.labels.step = 1;

        defaultChartOptions.plotOptions.paddingTop = 30;

        // assign the correct text for x axis title
        if(extraInfo?.granularity === 'QUARTER') defaultChartOptions.xAxis.title.text = 'Quarters';
        else defaultChartOptions.xAxis.title.text = 'Months';

        defaultChartOptions.series = [...setSeries(response.data, 'efficiencyWidget', extraInfo)];
    }

    !updateState
        ? updateInnerState({...innerUpdate, options: defaultChartOptions})
        : updateState(id, defaultChartOptions)
};

export const ciiGroupsOverviewUpdate = (id, response, updateState, updateInnerState, innerUpdate) => {
    const defaultChartOptions = !response.data ? {} : {...syncChartsOptions(true)};
    
    if (response.data) {
        setCommonChartOptions(defaultChartOptions);

        defaultChartOptions.series = [...setSeries(response.data, 'groupsOverviewWidget')];

        defaultChartOptions.chart.marginTop = 80;
        defaultChartOptions.chart.height = 390;

        defaultChartOptions.xAxis.title.enabled = false;

        const chartIsScrollable = keys(response.data.telegrams.series?.[0]?.data)?.length >= 9;

        // show the scrollbar only if we there are more than 9 series
        if(chartIsScrollable) {
            defaultChartOptions.xAxis.max = 8;

            defaultChartOptions.xAxis.scrollbar = {
                enabled: true,
                height: 8,
                rifleColor: 'transparent',
    
                barBackgroundColor: ThemeColors['disabled_text'],
                barBorderRadius: 4,
                barBorderWidth: 0,
    
                buttonBorderWidth: 0,
                buttonBorderRadius: 4,
                buttonBorderColor: 'transparent',
                buttonBackgroundColor: 'transparent',
                buttonArrowColor: 'transparent',
    
                trackBackgroundColor: 'transparent',
                trackBorderWidth: 0,
                margin: 20
            }
        }

        const onLabelClick = e => {
            setTimeout(() => {
                const vesselGroupName = e.target?.innerText;

                if(!vesselGroupName) return;
    
                const vesselGroupId = vesselGroupUtils.getVesselGroupIdFromName(vesselGroupName);
    
                if(vesselGroupId) vesselGroupUtils.setSelectedAndChange(store.dispatch, vesselGroupId);
            });
        }

        defaultChartOptions.chart.events = {
            render: function() { this.xAxis[0].labelGroup.on('click', onLabelClick) }
        };

        defaultChartOptions.xAxis.labels = {
            ...defaultChartOptions.xAxis.labels,
            step: 1,
            useHTML: true,
            align: 'center',
            y: chartIsScrollable ? 0 : -50,
            formatter: function () {
                const getVesselGroupColor = () => {
                    const index = this.pos > 15 ? this.pos - 16 : this.pos;

                    return ThemeColors[`fleetColor${index}`];
                };

                return `<div class="vessel-name-box">
                            <div class="vessel-name-box__rect d-flex justify-content-start align-items-center pointer"
                                style="border-color: ${getVesselGroupColor()}; width: 127px;"
                            >
                                <div class="groupsIconDiv" style="background-color: ${getVesselGroupColor()};"> </div>

                                <div class="table-label-element">
                                    ${this.value}
                                </div>
                            </div>
                        </div>`;
            }
        }
    }

    !updateState
        ? updateInnerState({...innerUpdate, options: defaultChartOptions})
        : updateState(id, defaultChartOptions)
};