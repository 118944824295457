import geStorage from "components/monitoringCategories/localStorages/geStorage";
import lineChartStockOptions from "components/charts/lineChart/react-lineChartStock";
import {licensing} from "../../common/store/licensing";
import chartUtils from 'Utilities/charts';
import { vesselStore, vesselUtils, monitoringStore } from 'common/store/storeUtils';
import { downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";
import moment from "moment";

let gesNum = 0;

export const loadHistoryPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    metrics: [
        {
            metricCategory: "GENERATOR_ENGINE",
            subIds: [],
            metricData: [
                {
                    metricName: "load"
                }
            ]
        }
    ]
};

export let loadHistoryPayload_light = {
    xAxis: "TIME",
    aggregation: "AVG",
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: []
        }
    ]
};

// Dynamically fill metricData of loadHistoryPayload_light from generator engines number
const setPayloadLight = count => {
    loadHistoryPayload_light.metrics[0].metricData = [];
    for (let i = 1; i <= count; i++ ) {
        loadHistoryPayload_light.metrics[0].metricData.push({'metricName': `ge_${i}_load_avg_calc`});
    }
}

const yAxisData = [
    {
        id: 'loads-axis',
        title: {text: 'Load (%)'},
        min: 0,
        tickPixelInterval: 150,
        opposite: false
    }
]

export const setGesNum = (data, widget) => {
    if(Object.keys(data)?.length === 0) return;

    const vessel = vesselStore().selectedVessel !== -1 && vesselStore().selectedVessel !== undefined 
    ? vesselStore().selectedVessel 
    : widget?.vesselIds[0];

    gesNum = (data && data[vessel]) ? data[vessel]?.length : null;

    if(!gesNum) gesNum = 0;

    setPayloadLight(gesNum);
}

const getSeries = (response) => {
    let series = response[0].values.map(resp => ({
        name: `GE-${resp.subId}`,
        data: resp.value,
        tooltipTitle: `GE-${resp.subId}`,
        yAxis: 'loads-axis',
        titleSuffix: '(UTC)',
        yMin: 0,
        tooltip: {valueSuffix: ` ${resp.unit}`, valueDecimals: 2}
    }));
    return series;
}

const getSeriesLight = response => {

    // Parse float string values coming from response
    response[0].values.forEach(resp => {
        resp.value.forEach(value => {
            value.y = parseFloat(value?.y)
        })
    })

    return response[0].values.map((resp) => ({
        name: `GE-${resp.metricName.match(/\d+/)[0]}`,
        data: resp.value,
        tooltipTitle: `GE-${resp.metricName.match(/\d+/)[0]}`,
        yAxis: 'loads-axis',
        titleSuffix: '(UTC)',
        yMin: 0,
        tooltip: {valueSuffix: ` ${resp.unit}`, valueDecimals: 2}
    }));
}

export const loadHistory = (id, response, updateState, extraChartConfigs, widget) => {
    geStorage.setLoadHistoryData(response);
    
    if (response?.length === 0) return updateState(id, {}, widget?.id);

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    let loadHistoryJSON = {chartData: {...lineChartStockOptions()}};

    if (response[0] && response[0].values) {
        loadHistoryJSON.chartData.chart = {...loadHistoryJSON.chartData.chart, id: id};
        loadHistoryJSON.chartData.yAxis = yAxisData;
        loadHistoryJSON.chartData.series = isLight ? getSeriesLight(response) : getSeries(response);
        loadHistoryJSON.chartData.chart.height = 530;
        loadHistoryJSON.chartData.legend.itemDistance = 30;
        loadHistoryJSON.chartData.tooltip.borderColor = chartUtils.tootlipBorderColor.premiumLight();    
    
        loadHistoryJSON.chartData.exporting.buttons.contextButton= {
            menuItems: [
                "download",
                "customSeparator",
                {
                    text: "XLSX",
                    onclick: function () {
                        let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-GENERATOR ENGINES LOAD HISTORY`;
                        
                        let sheetsName = name
                        const rows = this.getDataRows(true);
                        let headers = this.getDataRows(true)[0]

                        if (sheetsName.length > 30) {
                            sheetsName = 'GENERATOR ENGINES LOAD HISTORY'
                        }

                        headers = headers.map((val) => val.includes("TIME") ? val : `${val} Load (%)`);
                        const newRows = rows.map((row) => {
                            return [
                                moment.utc(row[0]).format('DD-MM-YYYY HH:mm:ss'),
                                ...row.slice(1,row.length)
                            ];
                        });

                        let updatedows = [headers, ...newRows.slice(1, rows.length)];            

                        // remove special characters before the download function
                        // eslint-disable-next-line no-useless-escape
                        name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                        name = name.replace(/\./g, "");

                        // eslint-disable-next-line no-useless-escape
                        sheetsName = sheetsName.replace(/[:\/\\\?\*\[\]]/g, "");
                        downloadChartInXLSXform({
                            xlsxRows: updatedows,
                            sheetsName: sheetsName,
                            name: name
                        });
                    }
                },
                {
                    text: "CSV",
                    onclick: function () {
                        let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-GENERATOR ENGINES LOAD HISTORY`;
                        // eslint-disable-next-line no-useless-escape
                        name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                        name = name.replace(/\./g, "");

                        const rows = this.getDataRows(true);
                        let headers = this.getDataRows(true)[0]

                        headers = headers.map((val) => val.includes("TIME") ? val : `${val} Load (%)`);
                        const newRows = rows.map((row) => {
                            return [
                                moment.utc(row[0]).format('DD-MM-YYYY HH:mm:ss'),
                                ...row.slice(1,row.length)
                            ];
                        });

                        let updatedows = [headers, ...newRows.slice(1, rows.length)];            
                        
                        const csvContent = updatedows
                            .map((row) => row?.join(","))?.join("\n");

                        downloadChartInCSVform({ csvContent, name: name });
                    }
                },
            ]
        }

        // if detailed data exists with map bullet clicked or alert clicked, add the plotLines and plotBands (guides) to line charts
        if (extraChartConfigs && extraChartConfigs.plotLines) {
            let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(response);
            if (plotGuides?.xAxis) {
                loadHistoryJSON.chartData.xAxis = Object.assign({}, loadHistoryJSON.chartData.xAxis, plotGuides.xAxis);
            }
        }

        // if detailed data don't exist we need to reset the plotLines and plotBands configuration 
        // in case there were data before and the exit detailes view was clicked
        if (monitoringStore().detailedData.status === false) {
            loadHistoryJSON.chartData.xAxis.plotBands = lineChartStockOptions().xAxis.plotBands;
            loadHistoryJSON.chartData.xAxis.plotLines = [];
        }
    }

    updateState(id, loadHistoryJSON, widget?.id);

};
