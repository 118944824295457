import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReportsHeader from 'reports/reportsHeader';
import MapComponent from 'components/map/map';
import LoadHistoryLineChart from "widgets/generatorEngine/loadHistoryLineChart.jsx";
import Layout from 'components/core/Layout';
import {legInfoPayload, legInfo as updateLegInfo} from 'widgets/vesselData/legInfo';
import {loadConditionPayload, loadConditionLightPayload, loadCondition as updateLoadCondition, loadUpdate} from 'widgets/vesselData/loadCondition';
import {cleaningEventsUpdate, updateFoulingPenalty, getFoulingPenaltyTitle} from 'widgets/vesselData/foulingPenalty.js';
import {legFuelConsumptionPayload, updateLegFuelConsumption} from 'widgets/mainEngine/legFuelConsumption';
import { vesselLog as updateVesselLog } from 'widgets/events/VesselLog';
import { loadHistoryPayload, loadHistoryPayload_light, loadHistory as updateLoadHistory, setGesNum as setGesNumHistoryLoad} from "widgets/generatorEngine/loadHistoryLineChart.js";
import ReportsService from 'common/js/reports';
import service from 'common/js/service';
import TableComp from 'components/table/table';
import VesselTripPorts from 'reports/vesselTripPorts';
import ValuesTitlesIconsInline from 'reports/valuesTitlesIconsInline';
import HullFoulingPenalty from 'components/monitoringCategories/hullComponents/hullFoulingPenalty';
import HullFoulingPenaltyFooter from 'components/footers/dynamicFooters/hullFoulingPenaltyFooter.jsx'
import PanelHeaderWithTag from 'components/widgetPanel/panelHeaderWithTag';
import LegfuelConsumption from 'reports/legFuelConsumption';
import FullPageNoReportMessage from 'reports/fullPageNoReportMessage';
import LegsToolbar from 'reports/legsToolbar';
import SearchAnotherLeg from 'reports/searchAnotherLeg';
import {licensing} from "common/store/licensing";
import RunningTime from "widgets/generatorEngine/runningTime.jsx";
import {updateRunningTime} from "widgets/generatorEngine/runningTime.js";
import TotalFuelConsumption from 'reports/totalFuelConsumption';
import {totalFuelConsumptionAllMetricsPayload, updateTotalFuelConsumption} from 'reports/payloads/totalFuelConsumption.js';
import VesselMonitoringChart from "../components/monitoringCategories/overviewComponents/vesselMonitoringChart";
import {vesselMonitoringChartPayload, vesselMonitoringChartPayloadLight, vesselMonitoringChartUpdate} from "../reports/payloads/vesselMonitoringChart";
import {generalKPISPayload, updateGeneralKPIS, updateVesselIsTelegramConfigured, generalKPISColumnDefs} from 'reports/payloads/generalKPIS';
import ArrivalDepartureFooter from "components/footers/staticFooters/arrivalDepartureFooter.jsx"
import VesselNameBox from 'components/vesselNameBox/vesselNameBox';
import DoubleData from 'components/table/doubleData/doubleData';
import Utils from 'Utilities/global';
import {reportsStore, userStore, vesselStore, reportsUtils, vesselUtils} from 'common/store/storeUtils';
import {useDispatch, useSelector} from 'react-redux';
import EventTypeList from "../components/eventTypeList/eventTypeList";
import TotalFuelConsumptionFooter, { isFuelsAvailable } from 'reports/totalFuelConsumptionFooter';

let isMounted = false;
let currentVesselId = -1;
let currentMonth = moment(Utils.nowOrDemo()).toDate();

let registrationWidgetsPremium = [], registrationWidgetsLight = [];

const LegReport = (props) => {
    const reportsService = new ReportsService();
    const [init, setInit] = useState(false);
    const [legInfo, setLegInfo] = useState({});
    const [loadCondition, setLoadCondition] = useState({});
    const [powerPenalty, setPowerPenalty] = useState({});
    const [legFuelConsumption, setLegFuelConsumption] = useState({});
    const [vesselMonitoringChartData, setVesselMonitoringChartData] = useState([]);
    const [activeLegIndex, setActiveLegIndex] = useState(0);
    const [loadHistory, setLoadHistory] = useState({});
    const [vesselLog, setVesselLog] = useState([]);
    const [legs, setLegs] = useState([]);
    const [foundLegs, setFoundLegs] = useState(true);
    const [mapUserserLeg, setMapUserserLeg] = useState(false);
    const [vesselTripPorts, setVesselTripPorts] = useState({});
    const [userLegIsSet, setUserLegIsSet] = useState(false);
    const [runningTime, setRunningTime] = useState({});
    const [totalFuelConsumption, setTotalFuelConsumption] = useState({});
    const [generalKPIS, setGeneralKPIS] = useState([]);
    const [requestOnGoing, setRequestOnGoing] = useState({
        loadCondition: false,
        legInfo: false,
        powerPenalty: false,
        legFuelConsumption: false,
        vesselMonitoringChart: false,
        loadHistory: false,
        vesselLog: false,
        runningTime: false,
        totalFuelConsumption: false,
        generalKPIS: false
    });

    const [periods, setPeriods] = useState()

    // store vars for JSX
    const reportsStoreJSX = useSelector(state => state.reports);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    const userStoreJSX = useSelector(state => state.user);

    const dispatch = useDispatch();

    let registrationWidgets = [];

    registrationWidgetsPremium = [
        {widgetId: 'legInfo', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: legInfoPayload, type: 'text'},
        {widgetId: 'loadCondition', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: loadConditionPayload, type: 'text',
            prerequisite: { value: true, request: () => {return {method: reportsService.registerLegs, params: {id: vesselStore().selectedVessel, from: reportsStore().fromTo.from, to: reportsStore().fromTo.to}}}, callback: loadUpdate}
        },
        { widgetId: 'powerPenalty',
            prerequisite: { value: true, request: () => {return {method: service.getCleaningEvents, params:
                    { vesselId: vesselStore().selectedVessel, datetime: reportsStore().fromTo.from }}}, callback: cleaningEventsUpdate },
            inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getPredictedPowerPenalty, params:
                        { vessel: vesselStore().selectedVessel, date: reportsStore().fromTo.to }
                }}}}, payload: {}, type: 'text'
        },
        {widgetId: 'legFuelConsumption', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-fuel-report'}, payload: legFuelConsumptionPayload, type: 'text'},
        {widgetId: 'vesselLog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getVesselEvents, params:
                          { value: vesselStore().selectedVessel, startingDate: reportsStore().fromTo.from, endingDate: reportsStore().fromTo.to }
                    }}}}, payload: {}, type: 'table'},
        {widgetId: 'loadHistory', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: loadHistoryPayload, type: 'basicChart'},
        { widgetId: 'runningTime', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getRunningTimeSensoredAndTelegram, params:
            {vesselId: vesselStore().selectedVessel, from: reportsStore().fromTo.from, to: reportsStore().fromTo.to}}}}}, payload: '', type: 'text'},
        { widgetId: 'totalFuelConsumption', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-generic-report' }, payload: totalFuelConsumptionAllMetricsPayload, type: 'text' },
        { widgetId: 'vesselMonitoringChart', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false }, payload: vesselMonitoringChartPayload, type: 'basicChart' },
        {
            widgetId: 'generalKPIS', 
            prerequisite: { 
                value: true, 
                request: () => {return {method: reportsUtils.getTelegramsConfig, 
                params: { vesselIds: [vesselStore().selectedVessel] }}}, 
                callback: updateVesselIsTelegramConfigured 
            },
            inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: generalKPISPayload, type: 'table'
        }
    ];

    registrationWidgetsLight = [
        {widgetId: 'legInfo', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: legInfoPayload, type: 'text'},
        {widgetId: 'loadCondition', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: loadConditionLightPayload, type: 'text',
            prerequisite: { value: true, request: () => {return {method: reportsService.registerLegs, params: {id: vesselStore().selectedVessel, from: reportsStore().fromTo.from, to: reportsStore().fromTo.to}}}, callback: loadUpdate}
        },
        { widgetId: 'powerPenalty',
            prerequisite: { value: true, request: () => {return {method: service.getCleaningEvents, params:
                    { vesselId: vesselStore().selectedVessel, datetime: reportsStore().fromTo.to }}}, callback: cleaningEventsUpdate },
            inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getPredictedPowerPenalty, params:
                        { vessel: vesselStore().selectedVessel, date: reportsStore().fromTo.to }
                }}}}, payload: {}, type: 'text'
        },
        {widgetId: 'legFuelConsumption', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: false, overwriteHeader: 'get-fuel-report'}, payload: legFuelConsumptionPayload, type: 'text'},    
        {widgetId: 'vesselLog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getVesselEvents, params:
                          { value: vesselStore().selectedVessel, startingDate: reportsStore().fromTo.from, endingDate: reportsStore().fromTo.to }
                    }}}}, payload: {}, type: 'table'},
        { widgetId: 'loadHistory', prerequisite: { value: true, request: () => {return {method: service.getGeInfo, params:
            userStore().user.company.id }}, callback: setGesNumHistoryLoad },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: false}, payload: loadHistoryPayload_light, type: 'basicChart'},
        { widgetId: 'runningTime', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getRunningTimeSensoredAndTelegram, params:
            {vesselId: vesselStore().selectedVessel, from: reportsStore().fromTo.from, to: reportsStore().fromTo.to}}}}}, payload: '', type: 'text'},
        { widgetId: 'totalFuelConsumption', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-generic-report' }, payload: totalFuelConsumptionAllMetricsPayload, type: 'text' },
        { widgetId: 'vesselMonitoringChart', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false }, payload: vesselMonitoringChartPayloadLight, type: 'basicChart' },
        {
            widgetId: 'generalKPIS', 
            prerequisite: { 
                value: true, 
                request: () => {return {method: reportsUtils.getTelegramsConfig, 
                params: { vesselIds: [vesselStore().selectedVessel] }}}, 
                callback: updateVesselIsTelegramConfigured 
            },
            inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: generalKPISPayload, type: 'table'
        }
    ]

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}});
    }

    const setLeg = (index) => {
        const from = moment(legs[index].dateFrom).valueOf();
        const to = moment(legs[index].dateTo).valueOf();

        reportsUtils.updateFromToAndUserLeg(dispatch, {from, to}, false);

        setUserLegIsSet(false);
        setMapUserserLeg(false);
        setVesselTripPorts({
            departureDate: adjustTimezone(from, true),
            departurePort: legs[index].portFrom,
            arrivalDate: adjustTimezone(to, true),
            arrivalPort: legs[index].portTo
        });
        setActiveLegIndex(index);
        register();
    }


    const stateMapper = (key, value) => {
        const mapper = {
            legInfo: setLegInfo,
            loadCondition: setLoadCondition,
            powerPenalty: setPowerPenalty,
            legFuelConsumption: setLegFuelConsumption,
            loadHistory: setLoadHistory,
            vesselLog: setVesselLog,
            runningTime: setRunningTime,
            totalFuelConsumption: setTotalFuelConsumption,
            vesselMonitoringChart: setVesselMonitoringChartData,
            generalKPIS: setGeneralKPIS
        }
        if(key === 'activeLegIndex') {
            setLeg(value);
            return;
        }
        return mapper[key](value);
    }

    const reportsResponseMapper = (response, key) => {
        const mapper = {
            legInfo: updateLegInfo,
            loadCondition: updateLoadCondition,
            powerPenalty: updateFoulingPenalty,
            legFuelConsumption: updateLegFuelConsumption,
            loadHistory: updateLoadHistory,
            vesselLog: updateVesselLog,
            runningTime: updateRunningTime,
            totalFuelConsumption: updateTotalFuelConsumption,
            vesselMonitoringChart: vesselMonitoringChartUpdate,
            generalKPIS: updateGeneralKPIS
        }

        return mapper[key](key, response, stateMapper, null, registrationWidgets.filter(report => report.widgetId === key)[0]);
    }

    const updatePeriods = async () => {
        const res = await service.getLegReportPeriod(vesselStore().selectedVessel, reportsStore().fromTo.from, reportsStore().fromTo.to);
        if (res?.data) {
            setPeriods(reportsUtils.getSortedVesselPeriods(res.data));
        }
    }

    const register = () => {
        // if vessel is light, use the light widget requests, or else the premium ones
        registrationWidgets = licensing.lightCondition() ? registrationWidgetsLight : registrationWidgetsPremium;

        reportsUtils.updateFromTo(dispatch, { from: reportsStore().fromTo.from, to: reportsStore().fromTo.to }, reportsStore().fromToChanged);
        updatePeriods();

        reportsService.registerReports([vesselStore().selectedVessel], registrationWidgets, reportsResponseMapper, updateRequestOnGoing, true);
    }

    const updateFromTo = (dates) => {
        const from = moment(dates.from).set({hour:3,minute:0,second:0,millisecond:0}).valueOf();
        const to = moment(dates.to).set({hour:20,minute:59,second:59,millisecond:0}).valueOf();

        reportsUtils.updateFromToAndUserLeg(dispatch, {from, to}, false);

        setUserLegIsSet(false);
        setMapUserserLeg(false);
    };

    const getLegReportData = () => {
        reportsService.registerLegs({id: vesselStore().selectedVessel, from: reportsStore().fromTo.from, to: reportsStore().fromTo.to}).then(response => {
            if(response?.length > 0) {
                const newestLeg = response[(response.length-1)];
                const from = moment(newestLeg.dateFrom).valueOf();
                const to = moment(newestLeg.dateTo).valueOf();

                reportsUtils.updateFromToAndUserLeg(dispatch, {from, to}, false);

                setLegs(response);
                setFoundLegs(true);
                setActiveLegIndex(response.length - 1);
                setUserLegIsSet(false);
                setMapUserserLeg(false);
                setVesselTripPorts({
                    departureDate: adjustTimezone(from, true),
                    departurePort: newestLeg.portFrom,
                    arrivalDate: adjustTimezone(to, true),
                    arrivalPort: newestLeg.portTo
                });
                register();
            }
            else {
                setLegs([]);
                setFoundLegs(false);
                setMapUserserLeg(false);
            }
        });
    }

    const setCurrentMonth = date => currentMonth = date;

    const goToDates = (event, userLeg) => {
        if(!userLeg) {
            dispatch({
                type: 'reports/setFromTo', 
                payload: {
                    from: moment(currentMonth).startOf('month').utc(true).valueOf(), 
                    to: moment(currentMonth).subtract(3, 'hours').endOf('month').utc(true).valueOf()
                }
            });
            getLegReportData();
        }
        else {
            setFoundLegs(true);
            setLegs([]);
            register();
        }
    }

    const selectUserLeg = ({departureDate, departurePort, arrivalDate, arrivalPort}) => {
        const from = moment(departureDate).utc(true).valueOf();
        const to = moment(arrivalDate).utc(true).valueOf();

        reportsUtils.updateFromToAndUserLeg(dispatch, {from, to}, true);

        setMapUserserLeg(true);
        setFoundLegs(true);
        setUserLegIsSet(true);
        setVesselTripPorts({
            departureDate: adjustTimezone(from, true),
            departurePort: departurePort,
            arrivalDate: adjustTimezone(to, true),
            arrivalPort: arrivalPort
        });
        goToDates({}, true);
    }
    
    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7',
            layout: [{
                columns: [{
                    grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 9},
                    components: [
                        {id: 'vesselRoute', title: "VESSEL'S ROUTE", type: 'map', component: MapComponent, data: {}, style: {height: '508px'}, props: {}}
                    ]
                }, {
                    grid: {xs: 12, sm: 12, md: 12, lg: 6, xl: 3},
                    className: 'padding-left-right-7',
                    layout: [{
                            columns: [{
                                grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                components: [
                                    {id: 'vesselTripPorts', title: "", type: 'text', component: VesselTripPorts, data: {}, style: {height: '174px'}, props: {}}
                                ]
                            },{
                                grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                components: [
                                    { id: 'powerPenalty', title: <PanelHeaderWithTag title={getFoulingPenaltyTitle(powerPenalty)} tag={'AI CALCULATED'} />,
                                    component: HullFoulingPenalty, 
                                    footer: <HullFoulingPenaltyFooter foulingPenalty={powerPenalty} isReport={true} />, data: {}, style: { height: '319px' }, props: {} }
                                  ]
                            }]
                        }]
                }, {
                    grid: {xs: 12, sm: 12, md: 12, lg: 6, xl: 3},
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                            components: [
                                {id: 'loadCondition', title: "", type: 'text', component: ValuesTitlesIconsInline, data: {}, style: {height: '102px'}, props: {}}
                            ]
                        }, {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                            components: [
                                {id: 'legInfo', title: "", type: 'text', component: ValuesTitlesIconsInline, data: {}, style: {height: '102px'}, props: {}}
                            ]
                        }]
                    }]
                },
                {
                    grid: {xs: 12, sm: 12, md: 12, lg: 6, xl: 9},
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [
                            {
                                grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                components: [
                                    {component: TableComp, id: 'generalKPIS', style: {height: '158px'}, props: {}, title: 'LEG GENERAL KPIs'}
                                ]
                            }
                        ]
                    }]
                }],
            }, {
                columns: [
                    {
                        grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                        className: 'padding-left-right-7',
                        layout: [{
                                columns: [
                                {
                                    grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 4},
                                    className: 'padding-left-right-7',
                                    layout: [{
                                        columns: [{
                                                grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                                components: [
                                                    {id: 'totalFuelConsumption', title: '', type: 'text', component: TotalFuelConsumption, data: {}, style: {height: '245px'}, props: {}, 
                                                    footer: isFuelsAvailable(totalFuelConsumption?.data?.fuelConsumptionTypesData) && <TotalFuelConsumptionFooter data={totalFuelConsumption}/>}
                                                ]
                                            },
                                            {
                                                grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                                components: [
                                                    {id: 'legFuelConsumption', title: 'FUEL CONSUMPTION', type: 'barChart', component: LegfuelConsumption, data: {}, style: {height: '635px', width: '100%'}, props: {}}
                                                ]
                                            },
                                            {
                                                grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                                components: [
                                                    {component: EventTypeList, id: 'vesselLog', title: "VESSEL LOG", data: {}, style: {height: '1267px', paddingBottom: '20px'}, props: {}, type: 'table'}
                                                ]
                                            }
                                        ]
                                    }]
                                },
                                {
                                    grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 8},
                                    className: 'padding-left-right-7',
                                    layout: [{
                                        columns: [
                                            {
                                                grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},        
                                                components: [
                                                    { 
                                                        id: 'vesselMonitoringSyncedCharts', footer: <ArrivalDepartureFooter/>, 
                                                        title: "VESSEL'S MONITORING", component: VesselMonitoringChart, 
                                                        data: {}, style: { height: '896px'}, props: { } 
                                                    },
                                                ]
                                            },
                                            { 
                                                grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                                                components: [
                                                    { 
                                                        id: 'runningTime', component: RunningTime,
                                                        title: 'GENERATOR ENGINE RUNNING TIME', data: {}, style: {minHeight: '596px'}, props: {} 
                                                    },
                                                ]
                                            },
                                            { 
                                                grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                                                components: [
                                                    { 
                                                        id: 'loadHistory', component: LoadHistoryLineChart,
                                                        title: 'GENERATOR ENGINES LOAD HISTORY', data: {}, style: {height: '596px'}, props: {} 
                                                    },
                                                ]
                                            }
                                        ]
                                    }]
                                }
                            ]
                        }]
                    },
                ]
            }]
        };
    };

    const adjustTimezone = (dateTime, isUTC) => {
        if( isUTC ) return moment(dateTime).utc().format('DD/MM/YYYY HH:mm');
        return moment(dateTime).format('DD/MM/YYYY HH:mm');
    }

    const createLayoutProps = (data, props = getWidgetsPreferences()) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.map(newComponent => {
                    ('id' in newComponent) && !('element' in newComponent) && (newComponent.id in data) && (newComponent.props = data[newComponent.id]);
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    }

    const checkIfVesselHasTelegramsConfig = () => {
        reportsUtils.getTelegramsConfig({ vesselIds: [vesselStore().selectedVessel] }).then(response => {
            if(response?.data && response?.data[vesselStore().selectedVessel]?.hasTelegramConfiguration)
                dispatch({type: 'reports/setIsTelegramConfigured', payload: true});
            else dispatch({type: 'reports/setIsTelegramConfigured', payload: false});
        })
    }

    useEffect(() => {
        const controller = new AbortController();
        Utils.signal = controller.signal;

        setInit(true);        
        isMounted = true;
        const from = moment(Utils.nowOrDemo()).startOf('month').set({hour:3,minute:0,second:0,millisecond:0}).valueOf();
        const to = moment(Utils.nowOrDemo()).set({hour:20,minute:59,second:59,millisecond:0}).valueOf();

        reportsUtils.updateFromToAndUserLeg(dispatch, {from, to}, false);

        // if initially no vessel group is selected, then select the user's default group
        if(vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) dispatch({ type: 'vesselGroup/setSelectedVesselGroup', payload: userStoreJSX.user.defaultGroup });

        if(vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) dispatch({ type: 'vessel/setSelectedVessel', payload: vesselUtils.fleetVesselsIds()[0] });

        checkIfVesselHasTelegramsConfig();

        return () => {
            controller.abort();
            isMounted = false;
            Utils.signal = null;
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(init) return;
        if(!isMounted) return;
        if(vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) return;
        currentVesselId = vesselStore().selectedVessel;
        getLegReportData();
    // eslint-disable-next-line
    }, [init]);

    useEffect(() => {
        if(!vesselStore().selectedVessel) return;
        if(!isMounted) return;
        if(!init) return;
        if(vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) setTimeout(() => props.history.push('/reports/dailyGroupReport'));
        if(vesselStore().selectedVessel !== currentVesselId) {
            currentVesselId = vesselStore().selectedVessel;
            const from = moment(Utils.nowOrDemo()).startOf('month').set({hour:3,minute:0,second:0,millisecond:0}).valueOf();
            const to = moment(Utils.nowOrDemo()).set({hour:20,minute:59,second:59,millisecond:0}).valueOf();

            reportsUtils.updateFromToAndUserLeg(dispatch, {from, to}, false);

            setTimeout(() => getLegReportData());
            checkIfVesselHasTelegramsConfig();
        };
    // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);

    const data = {
        vesselRoute: {
            reports: true,
            alwaysAnchor: true,
            mapUserserLeg: mapUserserLeg,
            fromTo: reportsStoreJSX.fromTo,
            fromToChanged: reportsStoreJSX.fromToChanged,
            canInitMap: init,
            mapClass: 'legReportMapSize'
        },
        vesselTripPorts: {
            data: vesselTripPorts
        },
        legInfo: {
            data: legInfo,
            loading: requestOnGoing.legInfo
        },
        loadCondition: {
            data: loadCondition,
            loading: requestOnGoing.loadCondition
        },
        powerPenalty: {
            data: {...powerPenalty},
            loading: requestOnGoing.powerPenalty,
            legReportsWidget: true
        },
        legFuelConsumption: {
            chartData: legFuelConsumption,
            loading: requestOnGoing.legFuelConsumption
        },
        vesselMonitoringSyncedCharts: {
            data: vesselMonitoringChartData,
            exportingChartTitle: "VESSEL'S MONITORING",
            loading: requestOnGoing.vesselMonitoringChart,
        },
        loadHistory: {
            data: loadHistory,
            exportingChartTitle: 'GENERATOR ENGINES LOAD HISTORY',
            loading: requestOnGoing.loadHistory
        },
        vesselLog: {
            items: vesselLog,
            loading: requestOnGoing.vesselLog
        },
        runningTime: {
            data: runningTime,
            loading: requestOnGoing.runningTime,
            isReportsWidget: true
        },
        totalFuelConsumption: {
            data: totalFuelConsumption,
            loading: requestOnGoing.totalFuelConsumption,
            isLegReportWidget: true
        },
        generalKPIS: {
            columns: generalKPISColumnDefs,
            items: generalKPIS,
            frameworkComponents: { vesselNameBox: VesselNameBox, doubleData: DoubleData},
            allowSorting: false,
            isLegReportsComponent: true,
            wrapperStyle: {width: '100%', height: '100%', marginTop: 25},
            wrapperClass: 'generalKPISWigdet',
            loading: requestOnGoing.generalKPIS
        }
    };

    return (
        <div className={`reports main-content-padding relative ${!foundLegs && "hideLegScroll"}`}>
            <ReportsHeader 
                from={moment(reportsStoreJSX.fromTo.from).utc()}
                to={moment(reportsStoreJSX.fromTo.to).utc()} 
                updateFromTo={updateFromTo}
                goToDates={goToDates}
                onlyMonths={true}
                setCurrentMonth={setCurrentMonth}
                showLegsPeriodChip={( foundLegs && legs.length > 0 && !userLegIsSet )}
                legsPeriodChip={[
                    <LegsToolbar legs={legs} activeLegIndex={activeLegIndex} updateParentState={stateMapper} />,
                ]}
                sourceTypes={reportsUtils.getAvailableVesselAndLegSourceTypes()}
                periods={periods}
                hasData={foundLegs}
            />
            <div style={{visibility: foundLegs ? 'visible' : 'hidden' }}>
                { ( !(legs.length > 0) && userLegIsSet ) &&
                    <SearchAnotherLeg selectUserLeg={selectUserLeg} />
                }
                <div className="reports__widgets">
                    <Layout {...createLayoutProps(data)}/>
                </div>
            </div>
            <div className="fullPageNoReportMessage" style={{visibility: !foundLegs ? 'visible' : 'hidden' }}>
                <FullPageNoReportMessage selectUserLeg={selectUserLeg} />
            </div>
        </div>
    );
}

export default withRouter(LegReport);
