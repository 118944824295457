import React from 'react';
import Calendar from 'components/calendar/calendar';

const Timeline = props => {
    return (
        <div className="timeline main-content-padding">
            <div className="section-title timeline-page-title">TIMELINE</div>
            <div className="main-panel">
                <div className="main-panel__body">
                    <Calendar id="events-calendar" />
                </div>
            </div>
        </div>
    );
};

export default Timeline;