import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import syncChartsOptions from "../../components/charts/genericSyncCharts/syncChartsOptions";
import {monitoringStore, vesselStore, vesselUtils} from "../../common/store/storeUtils";
import {buildExportContextButton} from "../../Utilities/highchartsUtils";
import {mainEngineHardCodedHeaders} from "./generalDetails";
import { setReportsPlotLineGuides } from "../../Utilities/highchartsUtils";

let turboChargerJson = {};

const setBarTablesDataFormat = (barsTableData) => {
    let tmpObj = {};
    barsTableData.forEach(obj => {
      if (obj.value.length === 0) return;
      tmpObj[obj.metricName] = obj.value
    });
    return tmpObj;
};

export const turboChargersChartPayload = {
  xAxis: "TIME",
  aggregation: "AVG",
  timeGroup: 'HOUR',
  metrics: [{
    metricCategory: "MAIN_ENGINE",
    subIds: [1],
    metricData: []
  }]
};

export const turboChargersLineChartData = {
  turboChargersChartMetrics: [
    'tcExhGasInTemp', 'tcExhGasOutTemp', 'tcLOInLETPress', 'tcLOOutLETTemp', 'tcRPM', 'coolingWOutLETTemp'
  ],
  chartAxes: {},              // Dynamically generated in buildNavigationChartAxes method
  turboChargersLineChartTabs: [],   // Dynamically generated in buildNavigationChartTabs method
  navigationMapper: {},       // Dynamically generated in buildNavigationMapper method
  chartSeries: {},             // Dynamically generated in buildNavigationChartSeries method
  currentTab: null,
  handleTabUpdate: null
}

export const buildTurboChargersChartPayload = (result) => {
    turboChargersChartPayload.metrics[0].metricData = [];

    if (!result.noTurboCharger) result.noTurboCharger = 4;
    for (let i = 1; i <= result.noTurboCharger; i++) {
        turboChargersLineChartData.turboChargersChartMetrics.forEach((metric, index) => {
            turboChargersChartPayload.metrics[0].metricData.push({'metricName': `${metric}No${i}`});
        })
    }

    // Navigation Mapper - Build Navigation Mapper object
    buildNavigationMapper(result.noTurboCharger);

    // Navigation Chart - Build Tabs Object
    buildNavigationChartTabs(result.noTurboCharger);

    // Navigation Chart - Build Axes Object
    buildNavigationChartAxes(result.noTurboCharger);

    // Navigation Chart - Build Series Object
    buildNavigationChartSeries(result.noTurboCharger);
}

const buildNavigationMapper = noTurboCharger => {
    turboChargersLineChartData.navigationMapper = {};

    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        const tempArr = turboChargersLineChartData.turboChargersChartMetrics.map((metric) => {
        return `${metric}No${turboNum}`
        })

        turboChargersLineChartData.navigationMapper[`t/c${turboNum}`] = tempArr;
    }
}

const buildNavigationChartTabs = noTurboCharger => {
    turboChargersLineChartData.turboChargersLineChartTabs = [];
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.turboChargersLineChartTabs.push({title: `T/C ${turboNum}`, name: `t/c${turboNum}`});
    }
}

const buildNavigationChartAxes = noTurboCharger => {
    turboChargersLineChartData.chartAxes = {};
  
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartAxes[`t/c${turboNum}`] = [
        { id: 'exhTemp-in-axis', title: { text: 'Exh. Gas Inlet Temp (°C)'}, height: '60px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'exhTemp-out-axis', title: { text: 'Exh. Gas Outlet Temp (°C)'}, height: '60px', top: '150px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'rpm-axis', title: { text: 'RPM'}, height: '60px', top: '250px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'loPress-in-axis', title: { text: 'LO Inlet Press (bar)'}, height: '60px', top: '350px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'loTemp-out-axis', title: { text: 'LO Outet Temp (°C)'}, height: '60px', top: '450px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'waterTemp-out-axis', title: { text: 'Air Cooler Water Outlet Temp (°C)'}, height: '60px', top: '550px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
      ]
    }
  }

const buildNavigationChartSeries = noTurboCharger => {
    turboChargersLineChartData.chartSeries = {};
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartSeries[`t/c${turboNum}`] = [
        { id: `tcExhGasInTempNo${turboNum}`, name: 'Exh. Gas Inlet Temp', data: [], tooltipTitle: 'Exh. Gas Inlet Temp', type: 'line', yAxis: 'exhTemp-in-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
        { id: `tcExhGasOutTempNo${turboNum}`, name: 'Exh. Gas Outlet Temp', data: [], tooltipTitle: 'Exh. Gas Outlet Temp', type: 'line', yAxis: 'exhTemp-out-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
        { id: `tcRPMNo${turboNum}`, name: 'RPM', data: [], tooltipTitle: 'RPM', type: 'line', yAxis: 'rpm-axis',
          titleSuffix: '(UTC)', tooltip: { valueDecimals: 2 } },
        { id: `tcLOInLETPressNo${turboNum}`, name: 'LO Inlet Temp', data: [], tooltipTitle: 'Lube Oil Inlet Press', type: 'line', yAxis: 'loPress-in-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
        { id: `tcLOOutLETTempNo${turboNum}`, name: 'LO Outet Press', data: [], tooltipTitle: 'Lube Oil Outlet Temp', type: 'line', yAxis: 'loTemp-out-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
        { id: `coolingWOutLETTempNo${turboNum}`, name: 'Air Cooler Water Outlet Temp', data: [], tooltipTitle: 'Air Cooler Water Outlet Temp', type: 'line', yAxis: 'waterTemp-out-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
      ]
    }
}

// this function is created in all js files of the components that use the SyncedTabWithTabNavigation component
// and recreates the chart json object taking each time the proper data based on the current tab from the JSON js object (response)
// and is mapped with the selected tab
const configureChartData = (currentTab, setSyncCharts, widget) => {
    if (!turboChargerJson[widget.id] || !currentTab) return;
    let options = syncChartsOptions();
    // checking if we are on dashboards or in the mainpage
    const vesselId = widget?.vesselIds?.[0] ? widget?.vesselIds[0] : vesselStore().selectedVessel;


    const tabName = currentTab === 'lOil' ? 'LUBE OIL' : currentTab === 'fOil' ? 'FUEL OIL' : currentTab;

    options["series"] = turboChargersLineChartData.chartSeries[currentTab];
    options["yAxis"] = turboChargersLineChartData.chartAxes[currentTab];
    options.chart.height = 770;
    options.title = '';
    options.elementId = 'turbo-chargers-details';
    if(options?.exporting?.chartOptions) {
        options.exporting.chartOptions.series = turboChargersLineChartData.chartSeries[currentTab];
        options.exporting.chartOptions.yAxis = turboChargersLineChartData.chartAxes[currentTab];
    }
    options.exporting.filename = `${vesselUtils.getVesselName(vesselId)}-${tabName.toUpperCase()}`
    options.exporting.buttons.contextButton = buildExportContextButton(tabName, mainEngineHardCodedHeaders['turbo-chargers-details']);
    options.exporting.chartOptions.title.text = `${turboChargersLineChartData.turboChargersLineChartTabs?.find((tab) => tab.name === currentTab)?.title} details`;

    // Metrics already are counted with no1, no2 etc.. No conversion needs to be made to match with axis
    turboChargersLineChartData.navigationMapper && turboChargersLineChartData.navigationMapper[currentTab] && turboChargersLineChartData.navigationMapper[currentTab].forEach(category => {
        turboChargersLineChartData.chartSeries[currentTab].forEach((serie, index) => {
            if (category === serie.id) options.series[index].data = turboChargerJson[widget.id][category] || [];
        })
    })

    if (monitoringStore().detailedData?.status && setReportsPlotLineGuides) {
        let plotGuides = setReportsPlotLineGuides(turboChargerJson[widget.id]);
        if (plotGuides?.xAxis) options.xAxis = Object.assign({}, options.xAxis, plotGuides.xAxis);
    } else {
        options.xAxis.plotBands = syncChartsOptions().xAxis.plotBands;
        options.xAxis.plotLines = [];
    }

    turboChargersLineChartData.currentTab = currentTab;
    if(setSyncCharts) {
        setSyncCharts(options);
    } else return options;
}

export const updateTurboChargersChartJson = (id, data, updateState, extraChartConfigs, widget) => {
    // update state with empty object in order to show the NoData in case the response is empty
    if(data.length === 0) {
        updateState(id, {}, widget?.id);
        return;
    }

    meStorage.setTurboChargersData(data);

    // turboChargerJson is an object variable from the response data and the keys are the names of the charts of every tab concat-NO-TABNAME (eg. engine 1, engine 2)
    turboChargerJson[widget.id] = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values) : [];

    turboChargersLineChartData.currentTab = turboChargersLineChartData.turboChargersLineChartTabs[0]?.name;
    turboChargersLineChartData.handleTabUpdate = configureChartData;

    const currentTabDataConfigured = configureChartData(turboChargersLineChartData.turboChargersLineChartTabs[0]?.name, null, widget);
    updateState(id, currentTabDataConfigured, widget?.id);
};
