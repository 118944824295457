import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import Tooltip from "../../muiHTMLTooltip/muiTooltip";
import { useDispatch } from "react-redux";
import { fleetHeadCells } from "../../../widgets/fleetData/fleetUtils";
import { ReactComponent as InformationIcon } from "assets/img/app/global-imgs/informationIcon.svg";
import { vesselGroupUtils, vesselStore } from "../../../common/store/storeUtils";
import VesselNameBox from "../../vesselNameBox/vesselNameBox";
import moment from "moment";
import Utils from "../../../Utilities/global";
import Skeleton from "@material-ui/lab/Skeleton";

const OverviewTable = (props) => {
    const dispatch = useDispatch();

    const getColorClass = (text) => {
        const colorMapper = {
            'High': 'high',
            'Low': 'low',
            'Data processing': 'data-processing',
            'Limited data': 'limited-data',
            'Poor quality': 'poor-quality',
            'Missing input': 'missing-input'
        }
        return colorMapper[text];
    }

    const sortTable = (orderById) => (event) => {
        const isAsc = props.orderBy === orderById && props.order === "asc";

        // set state vars in props.order to update the table arrows
        props.setOrder(isAsc ? "desc" : "asc");
        props.setOrderBy(orderById);

        // fetch table data based on the sort id and direction of the selected column
        props.sortTable(orderById, isAsc ? 'desc' : 'asc');
    }

    const handleVesselClick = (vesselId) => {
        const groupID = vesselGroupUtils.getVesselGroupIdFromVesselId([vesselId])
        vesselGroupUtils.setSelectedAndChange(dispatch, groupID);
        dispatch({ type: 'vessel/setSelectedVessel', payload: vesselId });
    }

    const isVesselSelected = vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel;


    // gets proper padding for cell values based on sorting and tooltip existence on the head of the column
    const getTableCellClassname = (id) => {
        const cellConfig = fleetHeadCells.find(cell => cell.id === id)
        if(isVesselSelected) {
            return cellConfig?.info ? 'padding-right-30' : '';
        } else {
            return cellConfig?.info ? 'padding-right-50' : 'padding-right-35';
        }
    }

    return (
        <TableContainer className="ds-mui-table ds-mui-table overview-table">
            <Table
                className="material-ui-table"
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
            >
                <TableHead>
                    <TableRow>
                        {fleetHeadCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.align}
                            >
                                <div className="overview-table__head-title">
                                    {!isVesselSelected ? (
                                        <TableSortLabel
                                            style={{ flexDirection: 'row', alignItems: 'start' }}
                                            active={props.orderBy === headCell.id}
                                            direction={
                                                props.orderBy === headCell.id
                                                    ? props.order
                                                    : "asc"
                                            }
                                            onClick={sortTable(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    ) : (
                                        <span>{headCell.label}</span>
                                    )}
                                    { headCell?.info ?
                                        <Tooltip
                                            placement="top"
                                            component={<InformationIcon className='svg-path mb-1 ml-1 marg-t-5 marg-l-5 pointer'/>}
                                            height='auto'
                                            title={<div
                                            className="dcii-tooltip">{headCell.info}</div>}
                                        /> : ""
                                    }
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody className="relative">
                    {props?.data?.map(
                        (row, index) => (
                            <TableRow hover tabIndex={-1} key={index}>
                                <TableCell className={getTableCellClassname('vesselName')} align="left">{
                                    row.vesselName ?
                                        <VesselNameBox
                                            data={{
                                                vesselName: row.vesselName,
                                                clickable: true,
                                                onClick: () => { handleVesselClick(row.vesselId) }
                                            }}
                                        />
                                        : "-"
                                }</TableCell>
                                <TableCell className={getTableCellClassname('latestFouling')} align="right">{Utils.renderNumber(row.latestFouling)}</TableCell>
                                <TableCell className={getTableCellClassname('modelConfidence')} align="left">
                                    {row.modelConfidence ?
                                        <div className="overview-table__confidence-cell">
                                            <div className={`overview-table__confidence-dot overview-table__confidence-dot--${getColorClass(row.modelConfidence)}`}/>
                                            {row.modelConfidence}
                                        </div> : "-"
                                    }
                                </TableCell>
                                <TableCell className={getTableCellClassname('hullDegradationSinceLastDrydock')} align="right">{Utils.renderNumber(row.hullDegradationSinceLastDrydock)}</TableCell>
                                <TableCell className={getTableCellClassname('hullDegradationSinceLastCleaningEvent')} align="right">{Utils.renderNumber(row.hullDegradationSinceLastCleaningEvent)}</TableCell>
                                <TableCell className={getTableCellClassname('annualHullDegradationRate')} align="right">{Utils.renderNumber(row.annualHullDegradationRate)}</TableCell>
                                <TableCell className={getTableCellClassname('latestDrydock')} align="left">{row.latestDrydock ? moment(row.latestDrydock).utc().format('DD/MM/YYYY') : "-"}</TableCell>
                                <TableCell className={getTableCellClassname('latestCleaningEvent')} align="left">
                                    {row.latestCleaningEvent ? `${moment(row.latestCleaningEvent).utc().format('DD/MM/YYYY')} - ${row.cleaningEventTitle}` : '-'}
                                </TableCell>
                            </TableRow>
                        ))
                    }

                    {
                        props.loadingData ? (
                            <div className="skeleton-absolute full-width full-height">
                                <Skeleton
                                    variant="rect"
                                    animation="wave"
                                    width={"100%"}
                                    height={"100%"}
                                />
                            </div>
                        ) : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OverviewTable;