import React from 'react';
import NavigationTabs from "components/navigationTabs/navigationTabs";
import SyncedChartWithTabNavigation from "components/syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx";

const GeneratorEngineDetails = (props) => {

    return( 
        <div className={`syncedChartWithTabNavigation ${props.className}`}>
            <div className="geDetails">
                <NavigationTabs 
                    classes={{
                        mainClass: 'secondary-navigation-tabs',
                        extraClasses: 'd-flex align-items-center'
                    }}
                    tabs={props.engineTabs} 
                    currentTab={props.currentEngineTab}
                    updateCurrentTab={(...args) => props.updateCurrentEngineTab(...args, true)}
                    widget={props.widget}
                    setData={props.setData}
                />
            </div>
            <SyncedChartWithTabNavigation
                {...props}
                updateCurrentTab={(...args) => props.updateCurrentTab(...args, false)}
            />
        </div>
    )
}

export default GeneratorEngineDetails;