import React from 'react';
import SyncCharts from "../charts/genericSyncCharts/syncCharts";
import NavigationTabs from "../navigationTabs/navigationTabs";
import moment from 'moment';

const SyncedChartWithTabNavigation = props => {

    return (
        <div className={`syncedChartWithTabNavigation ${props?.className || moment().valueOf()}`}>
            <NavigationTabs 
                classes={{
                    mainClass: 'secondary-navigation-tabs',
                    extraClasses: 'd-flex align-items-center'
                }}
                {...props} 
                currentTab={props.currentTab}
                updateCurrentTab={props.updateCurrentTab}
                setData={props.setData}
            />
            <SyncCharts options={props.data} />
        </div>
    );
}
export default SyncedChartWithTabNavigation;
