import React, { useState, useEffect } from "react";

const SegmentButtons = (props) => {
	const [active, setActive] = useState(props.defaultActive || '');

	const handleChange = value => {
		setActive(value);
		props.setSelectedSegment({value, reason: "click"});
	}

	// when we have an update on the page for vessel, group or date changed
	// we have to set the active segment button to default selection
	// but we need the reason so this update on segments would not trigger
	// again the chart's requests
	useEffect(() => {
		if(!props.onDataChange || active === props.defaultActive) return;
		setActive(props.defaultActive);
		props.setSelectedSegment({value: props.defaultActive, reason: "default"});
	// eslint-disable-next-line
	}, [props.onDataChange]);

	return (
		<div className="segment-buttons flex-centered-start">
			{props.options.map((input, index) => {
				return (
					<div
						className={`flex-centered segment-buttons__iconButton headline4 ${
							active === input.value ? "active" : ""
						}`}
						key={index}
						onClick={() => handleChange(input.value)}
					>
						{input.label}
					</div>
				);
			})}
		</div>
	);
};

export default SegmentButtons;
