import React from 'react';
import VesselPerformance from 'components/monitoringCategories/vesselPerformance';
import DateRange from 'components/monitoringDateRangePicker/dateRangePicker';
import { vesselStore } from 'common/store/storeUtils';
import { useSelector } from 'react-redux';

const VesselMonitoring = () => {
    const vesselStoreJSX = useSelector(state => state.vessel);    
    const vesselSelected = vesselStore().allVesselsObj.vesselId !== vesselStoreJSX.selectedVessel;
    
    return (
		<div className="vessel-monitoring-container">

            {/* On this div "main-content-padding" we have the fixed elements (titles, tabs, period header) */}
            <div className="main-content-padding flex-space-between">
                    <div className='section-title vessel-monitoring-page-title'>
                        PERFORMANCE MONITORING
                    </div>
                    {vesselSelected && 
                    <div className='monitoring-date-range-picker'>
                        <DateRange/>
                    </div>
                    }
            </div>
            <div className="monitoring-pages">
                <VesselPerformance />
            </div>
		</div>
	)
}

export default VesselMonitoring;