const initialVesselGroupState = {
    selectedVesselGroup: -1,
    allVesselGroups: [],
    assignedVesselGroup: {},
    changeVesselGroup: false,
    sameGroupReSelected: null,
    allVesselGroupsObj: {
        name: 'All fleets', 
        id: -1
    }
}

// vesselGroup reducer
const vesselGroup = (state = initialVesselGroupState, action) => {
    switch(action.type) {
        case 'vesselGroup/setSelectedVesselGroup': {
            return {
                ...state,
                selectedVesselGroup: action.payload
            };
        }
        case 'vesselGroup/setAssignedVesselGroup': {
            return {
                ...state,
                assignedVesselGroup: action.payload
            };
        }
        case 'vesselGroup/setChangeVesselGroup': {
            return {
                ...state,
                changeVesselGroup: action.payload
            };
        }
        case 'vesselGroup/setAllVesselGroups': {
            return {
                ...state,
                allVesselGroups: action.payload
            };
        }
        case 'vesselGroup/setAllVesselGroupsObj': {
            return {
                ...state,
                allVesselGroupsObj: action.payload
            };
        }
        case 'vesselGroup/setSameGroupReSelected': {
            return {
                ...state,
                sameGroupReSelected: action.payload
            };
        }
        default: 
            return state;
    }
}

export default vesselGroup;