import React from "react";
import { useSelector } from "react-redux";
import {ReactComponent as VesselIcon} from 'assets/img/app/monitoring-imgs/ship-icon.svg';
// import {ReactComponent as DoubleVesselIcon} from 'assets/img/app/monitoring-imgs/double-vessel.svg';

const CompanyOverviewFooter = (props) => {
	const vesselStoreJSX = useSelector((state) => state.vessel);
	const vesselGroupStoreJSX = useSelector((state) => state.vesselGroup);
	const isOnCompanyMonitoring = vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id;

	return (
		<div className="vessel-rating__footer align-self-center">
			<VesselIcon className="ml-3"/>
			<div className="small-label ml-2">
				Total Vessels:
				<span className="statistic-value reportsData pl-1">
					{isOnCompanyMonitoring
						? vesselStoreJSX.allVessels.length
						: vesselStoreJSX.fleetVessels.length}
				</span>
			</div>
			{/* {isOnCompanyMonitoring && (
				<>
					<div className="double-data-devider ml-3" />
					<DoubleVesselIcon className="ml-2" />
					<div className="small-label ml-2">
						Total Groups:
						<span className="statistic-value reportsData pl-1">
							{vesselGroupStoreJSX.allVesselGroups.length}
						</span>
					</div>
				</>
			)} */}
		</div>
	);
};

export default CompanyOverviewFooter;
