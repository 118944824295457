import React, { useState, useEffect } from 'react';
import moment from "moment";
import Highcharts from "highcharts";
import lineChartStockOptions from "components/charts/lineChart/react-lineChartStock";
import ReportsService from "common/js/reports";
import meStorage from './localStorages/meStorage';
import {updateMeInfoJson} from 'widgets/mainEngine/meInfoTable';
import {FuelBarChartPayload, FuelBarChartPayload_light, updateFuelBarChart} from 'widgets/mainEngine/fuelBarChart.js';
import {FuelLineChartPayload, FuelLineChartPayload_light, FuelLineChartPredictedPayload, updateFuelLineChartJson, setPData} from 'widgets/mainEngine/fuelLineChart.js';
import {LoadLineChartPayload, updateLoadLineChartJson}  from 'widgets/mainEngine/loadLineChart.js';
import {turboChargersChartPayload, updateTurboChargersChartJson, buildTurboChargersChartPayload, turboChargersLineChartData}  from 'widgets/mainEngine/turboChargersLineChart';
import {pistonsChartPayload, updatePistonsChartJson, buildPistonsChartPayload, pistonLineChartData} from 'widgets/mainEngine/pistonsLineChart';
import {updateDeviationChartJson} from 'widgets/mainEngine/deviationBarChart.js';
import DeviationBarChart from 'widgets/mainEngine/deviationBarChart.jsx';
import { mainEngineHardCodedKeys, generalBarsTablePayload, updateGeneralBarsTable, mainEngineSyncedChartsConfig } from "widgets/mainEngine/generalDetails";
import Layout from 'components/core/Layout';
import mainEngineLoad from './meComponents/mainEngineLoad';
import mainEngineInfo from './meComponents/mainEngineInfo';
import mainEngineFuelBarChart from './meComponents/mainEngineFuelBarChart';
import mainEngineFuelLineChart from './meComponents/mainEngineFuelLineChart';
import {licensing} from "common/store/licensing";
import SyncedChartWithTabNavigation from "../syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx";
import LineChart from 'components/charts/lineChart/lineChart';
import {updateSFOCPowerChart, setSFOCPowerCurvesData} from 'widgets/mainEngine/SFOCPowerChart';
import PowerChartFooter from 'components/footers/staticFooters/powerChartFooter';
import {updateFuelPowerChart, setfuelPowerCurvesData} from 'widgets/mainEngine/fuelConsumptionPowerChart';
import {updatePowerRpmChart, setPowerRpmCurvesData} from 'widgets/mainEngine/powerRpmChart';
import {updateSCAVPowerChart} from 'widgets/mainEngine/SCAVPowerChart';
import {updateTCRpmPowerChart, setTCRpmPowerCurvesData} from 'widgets/mainEngine/turboChargerRpmPowerChart';
import {updateTCScavRpmChart} from 'widgets/mainEngine/turboChargerScavRpmChart';
import service from 'common/js/service';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { monitoringStore, vesselStore, monitoringUtils } from 'common/store/storeUtils';
import {useDispatch, useSelector} from 'react-redux';
import GlobalUtilities from 'Utilities/global';
import { setReportsPlotLineGuides } from "../../Utilities/highchartsUtils";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

let isMounted = false;

const MainEngine = () => {
  const reportsService = new ReportsService();
  const [meInfo, setMeInfo] = useState({});
  const [fuelBarChartJson, setFuelBarChartJson] = useState({});
  const [lineChartPistonsJSON, setLineChartPistonsJSON] = useState();
  const [lineChartTurboChargersJSON, setLineChartTurboChargersJSON] = useState();
  const [lineChartFoConsumptionJSON, setLineChartFoConsumptionJSON] = useState({...lineChartStockOptions()});
  const [deviationChartJson, setDeviationChartJson] = useState("");
  const [meLoadOverTimeJSON, setMeLoadOverTimeJSON] = useState({...lineChartStockOptions()});
  const [meDetailsJson, setMeDetailsJson] = useState(1);
  const [SFOCPowerChartJSON, setSFOCPowerChartJSON] = useState();
  const [fuelPowerChartJSON, setFuelPowerChartJSON] = useState();
  const [powerRpmChartJSON, setPowerRpmChartJSON] = useState();
  const [SCAVPowerChartJSON, setSCAVPowerChartJSON] = useState();
  const [TCRpmPowerChartJSON, setTCRpmPowerChartJSON] = useState();
  const [TCScavRpmChartJSON, setTCScavRpmChartJSON] = useState();
  const [requestOnGoing, setRequestOnGoing] = useState({
    loadLineChartJson: false,
    infoTableJson: false,
    fuelBarChartJson: false,
    fuelLineChartJson: false,
    generalBarsTableJson: false,
    deviationChartJson: false,
    meDetailsJson: false,
    lineChartPistonsJSON: false,
    lineChartTurboChargersJson: false,
    SFOCPowerChartJSON: false,
    fuelPowerChartJSON: false,
    powerRpmChartJSON: false,
    SCAVPowerChartJSON: false,
    TCRpmPowerChartJSON: false,
    TCScavRpmChartJSON: false
  });

  // store vars
  const dispatch = useDispatch();
  const monitoringStoreJSX = useSelector(state => state.monitoring);

  let reportsComponentsLight = [];

  const reportsComponents = [
    { widgetId: 'meInfoJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getMeInfo, params:
        { vesselId: vesselStore().selectedVessel}}}}}, payload: {}, type: 'text'},
    { widgetId: 'fuelBarChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: FuelBarChartPayload, type: 'basicChart' },
    { widgetId: 'loadLineChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: LoadLineChartPayload, type: 'basicChart', specificDates: false  },
    { widgetId: 'lineChartTurboChargersJson', id: 'lineChartTurboChargersJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, prerequisite: { value: true, request: () => {return {method: service.getMeInfo, params:
        { vesselId: vesselStore().selectedVessel}}}, callback: buildTurboChargersChartPayload }, payload: turboChargersChartPayload, type: 'basicChart', specificDates: false  },
    { widgetId: 'deviationChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-piston-deviation' }, payload: '', type: 'basicChart' },
    { widgetId: 'lineChartPistonsJSON', id: 'lineChartPistonsJSON', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, prerequisite: { value: true, request: () => {return {method: service.getMeInfo, params:
        { vesselId: vesselStore().selectedVessel}}}, callback: buildPistonsChartPayload }, payload: pistonsChartPayload, type: 'basicChart', specificDates: false  },
    { widgetId: 'meDetailsJson', id: 'meDetailsJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: generalBarsTablePayload, type: 'basicChart'},
    { widgetId: 'fuelLineChartJson',
        prerequisite: { isReport: true, report: { innerPrerequisite: true,
          widgetId: 'fuelPdata', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: FuelLineChartPredictedPayload, type: 'basicChart'}, callback: setPData },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false }, payload: FuelLineChartPayload,  type: 'basicChart'
    },
    { widgetId: 'SFOCPowerChartJSON',
        prerequisite: { value: true, request: () => {return {method: service.getSFOCPowerCurvesData, params:
              { vesselId: vesselStore().selectedVessel }}}, callback: setSFOCPowerCurvesData },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getSFOCPowerScatterPlotsData, params:
              { 
                vesselId: vesselStore().selectedVessel, 
                from: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from).valueOf(), 
                to: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to).valueOf(),
                scatterPlotCount: 6,
                dashboardTemplateId: ''
              }
        }}}},
        payload: {},
        type: 'text'
    },
    { widgetId: 'fuelPowerChartJSON',
        prerequisite: { value: true, request: () => {return {method: service.getFuelPowerCurvesData, params:
              { vesselId: vesselStore().selectedVessel }}}, callback: setfuelPowerCurvesData },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getFuelPowerScatterPlotsData, params:
              { 
                vesselId: vesselStore().selectedVessel,
                from: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from).valueOf(),
                to: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to).valueOf(),
                scatterPlotCount: 6,
                dashboardTemplateId: '' 
              }
        }}}},
        payload: {},
        type: 'text'
    },
    { widgetId: 'powerRpmChartJSON',
        prerequisite: { value: true, request: () => {return {method: service.getPowerRpmCurvesData, params:
              { vesselId: vesselStore().selectedVessel }}}, callback: setPowerRpmCurvesData },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getPowerRpmScatterPlotsData, params:
              { 
                vesselId: vesselStore().selectedVessel, 
                from: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from).valueOf(), 
                to: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to).valueOf(),
                scatterPlotCount: 6,
                dashboardTemplateId: '' 
              }
        }}}},
        payload: {},
        type: 'text'
    },
    { widgetId: 'SCAVPowerChartJSON',
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getSCAVPowerScatterPlotsData, params:
        { 
          vesselId: vesselStore().selectedVessel, 
          from: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from).valueOf(), 
          to: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to).valueOf(), 
          scatterPlotCount: 6,
          dashboardTemplateId: ''
        }
      }}}},
        payload: {},
        type: 'text'
    },
    { widgetId: 'TCRpmPowerChartJSON',
        prerequisite: { value: true, request: () => {return {method: service.getTCRpmPowerCurvesData, params:
              { vesselId: vesselStore().selectedVessel }}}, callback: setTCRpmPowerCurvesData },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getTCRpmPowerScatterPlotsData, params:
              { 
                vesselId: vesselStore().selectedVessel, 
                from: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from).valueOf(), 
                to: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to).valueOf(),
                scatterPlotCount: 6,
                dashboardTemplateId: '' 
              }
        }}}},
        payload: {},
        type: 'text'
    },
    { widgetId: 'TCScavRpmChartJSON',
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getTCScavRpmScatterPlotsData, params:
        { 
          vesselId: vesselStore().selectedVessel, 
          from: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from).valueOf(), 
          to: moment(monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to).valueOf(), 
          scatterPlotCount: 6,
          dashboardTemplateId: ''
        }
      }}}},
        payload: {},
        type: 'text'
    }
  ];

  const updateRequestOnGoing = (key, value) => {
    setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}});
  }

  const extraChartsConfigMapper = widgetId => {
    if(!monitoringStore().detailedData.status) return null;

    const mapper = {
      fuelBarChartJson: null,
      loadLineChartJson: {
        plotLines: { setReportsPlotLineGuides }
      },
      fuelLineChartJson: {
        plotLines: { setReportsPlotLineGuides }
      },
      lineChartTurboChargersJson: null,
      lineChartPistonsJSON: null,
      deviationChartJson: null,
      meDetailsJson: null,
      SFOCPowerChartJSON: null,
      fuelPowerChartJSON: null,
      powerRpmChartJSON: null,
      SCAVPowerChartJSON: null,
      TCRpmPowerChartJSON: null,
      TCScavRpmChartJSON: null
    }

    return mapper[widgetId];
  }

  const stateMapper = (key, value) => {
    const mapper = {
      fuelBarChartJson: setFuelBarChartJson,
      loadLineChartJson: setMeLoadOverTimeJSON,
      fuelLineChartJson: setLineChartFoConsumptionJSON,
      lineChartTurboChargersJson: setLineChartTurboChargersJSON,
      meInfoJson: setMeInfo,
      deviationChartJson: setDeviationChartJson,
      meDetailsJson: setMeDetailsJson,
      lineChartPistonsJSON: setLineChartPistonsJSON,
      SFOCPowerChartJSON: setSFOCPowerChartJSON,
      fuelPowerChartJSON: setFuelPowerChartJSON,
      powerRpmChartJSON: setPowerRpmChartJSON,
      SCAVPowerChartJSON: setSCAVPowerChartJSON,
      TCRpmPowerChartJSON: setTCRpmPowerChartJSON,
      TCScavRpmChartJSON: setTCScavRpmChartJSON
    }
    return mapper[key](value);
  }

  const reportsResponseMapper = (data, widgetId) => {
    const mapper = {
      fuelBarChartJson: updateFuelBarChart,
      loadLineChartJson: updateLoadLineChartJson,
      fuelLineChartJson: updateFuelLineChartJson,
      lineChartTurboChargersJson: updateTurboChargersChartJson,
      meInfoJson: updateMeInfoJson,
      deviationChartJson: updateDeviationChartJson,
      lineChartPistonsJSON: updatePistonsChartJson,
      meDetailsJson: updateGeneralBarsTable,
      SFOCPowerChartJSON: updateSFOCPowerChart,
      fuelPowerChartJSON: updateFuelPowerChart,
      powerRpmChartJSON: updatePowerRpmChart,
      SCAVPowerChartJSON: updateSCAVPowerChart,
      TCRpmPowerChartJSON: updateTCRpmPowerChart,
      TCScavRpmChartJSON: updateTCScavRpmChart
    }
    return mapper[widgetId](widgetId, data, stateMapper, extraChartsConfigMapper(widgetId), reportsComponents.filter(report => report.widgetId === widgetId)[0]);
  }

  const cachedResponsesMapper = widgetId => {
    const reportsResponseMapper = {
      fuelBarChartJson: meStorage.fuelBarData,
      loadLineChartJson: meStorage.loadLineChartData,
      fuelLineChartJson: meStorage.fuelLineChartData,
      lineChartTurboChargersJson: meStorage.turboChargersData,
      meInfoJson: meStorage.meInfoData,
      lineChartPistonsJSON: meStorage.pistonsData,
      deviationChartJson: meStorage.deviationData,
      meDetailsJson: meStorage.generalBarsTableData,
      SFOCPowerChartJSON: {
        data: {...meStorage.SFOCPowerData},
        scatterData: meStorage.SFOCPowerScatterPlotsData,
      },
      fuelPowerChartJSON: {
        data: {...meStorage.fuelPowerData},
        scatterData: meStorage.fuelPowerScatterPlotsData,
      },
      powerRpmChartJSON: {
        data: {...meStorage.powerRpmData},
        scatterData: meStorage.powerRpmScatterPlotsData,
      },
      SCAVPowerChartJSON: {
        data: {...meStorage.SCAVPowerScatterPlotsData},
      },
      TCRpmPowerChartJSON: {
        data: {...meStorage.TCRpmPowerData},
        scatterData: meStorage.TCRpmPowerScatterPlotsData,
      },
      TCScavRpmChartJSON: {
        data: {...meStorage.TCScavRpmScatterPlotsData},
      }
    }
    return reportsResponseMapper[widgetId];
  }

  /**This function call the main engine fetch data request and sets the state. */
  const getMeData = () => {
    const from = monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from;
    const to = monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to;

    registerWidgetsStore.setFromTo({from, to});

    //  in detailed view, change the timeGroup to minutes
    LoadLineChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    turboChargersChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';

    if (!licensing.lightCondition()) {
      FuelLineChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
      FuelLineChartPredictedPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    }

    generalBarsTablePayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    pistonsChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    turboChargersChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';

    //Change payload for some widgets when in light view and  keep only the widgets for light view
    reportsComponentsLight = reportsComponents.filter(comp => comp.widgetId === 'fuelBarChartJson' || comp.widgetId === 'fuelLineChartJson');
    reportsComponents.filter(comp => comp.widgetId === 'fuelBarChartJson')[0].payload = licensing.lightCondition() ? FuelBarChartPayload_light : FuelBarChartPayload;
    reportsComponents.filter(comp => comp.widgetId === 'fuelLineChartJson')[0].payload = licensing.lightCondition() ? FuelLineChartPayload_light : FuelLineChartPayload;

    if ( !meStorage.isGlobalStateEmpty() ) {
      reportsComponents.map(comp => {
        return reportsResponseMapper(cachedResponsesMapper(comp.widgetId), comp.widgetId)
      })
      return;
    }

    reportsService.registerReports([vesselStore().selectedVessel], licensing.lightCondition() ? reportsComponentsLight : reportsComponents, reportsResponseMapper, updateRequestOnGoing, true);
  }

  useEffect(() => {
    if (!monitoringStore().fromToChanged) return;
    if (!isMounted) return;
    
    //reset fromToChanged redux var in order to trigger the next fromTo dates
    dispatch({ type: 'monitoring/setFromToChanged', payload: false });

    //invalid period if more than 3 months (91 days)
    if(monitoringStore().fromTo.to - monitoringStore().fromTo.from > 7862400000) return;

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    return getMeData();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.fromToChanged]);

  useEffect(() => {
    if (!isMounted) return;

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    if (monitoringStore().detailedData && monitoringStore().detailedData.status) return getMeData();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.detailedData]);

  useEffect(() => {
    if (!monitoringStore().resetLegView) return;
    if (!isMounted) return;

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();
    
    dispatch({ type: 'monitoring/setResetLegView', payload: false });

    return getMeData();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.resetLegView]);

  useEffect(() => {
    isMounted = true;
    const controller = new AbortController();
    GlobalUtilities.signal = controller.signal;

    if (!monitoringStore().fromTo.from && !monitoringStore().fromTo.to) return;

    getMeData();

    return () => {
      controller.abort();
      isMounted = false;
      GlobalUtilities.signal = null;
    };
    // eslint-disable-next-line
  }, []);

  const getTitlesFromWidgetIds = (widgetId) => {
    const idsTitlesMapper = {
      meLoad: 'MAIN ENGINE LOAD HISTORY',
      meInfo: 'MAIN ENGINE INFO',
      mainEngineFuelBarChart: 'MAIN ENGINE FUEL CONSUMPTION',
      mainEngineFuelLineChart: 'MAIN ENGINE FUEL CONSUMPTION RATE',
      deviationChartJson: 'PISTONS DEVIATION',
      pistonsDetails: 'PISTON DETAILS',
      meDetails: 'MAIN ENGINE DETAILS',
      turboChargersDetails: 'TURBOCHARGER',
      SFOCPowerChart: 'MAIN ENGINE SFOC VS POWER',
      fuelPowerChart: 'MAIN ENGINE FOC VS POWER',
      powerRpmChart: 'MAIN ENGINE POWER VS RPM',
      SCAVPowerChart: 'MAIN ENGINE PSCAV VS POWER',
      TCRpmPowerChart: 'TURBOCHARGER RPM VS POWER',
      TCScavRpmChart: 'TURBOCHARGER PSCAV VS RPM'
    }

    return idsTitlesMapper[widgetId];
  }

  const getWidgetsPreferences = () => {

    return {
      className: 'padding-left-right-7',
      layout: [
        { columns: [
          { grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
            components: [
              { id: 'SFOCPowerChart', component: LineChart,
              footer: <PowerChartFooter />,
              title: getTitlesFromWidgetIds('SFOCPowerChart'), data: {}, style: {height: '442px'}, props: { removeFromLayout: licensing.lightCondition() } }
            ]
          },
          { grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
            components: [
              { id: 'fuelPowerChart', component: LineChart,
              footer: <PowerChartFooter />,
              title: getTitlesFromWidgetIds('fuelPowerChart'), data: {}, style: {height: '442px'}, props: { removeFromLayout: licensing.lightCondition() } }
            ]
          },
          { grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
            components: [
              { id: 'meInfo', component: mainEngineInfo,
              title: getTitlesFromWidgetIds('meInfo'), data: {}, style: {height: '442px'}, props: { removeFromLayout: licensing.lightCondition() } },
            ]
          }
          ]
        },
        { columns: [
            { grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
              components: [
                { id: 'mainEngineFuelBarChart', component: mainEngineFuelBarChart, 
                title: getTitlesFromWidgetIds('mainEngineFuelBarChart'), data: {}, style: {height: '481px'}, props: { } },
              ]
            },
            { grid: { xs: 12, sm: 12, md: 12, lg: 8, xl: 8 },
              components: [
                { id: 'mainEngineFuelLineChart', component: mainEngineFuelLineChart,
                title: getTitlesFromWidgetIds('mainEngineFuelLineChart'), data: {}, style: {height: '481px'}, props: { } },
              ]
            },
          ]
        },
        { columns: [
            { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
              components: [
                { id: 'meLoad', component: mainEngineLoad,
                title: getTitlesFromWidgetIds('meLoad'), data: {}, style: {height: '442px'}, props: { removeFromLayout: licensing.lightCondition() } },
              ]
            }
          ]
        },
        { columns: [
            { grid: { xs: 12, sm: 12, md: 12, lg: 6, xl: 6 },
              className: 'padding-left-right-7',
              layout: [{
                columns: [{
                  grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                  components: [
                    { id: 'meDetails', component: SyncedChartWithTabNavigation,
                    title: getTitlesFromWidgetIds('meDetails'), data: {}, style: {height: '840px'}, props: { removeFromLayout: licensing.lightCondition() } },
                  ]
                }]
              }]
            },
            { grid: { xs: 12, sm: 12, md: 12, lg: 6, xl: 6 },
                  className: 'padding-left-right-7',
                  layout: [{
                    columns: [{
                      grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                        components: [
                          { id: 'powerRpmChart', component: LineChart,
                          title: getTitlesFromWidgetIds('powerRpmChart'), data: {}, style: {height: '380px'}, props: { removeFromLayout: licensing.lightCondition() } }
                        ]
                      }
                  ]},
                  {
                    columns: [{
                      grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                        components: [
                          { id: 'SCAVPowerChart', component: LineChart,
                          title: getTitlesFromWidgetIds('SCAVPowerChart'), data: {}, style: {height: '385px'}, props: { removeFromLayout: licensing.lightCondition() } }
                        ]
                      }
                  ]}
              ]
            },
        ]},
        { columns: [
            { grid: { xs: 12, sm: 12, md: 12, lg: 6, xl: 6 },
              className: 'padding-left-right-7',
              layout: [{
                columns: [{
                  grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                    components: [
                      { id: 'turboChargersDetails', component: SyncedChartWithTabNavigation,
                      title: getTitlesFromWidgetIds('turboChargersDetails'), data: {}, style: {height: '840px'}, props: { removeFromLayout: licensing.lightCondition() } },
                    ]
                  }
                ]
              }]
            },
            { grid: { xs: 12, sm: 12, md: 12, lg: 6, xl: 6 },
                  className: 'padding-left-right-7',
                  layout: [{
                    columns: [{
                      grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                        components: [
                          { id: 'TCRpmPowerChart', component: LineChart,
                          title: getTitlesFromWidgetIds('TCRpmPowerChart'), data: {}, style: {height: '380px'}, props: { removeFromLayout: licensing.lightCondition() } }
                        ]
                      },
                      {
                        grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                          components: [
                            { id: 'TCScavRpmChart', component: LineChart,
                            title: getTitlesFromWidgetIds('TCScavRpmChart'), data: {}, style: {height: '385px'}, props: { removeFromLayout: licensing.lightCondition() } }
                        ]
                      }
                  ]}
              ]
            }
          ]
        },
        { columns: [
            { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 6 },
              components: [
                { id: 'deviationChartJson', component: DeviationBarChart,
                  title: getTitlesFromWidgetIds('deviationChartJson'), data: {}, style: {height: '840px'}, props: { removeFromLayout: licensing.lightCondition() } },
              ]
            },
            { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 6 },
              components: [
                { id: 'pistonsDetails', component: SyncedChartWithTabNavigation,
                  title: getTitlesFromWidgetIds('pistonsDetails'), data: {}, style: {height: '840px'}, props: { removeFromLayout: licensing.lightCondition() } },
              ]
            }
          ]
        }
      ]
    };
  };

  const data = {
    TCScavRpmChart: {
      data: {...TCScavRpmChartJSON},
      loading: requestOnGoing.TCScavRpmChartJSON
    },
    TCRpmPowerChart: {
      data: {...TCRpmPowerChartJSON},
      loading: requestOnGoing.TCRpmPowerChartJSON
    },
    SCAVPowerChart: {
      data: {...SCAVPowerChartJSON},
      loading: requestOnGoing.SCAVPowerChartJSON
    },
    powerRpmChart: {
      data: {...powerRpmChartJSON},
      loading: requestOnGoing.powerRpmChartJSON
    },
    SFOCPowerChart: {
      data: {...SFOCPowerChartJSON},
      loading: requestOnGoing.SFOCPowerChartJSON
    },
    fuelPowerChart: {
      data: {...fuelPowerChartJSON},
      loading: requestOnGoing.fuelPowerChartJSON
    },
    meLoad: {
      data: {...meLoadOverTimeJSON, exporting: {...meLoadOverTimeJSON.exporting, filename: getTitlesFromWidgetIds('meLoad')}},
      loading: requestOnGoing.loadLineChartJson,
    },
    meInfo: {
      data: {...meInfo},
      loading: requestOnGoing.infoTableJson,
    },
    mainEngineFuelBarChart: {
      data: fuelBarChartJson,
      loading: requestOnGoing.fuelBarChartJson,
    },
    mainEngineFuelLineChart: {
      data: {...lineChartFoConsumptionJSON, exporting: {...lineChartFoConsumptionJSON.exporting, filename: getTitlesFromWidgetIds('mainEngineFuelLineChart')}},
      loading: requestOnGoing.fuelLineChartJson,
    },
    meDetails: {
      elementId: 'main-engine-general',
      currentTab: mainEngineSyncedChartsConfig.currentTab,
      updateCurrentTab: mainEngineSyncedChartsConfig.handleTabUpdate,
      data: meDetailsJson,
      setData: setMeDetailsJson,
      widget: { id: 'meDetailsJson' },
      tabs: mainEngineHardCodedKeys.names,
      className: "meDetails",
      loading: requestOnGoing.meDetailsJson,
    },
    pistonsDetails: {
      elementId: 'pistons-details',
      currentTab: pistonLineChartData.currentTab,
      updateCurrentTab: pistonLineChartData.handleTabUpdate,
      data: lineChartPistonsJSON,
      setData: setLineChartPistonsJSON,
      widget: { id: 'lineChartPistonsJSON' },
      className: "pistonsDetails",
      tabs: pistonLineChartData.pistonsLineChartTabs,
      loading: requestOnGoing.lineChartPistonsJSON
    },
    turboChargersDetails: {
      elementId: 'turbo-chargers-details',
      currentTab: turboChargersLineChartData.currentTab,
      updateCurrentTab: turboChargersLineChartData.handleTabUpdate,
      data: lineChartTurboChargersJSON,
      setData: setLineChartTurboChargersJSON,
      widget: { id: 'lineChartTurboChargersJson' },
      tabs: turboChargersLineChartData.turboChargersLineChartTabs,
      className: "turboChargersDetails",
      loading: requestOnGoing.lineChartTurboChargersJson
    },
    deviationChartJson: {
      data: {...deviationChartJson, exporting: {...deviationChartJson.exporting, filename: getTitlesFromWidgetIds('deviationChartJson')}},
      loading: requestOnGoing.deviationChartJson
    }
  }

  const createLayoutProps = (data, props = getWidgetsPreferences()) => {
    props.layout = props.layout.map(newLayout => {
        newLayout.columns = newLayout.columns.map(newColData => {
            ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                if (('id' in newComponent) && !('element' in newComponent)) {
                    if (newComponent.id in data) newComponent.props = data[newComponent.id];
                }
                return newComponent;
            }));
            ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
            return newColData;
        });
        return newLayout;
    });
    return props;
  };

  return (
    <div className="main-engine-page">
      <div className="main-engine-page__widgets">
        <Layout {...createLayoutProps(data)} />
      </div>
    </div>
  );

}

export default MainEngine;
