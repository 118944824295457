import React, { useState, useEffect } from "react";
import SyncCharts from "components/charts/genericSyncCharts/syncCharts";
import SegmentButtons from "components/segmentButtons/SegmentButtons";
import classnames from 'classnames';

const CiiEfficiencyChart = (props) => {
	const [selectedSegment, setSelectedSegment] = useState(null);
	const [chartOptions, setChartOptions] = useState(null);
	
	useEffect(() => {
		// check only if the segment selection changed from use click action
		// on segments buttons and not for an initialize with the default segment value 
		if (!selectedSegment?.value || selectedSegment?.reason === 'default') return;

		// if(props.innerRequest && props.innerUpdate) props.innerRequest(selectedSegment?.value).then(response => props.innerUpdate(response, selectedSegment, setInnerUpdate, innerUpdate));
		if(props.innerRequest && props.innerUpdate) {
			setChartOptions(null);
			props.innerRequest(selectedSegment?.value).then(response => {
				props.innerUpdate(response, selectedSegment?.value, setChartOptions, chartOptions)
			});
		}
		// eslint-disable-next-line
	}, [selectedSegment?.value]);

	useEffect(() => {
		if (selectedSegment?.reason === 'click') return;

		setChartOptions(props);
		// eslint-disable-next-line
	}, [props.options, selectedSegment?.value]);

	const getSegmentButtonsWrapperClasses = () => classnames('cii-segment-buttons', { 'marg-t-35': !props.footerInfo });

	return (
		<div className="cii-vessel-efficiency vessel-rating d-flex flex-column" style={{ height: props.style?.height }}>
			<div className={getSegmentButtonsWrapperClasses()}>
				<SegmentButtons
					{...props.defaultInputProps}
					setSelectedSegment={setSelectedSegment}
				/>
			</div>

			<SyncCharts {...chartOptions} setNavigatorExtremes={null} />

			{props.footerInfo ? <div className="vessel-rating__footer justify-content-end pr-3">
				<div className="small-label d-flex flex-row align-items-center justify-space-between">
					<span className="headline2">{ props.footerInfo?.title }</span>
					<div className="dashed-line"></div>
					<span className="headline2 pl-1">{ props.footerInfo?.subtitle }</span>
				</div>
			</div> : null}
		</div>
	);
};

export default CiiEfficiencyChart;
