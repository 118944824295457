import React, { useEffect, useState } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import chartOptions from 'components/charts/lineChart/tableChartUtils';
import ThemeColors from "sass/_themeColorsForJs.scss";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
const TableLineChart = (props) => {
    const [chartData, setChartData] = useState({...chartOptions()});
    useEffect(() => {
        if(!props.lineChart) return;
        
        const chartDataTemp = {...chartOptions()};
        chartDataTemp.series = [{
            name: props.chartName,
            data: props?.lineChart ? props.lineChart : [],
            color: ThemeColors['reports_color']
        }];
        setChartData(chartDataTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.lineChart]);
    return (
        <div className='flex-centered'>
            <HighchartsReact options={chartData} highcharts={Highcharts} />
        </div>
    );
}
export default TableLineChart;