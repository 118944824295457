import boStorage from "components/monitoringCategories/localStorages/boStorage";
import syncChartsOptions from "../../components/charts/genericSyncCharts/syncChartsOptions";
import Utilities from "../../Utilities/global";
import { BoilersUtils } from "Utilities/boilers";
import {licensing} from "../../common/store/licensing";
import keys from 'lodash/keys';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import { vesselUtils, monitoringStore } from 'common/store/storeUtils';

let noBoilersConfig = {};

export const fuelLineChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    withTelegramNavigationStatus: true,
    withNavigationStatus: true,
    timeGroup: 'HOUR',   
    metrics:[
        {
            metricCategory: "BOILER",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerFoMassRate"
                }
            ]
        }
    ]
};

export const fuelLineChartTotalPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    withTelegramNavigationStatus: true,
    withNavigationStatus: true,
    timeGroup: 'HOUR',   
    metrics:[
        {
            metricCategory: "VESSEL",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerCommonFoMassRate"
                }
            ]
        },        
        {
            metricCategory: "BOILER",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerFoMassRate"
                }
            ]
        }
    ]
};

export const fuelLineChartPayload_light = {
    xAxis: "TIME",
    aggregation: "AVG",
    withTelegramNavigationStatus: true,
    withNavigationStatus: true,
    timeGroup: 'HOUR',
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "bo_1_foConsumption_calc"
                }
            ]
        },
    ]
};

// 'allSeriesData' and 'allYAxisData' are used in case we use this widget in the dashboard and we have multiple instances of it for different vessels(it assigns to each vessel the appropriate series and yAxis data)
let boilersSubIds, seriesData, yAxisData = [], allSeriesData = {}, allYAxisData = {};
const supportedNavigationStatuses = ["DEPARTURE", "ARRIVAL"];

export const yAxisDataOptions = {
    TOTAL: { id: 'boFoTotal-axis', title: { text: 'FCR (Kg/min)'}, height: '60px', top: '60px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    BOILER1: { id: 'boiler1-axis', title: { text: 'FCR (Kg/min)'}, height: '60px', top: '160px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    BOILER2: { id: 'boiler2-axis', title: { text: 'FCR (Kg/min)'}, height: '60px', top: '260px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    BOILER3: { id: 'boiler3-axis', title: { text: 'FCR (Kg/min)'}, height: '60px', top: '360px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    ONLY_ONE_BOILER: { id: 'boFoOneOnly-axis', title: { text: 'FCR (Kg/min)'}, height: '90%', top: '60px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    LIGHT: { id: 'light-axis', title: { text: 'FCR (mt)'}, height: '90%', top: '60px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
};

export const seriesFuelLineChartOptions = {
    AUXILIARY: {
        name: 'Auxiliary Consumption',  
        data: [], 
        tooltipTitle: 'Auxiliary Consumption', 
        yAxis: 'boFoAuxiliary-axis',
        titleSuffix:'UTC', 
        yMin: 0, 
        tooltip: {valueSuffix: ' kg/min', valueDecimals: 2}
    },
    COMPOSITE: {
        name: 'Composite Consumption', 
        data: [], 
        tooltipTitle: 'Composite Consumption', 
        yAxis: 'boFoComposite-axis',
        titleSuffix:'UTC', 
        yMin: 0, 
        tooltip: {valueSuffix: ' kg/min', valueDecimals: 2}
    },
    TOTAL: {
        name: 'Total Consumption', 
        data: [], 
        tooltipTitle: 'Total Consumption', 
        yAxis: 'boFoTotal-axis',
        titleSuffix:'UTC', 
        yMin: 0, 
        tooltip: {valueSuffix: ' kg/min', valueDecimals: 2}
    }
}

const seriesData_light = [
    {
        name: 'FUEL CONSUMPTION', 
        data: [], 
        tooltipTitle: 'Report FCR', 
        yAxis: 'light-axis', 
        color: ThemeColorsForCharts()['reports_color'],
        titleSuffix:'UTC', 
        yMin: 0, 
        tooltip: {valueSuffix: ' mt', valueDecimals: 2}
    }
];

export const setBoilersFuelLineTypes = (data, widget) => {
    boilersSubIds = [];
    data && data.filter(item => item.type && item.subId && BoilersUtils.acceptedBoilerTypesFuel.includes(item?.type)).forEach(obj => {
        if(obj.type && obj.subId) {
            const tempObj = {subId: obj.subId, type: obj.type};
            boilersSubIds.push(tempObj);
        }
    });

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    (!boilersSubIds.length && !isLight) ? noBoilersConfig[widget.id] = true : noBoilersConfig[widget.id] = false;

    setFuelLineChartSeriesAndAxes(boilersSubIds, widget);
};

export const boilersFuelLineUpdatePayload = (widget) => { // assign correct payload and metrics depending on the boilers types we have received
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if(isLight) return fuelLineChartPayload_light;

    let payload = boilersSubIds.length > 1 ? fuelLineChartTotalPayload : fuelLineChartPayload;

    payload.metrics.forEach(metric => {
        const tempSubIds = [];
        boilersSubIds.forEach((item) => {
            tempSubIds.push(item.subId);
        })
        metric.subIds = tempSubIds;
    });

    return payload;
}

const changeTooltipTitlesIfNeeded = (type, boilerTypeInfoObject, seriesOptionTemp) => {
    if(boilerTypeInfoObject[type].total > 1 && boilerTypeInfoObject[type].current <= boilerTypeInfoObject[type].total) {
        let capitalizedFirstLetterType = type?.toLowerCase()?.replace(/^\w/, c => c?.toUpperCase());
        seriesOptionTemp.name = `${capitalizedFirstLetterType} ${boilerTypeInfoObject[type].current} Consumption`;
        seriesOptionTemp.tooltipTitle = `${capitalizedFirstLetterType} ${boilerTypeInfoObject[type].current} Consumption`;
        boilerTypeInfoObject[type].current++;
    }
}

const adaptYaxisDependingOnBoilersNum = (boilersNum, yAxisData) => {
    if(boilersNum === 2) {
        yAxisData.forEach((yAxis, i) => {
            yAxis.height = `${parseInt(yAxis.height.replace(/[^\d.-]/g, '')) + 25}px`; // add 25px to each yAxis 'height'
            if(i > 0) yAxis.top = `${parseInt(yAxis.top.replace(/[^\d.-]/g, '')) + 27*i}px`;     // add 27*i px to each yAxis 'top', except the first one
        })
    }
}

const addSeriesAndAxisOptionForAllBoilers = (seriesData, yAxisNotAdded, boilerTypeInfoObject, widget) => {
    boilersSubIds.forEach((item) => {                  // add series and YAxis for the rest of the boilers
        if(keys(seriesFuelLineChartOptions).includes(item.type) && yAxisNotAdded.length > 0) {
            const seriesOptionTemp = {...seriesFuelLineChartOptions[item.type], metricName: `boilerFoMassRate${item.subId}`}; // we use metricName + subId to assign the correct data to each series 
            seriesOptionTemp.yAxis = yAxisDataOptions[yAxisNotAdded[0]]?.id;
            
            boilerTypeInfoObject && changeTooltipTitlesIfNeeded(item.type, boilerTypeInfoObject, seriesOptionTemp);

            seriesData.push(seriesOptionTemp);
            yAxisData.push({...yAxisDataOptions[yAxisNotAdded[0]]});
            
            yAxisNotAdded.splice(0, 1);
        }
    });
    adaptYaxisDependingOnBoilersNum(boilersSubIds.length, yAxisData); // if we have less than 3 boilers, then the 'height' and 'top' properties of each yAxis need to change
}

const setFuelLineChartSeriesAndAxes = (boilersSubIdsLocal, widget) => {
    seriesData = [];
    yAxisData = [];
    let yAxisNotAdded = ['BOILER1', 'BOILER2', 'BOILER3'];

    if(keys(boilersSubIdsLocal).length === 1) {            // case when we have one accepted boiler type only
        addSeriesAndAxisOptionForAllBoilers(seriesData, yAxisNotAdded); // add series and YAxis for the rest of the boilers
        yAxisData = [{...yAxisDataOptions['ONLY_ONE_BOILER']}];
        seriesData[0].yAxis = 'boFoOneOnly-axis';
    } 
    else if(keys(boilersSubIdsLocal).length > 1) {               // case when we have multiple accepted boiler types
        seriesData.push(seriesFuelLineChartOptions['TOTAL']);  // first we add the TOTAL series and YAxis
        yAxisData.push({...yAxisDataOptions['TOTAL']});        

        const boilerTypeInfoObject = {          // info needed to change the tooltip title in order to include the appropriate index
            AUXILIARY: {total: boilersSubIdsLocal.filter(item => item.type === 'AUXILIARY').length, current: 1},
            COMPOSITE: {total: boilersSubIdsLocal.filter(item => item.type === 'COMPOSITE').length, current: 1}
        }

        addSeriesAndAxisOptionForAllBoilers(seriesData, yAxisNotAdded, boilerTypeInfoObject); // add series and YAxis for the rest of the boilers
    }
    allSeriesData[widget?.id] = seriesData;
    allYAxisData[widget?.id] = yAxisData;
}

const setBarTablesDataFormat = (barsTableData) => {
    let tmpObj = {};
    barsTableData.forEach(obj => tmpObj[`${obj.metricName}${obj.subId}`] = obj.value);
    return tmpObj;
};

export const updateFuelLineChart = async (id, data, updateState, extraChartConfigs, widget) => {
    boStorage.setFuelLineChartData(data);

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if(isLight) noBoilersConfig[widget?.id] = false;
    if(noBoilersConfig[widget?.id]) return updateState(id, {noBoilersConfig: noBoilersConfig[widget?.id]}, widget?.id);

    if (data[0]?.values[0]?.value?.length === 0) return updateState(id, [], widget?.id);

    const supportedStatusPlotLines = data[0]?.navigationStatus[0]?.statuses?.filter(status => supportedNavigationStatuses.includes(status.status));
    const plotLinesArray = [];

    let fuelLineChartJSON = { ...syncChartsOptions() };
    fuelLineChartJSON.yAxis = isLight ? [{...yAxisDataOptions['LIGHT']}] : [...allYAxisData[widget?.id]];
    fuelLineChartJSON.series = isLight ? JSON.parse(JSON.stringify(seriesData_light)) : JSON.parse(JSON.stringify(allSeriesData[widget?.id]));
    fuelLineChartJSON.xAxis.title = { text: "Time (UTC)" };
    fuelLineChartJSON.xAxis.lineWidth = 0;
    fuelLineChartJSON.chart.height = 573;

    if(data && data[0]) {
        if (isLight) data[0].values[0].value.forEach(val => {val.y = parseFloat(val.y)})

        const barsTableData = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values) : [];

        const lightMetricFound = keys(barsTableData).find((metricName) => metricName.startsWith('bo_1_foConsumption_calc'));
        const totalMetricFound = keys(barsTableData).find((metricName) => metricName.startsWith('boilerCommonFoMassRate'));

        // assign the correct series depending on the metricName
        if(lightMetricFound && fuelLineChartJSON.series[0]) fuelLineChartJSON.series[0].data = barsTableData[lightMetricFound];  // light case
        else {                                                                                    // premium case
            if(totalMetricFound && fuelLineChartJSON.series[0]) fuelLineChartJSON.series[0].data = barsTableData[totalMetricFound];  
            fuelLineChartJSON.series.forEach((serie, i) => { // if we have total consumption, then series.data[0] should not change again, since it'a already initialized with the correct data
                serie.data = (((totalMetricFound && i > 0) || (!totalMetricFound)) && barsTableData[serie.metricName]) 
                ? [...barsTableData[serie.metricName]] : (totalMetricFound && i === 0) ? serie.data : []
            });
        }
                       
        supportedStatusPlotLines?.length > 0 && supportedStatusPlotLines.forEach(status => {
            plotLinesArray.push({
                value: status.datetime,
                dashStyle: 'LongDash',
                width: 2,
                color: Utilities.getNavigationStatus(status.status).color,
                label: {
                    style: {
                        display: 'none'
                    }
                }
            })
        });

        // if detailed data exists with map bullet clicked or alert clicked, add the plotLines and plotBands (guides) to line charts
        if (extraChartConfigs && extraChartConfigs.plotLines) {
            let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(data);
            if (plotGuides?.xAxis) {
                fuelLineChartJSON.xAxis = Object.assign({}, fuelLineChartJSON.xAxis, plotGuides.xAxis);
            }
        }

        // if detailed data don't exist we need to reset the plotLines and plotBands configuration 
        // in case there were data before and the exit detailes view was clicked
        if (monitoringStore().detailedData.status === false) {
            fuelLineChartJSON.xAxis.plotBands = syncChartsOptions().xAxis.plotBands;
            fuelLineChartJSON.xAxis.plotLines = [];
        }

        fuelLineChartJSON.plotLines = plotLinesArray;
    }

    
    const chartHasData = fuelLineChartJSON.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) fuelLineChartJSON = {};

    updateState(id, fuelLineChartJSON, widget?.id);
};
